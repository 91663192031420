
import Vue from 'vue'

export default Vue.extend({
    name: 'CardSharePreviewChat',
    props: {
        carddata: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        content(): string {
            return this.carddata.formData.description.replace(/<[^>]*>?/gm, ' ') || ''
        },
    },
})
