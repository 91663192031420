var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-auth-forgot",class:{ page: _vm.page }},[_c('div',{staticClass:"form-auth-forgot__content"},[_vm._t("default"),_vm._v(" "),_c('div',{staticClass:"form-auth-forgot__left"},[_c('picture',{staticClass:"form-auth-forgot__image"},[_c('img',{attrs:{"src":_vm.$assets.auth.recovery.modalImage,"alt":"image"}})]),_vm._v(" "),_c('h2',{staticClass:"form-auth-forgot__title"},[_vm._v("\n                "+_vm._s(_vm.$util.lang.components.formForgotPassword.noproblem_go_back)+"\n            ")]),_vm._v(" "),_c('BtnGeneric',{staticClass:"form-auth-forgot__change-modal-button",attrs:{"info":{
                    text: _vm.$util.lang.components.formForgotPassword.goback,
                    handler: _vm.changeModal,
                }}}),_vm._v(" "),_c('p',{staticClass:"form-auth-forgot__contact-link"},[_vm._v("\n                "+_vm._s(_vm.$util.lang.components.formForgotPassword.have_troubles)+"\n                "),_c('NLink',{attrs:{"to":`/${_vm.$util.lang.routes.contact}`,"rel":"follow"}},[_vm._v(_vm._s(_vm.$util.lang.components.formForgotPassword.contact_us))])],1)],1),_vm._v(" "),_c('div',{staticClass:"form-auth-forgot__right"},[_c('div',{staticClass:"space-y-3"},[_c('h2',{staticClass:"form-auth-forgot__normal-title"},[_vm._v("\n                    "+_vm._s(_vm.$util.lang.components.formForgotPassword.change_password)+"\n                ")]),_vm._v(" "),_c('p',{staticClass:"form-auth-forgot__description"},[_vm._v("\n                    "+_vm._s(_vm.$util.lang.components.formForgotPassword.check_email)+"\n                ")])]),_vm._v(" "),_c('div',{staticClass:"space-y-3"},[_c('h2',{staticClass:"form-auth-forgot__normal-title"},[_vm._v("\n                    "+_vm._s(_vm.$util.lang.components.formForgotPassword.set_email)+"\n                ")]),_vm._v(" "),_c('form',{staticClass:"form-auth-forgot__form-login",on:{"submit":function($event){$event.preventDefault();}}},[_c('FormFieldGeneric',{staticClass:"form-auth-forgot__field",attrs:{"type":"text","name":"email","placeholder":_vm.$util.lang.components.formForgotPassword.email,"invalid":_vm.form.validationStatus.email},model:{value:(_vm.form.data.email),callback:function ($$v) {_vm.$set(_vm.form.data, "email", $$v)},expression:"form.data.email"}},[_vm._v("\n                        "+_vm._s(_vm.form.validationStatus.email)+"\n                    ")]),_vm._v(" "),_c('BtnGeneric',{staticClass:"form-auth-forgot__digits-submit-button",attrs:{"info":{
                            text: _vm.$util.lang.components.formForgotPassword.login_without_password,
                            handler: () => _vm.handleAuthStrategy({ strategy: 'withCode' }),
                        }}}),_vm._v(" "),_c('BtnGeneric',{staticClass:"form-auth-forgot__password-submit-button",attrs:{"info":{
                            text: _vm.$util.lang.components.formForgotPassword.restore_password,
                            handler: () => _vm.handleAuthStrategy({ strategy: 'withToken' }),
                        }}})],1)])])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }