
import Vue, { PropType } from 'vue'

export default Vue.extend({
    name: 'MenuDropdown',
    props: {
        countries: Array as PropType<
            { url: string; variant: 'okdescontos' | 'megadescuentos' | 'bchollos' | 'jdescuentos' }[]
        >,
    },
    data() {
        return {
            showOptions: false,
            countriesCodes: {
                bchollos: 'España (ES)',
                megadescuentos: 'México (MX)',
                okdescontos: 'Brasil (BR)',
                jdescuentos: 'Chile (CL)',
            } as { [x: string]: string },
            countriesFlags: {
                bchollos: '/assets/png/es.png',
                megadescuentos: '/assets/png/mx.png',
                okdescontos: '/assets/png/br.png',
                jdescuentos: '/assets/png/cl.png',
            } as { [x: string]: string },
        }
    },
    computed: {
        currentSite(): string {
            const country = this.$util.host && this.countriesCodes[process.env.VARIANT as string]

            return country || 'México (MX)'
        },
        currentFlag(): string {
            const country = this.$util.host && this.countriesFlags[process.env.VARIANT as string]

            return country || this.countriesFlags.megadescuentos
        },
    },
    methods: {
        toggleDropdown(show?: boolean): void {
            const newValue = show === undefined ? !this.showOptions : show

            const elm = this.$refs.list as HTMLElement

            const posY =
                document.documentElement.clientHeight -
                    (this.$refs.button as HTMLElement).getBoundingClientRect().bottom <
                170

            if (newValue) elm.classList.add(posY ? 'open-up' : 'open-down')
            else elm.className = 'menu-dropdown__options'

            this.showOptions = newValue
        },
        includeElements() {
            return [this.$refs.button]
        },
    },
})
