
import Vue from 'vue'
import { set as setCookie } from 'js-cookie'
import { cookies } from '~/config/constants'

export default Vue.extend({
    name: 'FormEmailLoginStrategy',
    props: {
        page: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ready: false,
            values: ['', '', '', '', '', ''],
            form: {
                data: {
                    email: this.$store.state.auth.recoveryEmail,
                    code: '',
                },
                validationStatus: {
                    code: false as boolean | string,
                },
            },
        }
    },
    watch: {
        values: {
            deep: true,
            handler() {
                this.form.data.code = this.values.join('')
                this.ready = this.form.data.code.length === 6
            },
        },
    },
    methods: {
        onInput(index: number, value: string) {
            if (/\d/.test(value)) {
                index < 5 && (this.$refs.digits as any)[index + 1].focus()
            } else if (!value) {
                index > 0 && (this.$refs.digits as any)[index - 1].select()

                this.values[index] = ''
            } else {
                ;(this.$refs.digits as any)[index].setValue('')

                this.values[index] = ''
            }
            this.form.data.code = this.values.join('')
        },
        async signIn() {
            this.$nuxt.$loading.start()

            const result = await this.$api.auth.signIn('withEmailCode', this.form.data)

            if (result.feedback === 'params_validation_failed') {
                this.form.validationStatus.code = result.data.code?.[0] || ''
            }
            if (result.feedback === 'not_found') {
                this.form.validationStatus.code = result.data.message
            }

            if (result.feedback === 'token_created_successfull') {
                setCookie(cookies.access_token_key, result.data.access_token, {
                    expires: result.data.expires_at,
                })
                setCookie(cookies.token_expires_at_key, result.data.expires_at.toString(), {
                    expires: result.data.expires_at,
                })

                this.$store.commit('auth/setAuthData', result.data)

                this.$store.commit('auth/setAuthenticationStatus', true)

                const user = await this.$api.user.profile()

                if (user.feedback === 'data_success') {
                    this.$store.commit('user/setProfile', user.data.user)
                }

                this.$root.$emit('notification', {
                    duration: 5000,
                    dismissButtonText: this.$util.lang.components.formEmailLoginStrategy.hide,
                    text: this.$util.lang.components.formEmailLoginStrategy.welcome,
                    type: 'success',
                })

                if (!this.page) {
                    this.$emit('close')
                }

                this.$router.push({ name: 'index' })

                this.$root.$emit('notification', {
                    duration: 5000,
                    dismissButtonText: this.$util.lang.components.formEmailLoginStrategy.hide,
                    text: this.$util.lang.components.formEmailLoginStrategy.welcome,
                    type: 'success',
                })
            }
            this.$nuxt.$loading.finish()
        },
    },
})
