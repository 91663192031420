var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"auth-email-verification",class:{ 'is-page': _vm.info.isPage }},[_c('div',{staticClass:"auth-email-verification__top-bar"},[_c('p',[_vm._v(_vm._s(_vm.$util.lang.components.modalAuthEmailVerification.verify_email))]),_vm._v(" "),_vm._t("default")],2),_vm._v(" "),_c('section',{staticClass:"auth-email-verification__content"},[_c('h2',{staticClass:"auth-email-verification__title"},[_vm._v("\n            "+_vm._s(_vm.info.message || _vm.$util.lang.components.modalAuthEmailVerification.registered_success)+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"font-semibold"},[_vm._v("\n            "+_vm._s(_vm.$util.lang.components.modalAuthEmailVerification.check_spam)+"\n        ")]),_vm._v(" "),(_vm.info.isForUserVerification)?_c('BtnGeneric',{staticClass:"auth-email-verification__button-verification",attrs:{"disabled":_vm.email.sent || _vm.email.error,"type":"submit","loading":_vm.email.loading,"info":{
                text: _vm.email.sent
                    ? _vm.$util.lang.components.modalAuthEmailVerification.email_sended
                    : _vm.$util.lang.components.modalAuthEmailVerification.email_resend,
                icon: _vm.$assets.primary.logout,
                handler: _vm.sendVerificationMail,
                disabled: _vm.email.sent,
            }}}):_vm._e(),_vm._v(" "),_c('Transition',{attrs:{"name":"fade"}},[(_vm.email.sent)?_c('p',[_vm._v("\n                "+_vm._s(_vm.$util.lang.components.modalAuthEmailVerification.email_verify)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.email.error)?_c('p',{staticClass:"font-semibold text-red-500"},[_vm._v("\n                "+_vm._s(_vm.$util.lang.components.modalAuthEmailVerification.email_verify_error)+"\n            ")]):_vm._e()]),_vm._v(" "),(!_vm.info.isForUserVerification)?_c('BtnGeneric',{staticClass:"auth-email-verification__button-continue",attrs:{"type":"submit","info":{
                text: _vm.$util.lang.components.modalAuthEmailVerification.continue,
                icon: _vm.$assets.primary.logout,
                link: _vm.info.isPage && '/',
                handler: !_vm.info.isPage && (() => _vm.$emit('close')),
            }}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }