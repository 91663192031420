
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'
export default Vue.extend({
    name: 'FormMakeComment',
    props: {
        user: Object as PropType<Models.User>,
    },
    data() {
        return {
            submitting: false,
            writing: false,
            html: '',
        }
    },
    watch: {
        html(html: string) {
            this.writing = !/^(<p>(<br>|\s)+<\/p>)+$/gm.test(html)
        },
    },
    methods: {
        reply() {
            this.submitting = true

            this.$emit('reply', {
                callback: (clear: boolean) => {
                    if (clear) {
                        this.$root.$emit('clearQuill')
                    }
                    this.submitting = false
                },
                html: this.html,
            })
        },
    },
})
