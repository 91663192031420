
import Vue from 'vue'

export default Vue.extend({
    name: 'FormForgotPassword',
    props: {
        page: Boolean,
    },
    data() {
        return {
            form: {
                data: {
                    email: '',
                },
                validationStatus: {
                    email: false as string | boolean,
                },
            },
        }
    },
    methods: {
        async handleAuthStrategy(params: { strategy: 'withCode' | 'withToken' }) {
            this.$nuxt.$loading.start()

            const result = await this.$api.auth.recoveryInit(params.strategy, this.form.data)

            if (result.feedback === 'mail_sent') {
                this.$store.commit('auth/setRecoveryEmail', this.form.data.email)
                if (!this.page) {
                    this.$store.commit('setModal', {
                        name:
                            params.strategy === 'withCode'
                                ? 'FormEmailLoginStrategy'
                                : 'ModalAuthResetPasswordInit',
                    })
                } else {
                    const routeName =
                        params.strategy === 'withCode' ? 'inicio-sin-contrasena' : 'cambiar-contrasena'

                    this.$router.push({ name: routeName })
                }
            }

            if (result.feedback === 'not_found') {
                this.form.validationStatus.email = result.data.message
            }
            this.$nuxt.$loading.finish()
        },
        changeModal() {
            if (this.page) this.$router.push({ name: 'login' })
            else this.$store.commit('setModal', { name: 'ModalAuthLogin' })
        },
    },
})
