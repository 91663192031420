
import Vue from 'vue'

export default Vue.extend({
    name: 'AboutUs',
    head(): any {
        const description = 'Saiba mais sobre Okdescontos nesta página. Quem somos, o que fazemos e por que fazemos.'
        const title = 'Saiba mais sobre Okdescontos nesta página. Quem somos, o que fazemos e por que fazemos.'

        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
  
})
