
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'

export default Vue.extend({
    name: 'DashboardPrivateHome',
    data() {
        const mainTabs = [
            {
                name: this.$util.lang.pages.dashboardParent.recent_activity,
                pos: 1,
                path: `/${this.$util.lang.routes.dashboard}/${this.$util.lang.routes.activity}`,
                icon: this.$assets.illustration.dashSummary,
            },
            {
                name: this.$util.lang.pages.dashboardParent.my_discounts_published,
                pos: 2,
                path: `/${this.$util.lang.routes.dashboard}/${this.$util.lang.routes.discounts}`,
                icon: this.$assets.illustration.dashOffer,
            },
            {
                name: this.$util.lang.pages.dashboardParent.discounts_saved,
                pos: 3,
                path: `/${this.$util.lang.routes.dashboard}/${this.$util.lang.routes.saved}`,
                icon: this.$assets.illustration.dashboardDiscountsSaved,
            },
            {
                name: this.$util.lang.pages.dashboardParent.my_chats,
                pos: 4,
                path: `/${this.$util.lang.routes.dashboard}/${this.$util.lang.routes.chats}`,
                icon: this.$assets.illustration.dashChats,
            },
            {
                name: this.$util.lang.pages.dashboardParent.settings,
                pos: 6,
                path: `/${this.$util.lang.routes.dashboard}/${this.$util.lang.routes.settings}`,
                icon: this.$assets.illustration.dashboardSettings,
            },
        ]
        return {
            mainTabs,
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.dashboardParent.title_seo
        const description = this.$util.lang.pages.dashboardParent.description_seo
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
})
