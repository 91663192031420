import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=5e82c14a&scoped=true&"
import script from "./_slug.vue?vue&type=script&lang=ts&"
export * from "./_slug.vue?vue&type=script&lang=ts&"
import style0 from "./_slug.vue?vue&type=style&index=0&id=5e82c14a&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e82c14a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroBreadcrumbs: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/Breadcrumbs.vue').default,CardPostUserInfo: require('/home/runner/work/okdescontos-front/okdescontos-front/components/card/PostUserInfo.vue').default,CardNews: require('/home/runner/work/okdescontos-front/okdescontos-front/components/card/News.vue').default})
