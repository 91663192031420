
import Vue, { PropType } from 'vue'

export default Vue.extend({
    name: 'SidebarLinks',
    props: {
        title: {
            type: String,
        },
        model: {
            type: String,
        },
        data: {
            type: Array as PropType<Array<{ text: string; handler: Function }>>,
        },
    },
})
