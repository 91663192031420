
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { format, parseISO } from 'date-fns'
import { pt as locale } from 'date-fns/locale'
import { Api, ServicesQueryParameters } from '~/global'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'UserChats',
    async asyncData({ $api, params: { user }, store }) {
        const { data, feedback } = await $api.general.loadForums({
            per_page: 10,
            user_id: user,
        })
        if (feedback === 'data_success') {
            store.commit('pages/setDashboardTab', {
                name: 'forums',
                data,
            })
        }
    },
    data() {
        const tabInfo = this.$store.state.pages.dashboard.tabs
            .forums as Api.Responses.General.IndividualModel<Models.Forum>['data']

        return {
            forums: tabInfo.results,
            fetchButton: {
                loading: false,
                show: tabInfo.meta.total > 0 && tabInfo.meta.current_page < tabInfo.meta.last_page,
            },
            params: {
                per_page: 10,
                page: 2,
                user_id: this.$route.params.user,
            } as ServicesQueryParameters.GetLastActivities,
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.dashboardUserChats.title_seo.replace(
            '{{name}}',
            this.$store.state.pages.dashboard.index.user.username
        )
        const description = this.$util.lang.pages.dashboardUserChats.description_seo.replace(
            '{{name}}',
            this.$store.state.pages.dashboard.index.user.username
        )
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
    methods: {
        async fetch() {
            this.fetchButton.loading = true

            const { feedback, data } = await this.$api.general.loadForums(this.params)

            if (feedback === 'data_success') {
                this.forums = [...this.forums, ...data.results]

                if (data.meta.current_page < data.meta.last_page) {
                    this.params.page = data.meta.current_page + 1
                } else {
                    this.fetchButton.show = false
                }
            }

            this.fetchButton.loading = false
        },
        formatDate(date: string): string {
            return format(parseISO(date), 'd MMM', { locale })
        },
    },
})
