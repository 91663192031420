
import Vue from 'vue'

export default Vue.extend({
    name: 'FormFieldTextArea',
    props: {
        value: {
            type: String,
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        bgcolor: {
            type: String,
        },
    },
    methods: {
        focus() {
            ;(this.$refs.input as HTMLInputElement).focus()
        },
        select() {
            ;(this.$refs.input as HTMLInputElement).select()
        },
        setValue(value: string) {
            ;(this.$refs.input as HTMLInputElement).value = value
        },
    },
})
