import { render, staticRenderFns } from "./index.vue?vue&type=template&id=9651b4ea&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=9651b4ea&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9651b4ea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnGeneric: require('/home/runner/work/okdescontos-front/okdescontos-front/components/btn/Generic.vue').default,MicroUserNoImage: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/userNoImage.vue').default,MicroBadge: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/Badge.vue').default,BtnSeeMoreResults: require('/home/runner/work/okdescontos-front/okdescontos-front/components/btn/SeeMoreResults.vue').default,DropdownOptions: require('/home/runner/work/okdescontos-front/okdescontos-front/components/dropdown/Options.vue').default,FormFieldGeneric: require('/home/runner/work/okdescontos-front/okdescontos-front/components/form/field/Generic.vue').default})
