import { render, staticRenderFns } from "./Coupons.vue?vue&type=template&id=65e78267&scoped=true&"
import script from "./Coupons.vue?vue&type=script&lang=ts&"
export * from "./Coupons.vue?vue&type=script&lang=ts&"
import style0 from "./Coupons.vue?vue&type=style&index=0&id=65e78267&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e78267",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnCoupon: require('/home/runner/work/okdescontos-front/okdescontos-front/components/btn/Coupon.vue').default})
