
import Vue, { PropType } from 'vue'
import { format } from 'date-fns'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'CardPostUserInfo',
    props: {
        post: {
            type: Object as PropType<Models.Article>,
        },
    },
    data() {
        return {}
    },
    methods: {
        formatDate(date: string): string {
            const newDate = new Date(date)
            return (
                format(newDate, 'dd/MM/yyyy') +
                ` ${this.$util.lang.components.cardPostUserInfo.at} ` +
                format(newDate, 'hh:ss') +
                'h'
            )
        },
    },
})
