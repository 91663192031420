
import Vue, { PropType } from 'vue'

export default Vue.extend({
    name: 'MicroNoResults',
    props: {
        button: Object as PropType<{
            color: 'primary' | 'secondary'
            text: string
            icon?: string
            path?: string
            handler?: () => any | void
        }>,
        mode: String as PropType<
            | 'discounts'
            | 'megaphone'
            | 'rewards'
            | 'rewards-won'
            | 'saved-discounts'
            | 'activities'
            | 'comments'
            | 'chats'
            | 'no-prizes'
            | 'no-prizes-available'
        >,
        title: String,
        subtitle: String,
    },
    data() {
        return {
            map: {
                discounts: this.$assets.gray.noDiscounts,
                megaphone: this.$assets.illustration.noDiscountsFoundTwo,
                rewards: this.$assets.gray.noRewards,
                'rewards-won': this.$assets.gray.noRewardsWon,
                'saved-discounts': this.$assets.gray.noSavedOffers,
                activities: this.$assets.gray.noActivities,
                comments: this.$assets.gray.noComments,
                chats: this.$assets.gray.noChats,
                'no-prizes': this.$assets.gray.noPrizes,
                'no-prizes-available': this.$assets.gray.noPrizesAvailable,
            },
        }
    },
})
