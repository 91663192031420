
import Vue from 'vue'
import encodeUrl from 'encodeurl'
import { MetaInfo } from 'vue-meta/types'
import { format } from 'date-fns'
import { parseImages } from '@/libs/images-functions'

export default Vue.extend({
    middleware: ['ifAuthenticated', 'ifAcceptTerms'],
    props: {
        options: {
            type: Object,
            default() {
                return {
                    url: '',
                    code: '',
                }
            },
        },
    },
    data() {
        return {
            loading: false,
            validationStatus: {
                url: false as boolean | string,
                title: false as boolean | string,
                description: false as boolean | string,
                discount_value: false as boolean | string,
                category_id: false as boolean | string,
                country_id: false as boolean | string,
            },
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.compartirCuponCrear.title_seo
        const description = this.$util.lang.pages.compartirCuponCrear.description_seo
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
    methods: {
        setValidations(
            name: 'url' | 'title' | 'description' | 'discount_value' | 'category_id' | 'country_id',
            status: boolean | string = false
        ) {
            this.validationStatus[name] = status
        },
        async makePost(data: any) {
            this.loading = true

            const { images, featured } = await parseImages(data)

            console.log('Images parse result: ', { images, featured })

            // Manipulamos la fecha inicial
            data.start_at = data.end_at = ''
            if (data.datePicker.start.date) {
                data.start_at = format(data.datePicker.start.date, 'yyyy-MM-dd')
                data.start_at += 'T' + data.datePicker.start.time + ':00'
            }
            // Manipulamos la fecha de finalizacion
            if (data.datePicker.end.date) {
                data.end_at = format(data.datePicker.end.date, 'yyyy-MM-dd')
                data.end_at += 'T' + data.datePicker.end.time + ':00'
            }

            // Creamos el nuevo formData
            const bodyFormData = new FormData()

            if (featured) bodyFormData.append('featured', featured)

            for (let i = 0; i < images.length; i++) {
                bodyFormData.append('images[]', images[i])
            }

            if (data.url) bodyFormData.append('url', data.url)
            if (data.discount.type) bodyFormData.append('discount_type', data.discount.type)
            if (data.discount.value) bodyFormData.append('discount_value', data.discount.value)
            if (data.code) bodyFormData.append('code', data.code)
            if (data.title) bodyFormData.append('title', data.title)
            if (data.description) bodyFormData.append('description', data.description)
            if (data.categoryId) bodyFormData.append('category_id', data.categoryId)
            if (data.start_at) bodyFormData.append('start_at', data.start_at)
            if (data.end_at) bodyFormData.append('expired_at', data.end_at)
            if (data.countryId) bodyFormData.append('country_id', data.countryId)
            if (data.isLocal) bodyFormData.append('is_local', data.isLocal)

            const response = await this.$api.create.postCoupons(bodyFormData)

            console.log('Create response: ', { response })

            // Si hay un error, lo mostramos
            if (!response) {
                this.$root.$emit('notification', {
                    duration: 5000,
                    text: this.$util.lang.pages.compartirCuponCrear.error_on_create,
                    type: 'error',
                })
            } else if (response.feedback === 'params_validation_failed') {
                this.validationStatus.url = response.data.url?.[0] || false
                this.validationStatus.title = response.data.title?.[0] || false
                this.validationStatus.description = response.data.description?.[0] || false
                this.validationStatus.discount_value = response.data.discount_value?.[0] || false
                this.validationStatus.category_id = response.data.category_id?.[0] || false
                this.validationStatus.country_id = response.data.country_id?.[0] || false

                this.$scrollTo(`#${Object.keys(response.data)[0]}`)
            } else if (response.feedback === 'resource_created') {
                this.$root.$emit('notification', {
                    duration: 5000,
                    text: this.$util.lang.pages.compartirCuponCrear.coupon_created,
                    type: 'success',
                })
                this.$router.push({
                    path: `/${this.$util.lang.routes.share}/${
                        this.$util.lang.routes.ended
                    }?variant=coupon&revision=false&slug=${response.data.coupon.slug}&title=${encodeUrl(
                        response.data.coupon.title
                    )}`,
                })
            } else if (response.feedback === 'resource_needs_approval') {
                this.$router.push({
                    path: `/${this.$util.lang.routes.share}/${
                        this.$util.lang.routes.ended
                    }?variant=offer&revision=true&title=${encodeUrl(response.data.coupon.title)}`,
                })
            } else {
                console.log('Response unhandled')
            }
            this.loading = false
        },
    },
})
