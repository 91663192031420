
import Vue from 'vue'

export type DropdownOptionType = {
    title: string
    icon?: string
    description?: string
    topBorder?: boolean
    subDropdown?: boolean
    options?: DropdownOptionType[]
    handler?: () => void
}

export default Vue.extend({
    name: 'DropdownOptions',
    props: {
        active: Number,
        options: {
            type: Array as () => Array<DropdownOptionType>,
            required: true,
        },
    },
    data() {
        const subDropdownsToggles = [] as boolean[]

        return {
            subDropdownsToggles,
        }
    },
    methods: {
        resolveHandler(option: DropdownOptionType, index: number) {
            if (option.subDropdown) {
                this.$set(this.subDropdownsToggles, index, !this.subDropdownsToggles[index])
            } else {
                option.handler?.()
                this.$emit('close')
            }
        },
    },
})
