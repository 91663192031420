
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { formatDuration, intervalToDuration } from 'date-fns'
import { Api, ServicesQueryParameters } from '~/global'
import { getDictionary } from '~/libs/variant-dict'
import { DropdownOptionType } from '~/components/dropdown/Options.vue'
import { Models } from '~/types/models'
import { removeXSS } from '~/libs/useful-functions'
import { currentDate } from '~/libs/date-functions'

export default Vue.extend({
    name: 'Thread',
    async validate({ $api, store, params: { slug }, error, $util }) {
        const storeResult = await $api.pages.loadThread(slug)

        if (storeResult.feedback === 'data_success') {
            store.commit('pages/setValue', {
                name: 'thread',
                page: storeResult.data,
            })
        } else if (storeResult.feedback === 'resource_not_found') {
            error({
                statusCode: 404,
                message: $util.lang.pages.forosSlug.thread_not_found,
            })
        } else {
            error({
                statusCode: 404,
                message: $util.lang.pages.forosSlug.unexpected_error,
            })
        }

        return true
    },
    data() {
        const storeResult = this.$store.state.pages.thread as Api.Responses.Pages.Thread['data']

        const { current_page: current_page_discussions, last_page: last_page_discussions } =
            storeResult.discussions.meta

        const pagination = {
            discussions: {
                show: true,
                loading: false,
            },
        }

        if (!last_page_discussions || current_page_discussions === last_page_discussions) {
            pagination.discussions.show = false
        }

        const type = getDictionary('forum')

        let commentsLoaded = 0

        storeResult.comments.forEach((c) => {
            commentsLoaded++
            c.comments?.forEach(() => {
                commentsLoaded++
            })
        })

        const showCommentsLoadButton = (storeResult.forum.comments_count || 0) > commentsLoaded

        const currentDates = currentDate()

        const [title, description] = this.$util.replacePlaceholders(
            [
                [':month', currentDates.month || ''],
                [':year', String(currentDates.year) || ''],
                [':day', String(currentDates.day) || ''],
                [':site', process.env.SITE_NAME || ''],
            ],
            [storeResult.forum.seo?.title || '', storeResult.forum.seo?.description || '']
        )

        return {
            creatingComment: false,
            storeResult,
            pagination,
            metaInfo: {
                title,
                description,
            },
            type,
            activeFilter: 0,
            follow: false,
            tabsData: {
                discussions: [...storeResult.discussions.results],
            },
            displayedItemFlags: {
                showCommentsLoadButton,
                showCommentMenu: false,
                showDiscountMenu: false,
                showDiscountMenuMobile: false,
            },
            nextPageQuery: {
                commentable_type: type.variant,
                commentable_id: storeResult.forum.id,
                per_page: 15,
                page: 2,
                order_by: 'most-liked',
            } as ServicesQueryParameters.GetComments,
            linkToCommentId: null as null | number,
            loadingItemFlags: {
                commentsLoadButton: false,
            },
            comments: [] as Models.Comment[],
            dropdown: {
                show: {
                    orderby: false,
                },
                orderby: {
                    data: [this.$util.lang.pages.forosSlug.popular, this.$util.lang.pages.forosSlug.new],
                    selected: this.$util.lang.pages.forosSlug.popular,
                },
            },
            removeXSS,
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                ],
                this.metaInfo?.title
            ),
        }
    },
    computed: {
        descriptionSanitized(): string | null {
            return removeXSS(this.storeResult.forum.description)
        },
        updatedDate(): string {
            const duration = intervalToDuration({
                start: new Date(this.storeResult.forum.created_at as any),
                end: new Date(),
            })

            const units = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']
            const units_spanish = [
                this.$util.lang.pages.forosSlug.years,
                this.$util.lang.pages.forosSlug.months,
                this.$util.lang.pages.forosSlug.weeks,
                this.$util.lang.pages.forosSlug.days,
                this.$util.lang.pages.forosSlug.hours,
                this.$util.lang.pages.forosSlug.minutes,
                this.$util.lang.pages.forosSlug.seconds,
            ]
            const nonzero = Object.entries(duration)
                .filter(([_, value]) => value)
                .map(([unit, _]) => unit)

            const formattedDuration = formatDuration(duration, {
                format: units.filter((i) => new Set(nonzero).has(i)).slice(0, 1),
                delimiter: ', ',
            })

            return formattedDuration.replace(
                /\b(years|months|weeks|days|hours|minutes|seconds)\b/g,
                (match) => {
                    return units_spanish[units.indexOf(match)]
                }
            )
        },
        dropdownOptions(): {
            commentOptions: DropdownOptionType[]
            discountOptions: DropdownOptionType[]
        } {
            return {
                commentOptions: [
                    {
                        title: this.$util.lang.pages.forosSlug.more_relevant,
                        icon: this.$assets.primary.bookmark,
                        description: this.$util.lang.pages.forosSlug.see_more_relevant_comments,
                        handler: () => {
                            this.nextPageQuery.order_by = 'most-liked'
                            this.activeFilter = 0
                        },
                    },
                    {
                        title: this.$util.lang.pages.forosSlug.more_recents,
                        description: this.$util.lang.pages.forosSlug.see_all_comments,
                        icon: this.$assets.primary.subscribe,
                        handler: () => {
                            this.nextPageQuery.order_by = 'latest'
                            this.activeFilter = 1
                        },
                    },
                ],
                discountOptions: [
                    {
                        title: this.$util.lang.pages.forosSlug.report,
                        icon: this.$assets.primary.shield,
                        description: this.$util.lang.pages.forosSlug.why_report,
                        subDropdown: true,
                        options: [
                            {
                                title: this.$util.lang.pages.forosSlug.spam_ofensive,
                                icon: this.$assets.primary.span,
                                handler: () => this.createReport('Spam/Ofensivo'),
                            },
                            {
                                title: this.$util.lang.pages.forosSlug.duplicated,
                                description: this.$util.lang.pages.forosSlug.offer_published,
                                icon: this.$assets.primary.copyCode,
                                handler: () => this.createReport('Duplicado'),
                            },
                            {
                                title: this.$util.lang.pages.forosSlug.refered_links,
                                icon: this.$assets.primary.referralLink,
                                handler: () => this.createReport('Enlaces referidos'),
                            },
                            {
                                title: this.$util.lang.pages.forosSlug.other,
                                icon: this.$assets.primary.about,
                                handler: () => this.createReport('Otro'),
                            },
                        ],
                    },
                ],
            }
        },

        breadcrumbs(): { path?: string; title: string }[] {
            return [
                {
                    path: '/',
                    title: this.$util.lang.pages.forosSlug.home,
                },
                {
                    path: `/${this.$util.lang.routes.forum}`,
                    title: this.$util.lang.pages.forosSlug.forums,
                },
                {
                    path: `/${this.$util.lang.routes.forum}`,
                    title: this.storeResult.forum.category?.name || '',
                },
                {
                    title: this.storeResult.forum.title || '',
                },
            ]
        },
        nextTabPage(): number {
            return this.storeResult.discussions.meta.current_page + 1
        },
    },
    watch: {
        async 'nextPageQuery.order_by'() {
            this.$nuxt.$loading.start()
            await this.loadComments()
            this.$nuxt.$loading.finish()
        },
        'dropdown.orderby.selected'() {
            this.loadMoreDiscussions(this.dropdown.orderby.selected)
        },
    },
    async mounted() {
        setTimeout(() => {
            this.$nextTick(() => {
                this.storeResult.banners?.length &&
                    this.$store.dispatch('assignBanners', this.storeResult.banners)
            })
        }, 2000)

        let comments: Models.Comment[] =
            this.storeResult.comments && this.storeResult.comments.length
                ? [...this.storeResult.comments]
                : []

        if (/#(comment|reply)-\d+/.test(this.$route.hash)) {
            const [type, id] = this.$route.hash.split('-')

            const commentType = type.slice(1)

            const commentId = Number(id)

            const comment = comments.find((comment) => {
                return comment.id === commentId || !!comment.comments?.find((reply) => reply.id === commentId)
            })

            if (comment) {
                this.comments = comments
                this.$nextTick(() => setTimeout(() => this.scrollToCommentsSection(this.$route.hash), 500))
            } else {
                try {
                    const commentResult = await this.$api.general.loadComments({
                        without_pagination: true,
                        limit: 1,
                        [commentType === 'comment' ? 'id' : 'reply_id']: id,
                    })

                    if (commentResult.feedback === 'data_success' && commentResult.data.results.length) {
                        this.linkToCommentId = commentResult.data.results[0].id

                        comments = comments.filter((comment) => comment.id !== this.linkToCommentId)

                        this.comments = [...commentResult.data.results, ...comments]

                        this.$nextTick(() =>
                            setTimeout(
                                () => this.scrollToCommentsSection(`#comment-${this.linkToCommentId}`),
                                500
                            )
                        )
                    }
                } catch (e) {}
            }
        } else if (this.$route.hash === '#bloque-de-comentarios') {
            this.comments = comments
            this.$nextTick(() =>
                setTimeout(() => this.scrollToCommentsSection('#bloque-de-comentarios'), 500)
            )
        } else {
            this.comments = comments
        }
    },
    methods: {
        scrollToCommentsSection(blockId: string) {
            this.$scrollTo(blockId)
            if (blockId === '#bloque-de-comentarios') {
                setTimeout(() => this.$root.$emit('focusQuill'), 600)
            }
        },
        async loadMoreDiscussions(order_changed: string | false = false) {
            this.pagination.discussions.loading = true

            const newInfo = JSON.parse(JSON.stringify(this.storeResult)) as Api.Responses.Pages.Thread['data']

            let resultsOrder = null

            if (order_changed) {
                switch (order_changed) {
                    case this.$util.lang.pages.forosSlug.new:
                        resultsOrder = 'latest'
                        break
                    case this.$util.lang.pages.forosSlug.popular:
                        resultsOrder = 'popular'
                        break
                }
            }

            const result = await this.$api.pages.loadThread(this.$route.params.slug, {
                page: order_changed ? '' : this.nextTabPage,
                'order-by': resultsOrder,
            })

            if (result.feedback === 'data_success') {
                if (order_changed === false) {
                    newInfo.discussions.results = []
                    newInfo.discussions.meta = result.data.discussions.meta

                    this.tabsData.discussions = [
                        ...this.tabsData.discussions,
                        ...result.data.discussions.results,
                    ]
                    newInfo.discussions.meta = result.data.discussions.meta

                    newInfo.discussions.results.push(...result.data.discussions.results)
                    if (result.data.discussions.meta) {
                        const { current_page, last_page } = result.data.discussions.meta
                        if (!last_page || current_page === last_page) {
                            this.pagination.discussions.show = false
                        }
                    }
                } else if (order_changed) {
                    newInfo.discussions = result.data.discussions
                }
            }

            this.$store.commit('pages/setValue', {
                name: 'thread',
                page: newInfo,
            })
            this.storeResult = this.$store.state.pages.thread as Api.Responses.Pages.Thread['data']

            this.pagination.discussions.loading = false
        },
        async createComment(reply: { callback: (clear: boolean) => void; html: string }) {
            if (!this.creatingComment) {
                this.creatingComment = true
                try {
                    const commentResult = await this.$api.create.postComment({
                        commentable_id: this.storeResult.forum.id,
                        commentable_type: 'forum',
                        content: reply.html,
                    })

                    if (
                        commentResult.feedback === 'resource_created' ||
                        commentResult.feedback === 'resource_needs_approval'
                    ) {
                        reply.callback(true)

                        if (commentResult.feedback === 'resource_created') {
                            this.$root.$emit('notification', {
                                duration: 5000,
                                dismissButtonText: this.$util.lang.pages.forosSlug.hide,
                                text: this.$util.lang.pages.forosSlug.published_comments,
                                type: 'success',
                            })
                            this.comments = [commentResult.data, ...this.comments]
                            this.$nextTick(() => {
                                this.$root.$emit('active-comment-pulses', commentResult.data.id)
                            })
                        }
                        if (commentResult.feedback === 'resource_needs_approval') {
                            this.$root.$emit('notification', {
                                duration: 5000,
                                dismissButtonText: this.$util.lang.pages.forosSlug.hide,
                                text: this.$util.lang.pages.forosSlug.pending_comment,
                                type: 'help',
                            })
                        }
                    }
                } catch (e) {
                    reply.callback(false)
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.pages.forosSlug.hide,
                        text: this.$util.lang.pages.forosSlug.comment_error,
                        type: 'error',
                    })
                }
                this.creatingComment = false
            }
        },
        async createReport(reason: Parameters<Api.Services['create']['postReport']>[0]['reason']) {
            const reportResult = await this.$api.create.postReport({
                reportable_type: 'forum',
                reportable_id: this.storeResult.forum.id,
                reason,
            })

            if (reportResult.feedback === 'data_success') {
                this.$root.$emit('notification', {
                    duration: 5000,
                    dismissButtonText: this.$util.lang.pages.forosSlug.okay,
                    text: this.$util.lang.pages.forosSlug.report_success,
                    type: 'success',
                })
            } else {
                this.$root.$emit('notification', {
                    duration: 5000,
                    dismissButtonText: this.$util.lang.pages.forosSlug.close,
                    text: this.$util.lang.pages.forosSlug.report_error,
                    type: 'error',
                })
            }
        },
        async loadComments() {
            const commentsResult = await this.$api.general.loadComments(this.nextPageQuery)

            if (commentsResult.feedback === 'data_success') {
                if (commentsResult.data.meta.current_page === 1) {
                    this.comments = [...commentsResult.data.results]
                } else {
                    const comments = this.linkToCommentId
                        ? commentsResult.data.results.filter((comment) => comment.id !== this.linkToCommentId)
                        : commentsResult.data.results

                    this.comments = [...this.comments, ...comments]
                }

                if (commentsResult.data.meta.current_page < commentsResult.data.meta.last_page) {
                    this.nextPageQuery.page = commentsResult.data.meta.current_page + 1
                } else {
                    this.displayedItemFlags.showCommentsLoadButton = false
                }
            }
        },
        async loadCommentsButtonHandler() {
            if (!this.loadingItemFlags.commentsLoadButton) {
                this.loadingItemFlags.commentsLoadButton = true
                await this.loadComments()
                this.loadingItemFlags.commentsLoadButton = false
            }
        },
        dropdownToggleEasy(show: boolean, name: string): void {
            this.dropdown.show[(name as 'orderby') || 'report'] = show
        },
        dropdownToggle(
            show: boolean,
            key: 'showCommentsLoadButton' | 'showCommentMenu' | 'showDiscountMenu' | 'showDiscountMenuMobile'
        ): void {
            if (
                (this.$bp.mb || this.$bp.xs || this.$bp.sm) &&
                (key === 'showCommentMenu' || key === 'showDiscountMenu' || key === 'showDiscountMenuMobile')
            ) {
                this.$store.commit(
                    'setDropdown',
                    show
                        ? this.dropdownOptions[
                              key === 'showCommentMenu' ? 'commentOptions' : 'discountOptions'
                          ]
                        : null
                )
            } else {
                this.displayedItemFlags[key] = show
            }
        },
    },
})
