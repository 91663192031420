
import { isBefore, parseISO } from 'date-fns'
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'LayoutHeaderFloatingResume',
    props: {
        info: {
            type: Object as PropType<{
                discount: Models.Discount
                mode: string
            }>,
        },
    },
    data() {
        return {
            showResume: false,
            listener: null as null | (() => void),
            timeout: null as null | NodeJS.Timeout,
        }
    },
    computed: {
        expired(): boolean {
            return (
                !!this.info.discount?.expired_at &&
                isBefore(parseISO(this.info.discount?.expired_at), new Date())
            )
        },
    },
    mounted() {
        let lastScrollY = scrollY

        this.listener = () => {
            this.showResume = lastScrollY <= scrollY && scrollY >= 600

            lastScrollY = window.scrollY
        }

        document.addEventListener('scroll', this.listener)
    },
    destroyed() {
        this.listener && document.removeEventListener('scroll', this.listener)
    },
    methods: {
        openCoupon() {
            this.$openCoupon(this.info.discount.slug)
        },
        openOffer() {
            this.$openOffer(this.info.discount.id)
        },
    },
})
