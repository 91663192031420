
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'

export default Vue.extend({
    name: 'PasswordResetSuccess',
    middleware: ['ifNotAuthenticated'],
    head(): MetaInfo {
        const title = 'Cambiar contraseña | OKdescontos'
        const description = 'Página para cambiar la contraseña'
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
})
