
import Vue, { PropOptions } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'ModalDashboardUsername',
    props: {
        info: Object as PropOptions<{
            callback: (success: boolean) => void
        }>,
    },
    data() {
        const form = {
            data: {
                username: '',
            },
            validationStatus: {
                username: false as boolean | string,
            },
        }

        return {
            form,
            loading: false,
        }
    },
    computed: {
        userData(): Models.User {
            return this.$store.state.user.profile
        },
    },
    methods: {
        cancelHandler() {
            this.$emit('close')
        },
        async continueHandler() {
            this.loading = true
            await this.onSave()
            this.loading = false
        },
        async onSave() {
            if (this.form.data.username) {
                await this.updateProfile()
            } else if (!!this.form.data.username && this.form.data.username.includes(' ')) {
                this.form.validationStatus.username =
                    this.$util.lang.components.modalDashboardUsername.no_blank_spaces
            }
        },
        async updateProfileInfo() {
            try {
                const userData = await this.$api.pages.loadUserProfile(this.userData.id)

                if (userData.feedback === 'data_success') {
                    this.$store.commit('pages/setDashboard', userData.data)
                }
            } catch (e) {}
        },
        async updateProfile() {
            const profileBody = {} as { [key: string]: any }

            !!this.form.data.username && (profileBody.username = this.form.data.username)

            try {
                const profileResult = await this.$api.user.updateProfile(
                    String(this.userData.id),
                    profileBody
                )

                if (profileResult.feedback === 'data_updated_success') {
                    await this.updateProfileInfo()

                    this.$root.$emit('notification', {
                        type: 'success',
                        text: this.$util.lang.components.modalDashboardUsername.profile_updated,
                        duration: 4000,
                    })

                    this.$emit('close')
                } else if (
                    profileResult.feedback === 'data_error' ||
                    profileResult.feedback === 'params_validation_failed'
                ) {
                    this.form.validationStatus.username = profileResult.data.username[0] || false
                }
            } catch (e) {}
        },
    },
})
