
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { Api } from '@/global'
import { currentDate } from '~/libs/date-functions'
import { removeXSS } from '~/libs/useful-functions'

export default Vue.extend({
    name: 'NoveltyDetails',
    async validate({ $api, store, params: { slug }, error, $util }) {
        const storeResult = await $api.pages.loadNovelty(slug)

        if (storeResult.feedback === 'data_success') {
            store.commit('pages/setValue', {
                name: 'novelty',
                page: storeResult.data,
            })
        } else if (storeResult.feedback === 'resource_not_found') {
            error({
                statusCode: 404,
                message: $util.lang.pages.novedadesSlug.novelty_not_found,
            })
        } else {
            error({
                statusCode: 404,
                message: $util.lang.pages.novedadesSlug.unexpected_error,
            })
        }

        return true
    },
    data() {
        const storeResult = this.$store.state.pages.novelty as Api.Responses.Pages.Novelty['data']

        const currentDates = currentDate()

        const [title, description] = this.$util.replacePlaceholders(
            [
                [':month', currentDates.month || ''],
                [':year', String(currentDates.year) || ''],
                [':title', this.$util.textShortener(storeResult.novelty.title || '', 40)],
                [
                    ':description',
                    this.$util.textShortener(
                        storeResult.novelty.content?.replace(/<\/?[a-z][a-z0-9]*[^<>]*>|<!--.*?-->/gim, '') ||
                            '',
                        150
                    ),
                ],
                [':site', process.env.SITE_NAME || ''],
            ],
            [storeResult.novelty.seo?.title || '', storeResult.novelty.seo?.description || '']
        )

        return {
            storeResult,
            metaInfo: {
                title,
                description,
            },
            removeXSS,
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['og:image:secure_url', this.storeResult.novelty.image?.url || ''],
                    ['twitter:image', this.storeResult.novelty.image?.url || ''],
                    ['og:image', this.storeResult.novelty.image?.url || ''],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                ],
                this.metaInfo?.title
            ),
        }
    },
    computed: {
        breadcrumbs(): { path?: string; title: string }[] {
            return [
                {
                    path: '/',
                    title: this.$util.lang.pages.novedadesSlug.home,
                },
                {
                    path: `/${this.$util.lang.routes.novelties}`,
                    title: this.$util.lang.pages.novedadesSlug.novelties,
                },
                {
                    title: this.storeResult.novelty.title || '',
                },
            ]
        },
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {},
})
