
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { Api } from '~/global'

export default Vue.extend({
    name: 'Help',
    async asyncData({ $api }) {
        const home = await $api.info.loadHelp()
        return {
            info: home.page,
        }
    },
    data() {
        return {
            info: {} as Api.Responses.Info.Basic['page'],
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.info.description],
                    ['title', this.info.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.info.title],
                    ['og:image:alt', this.info.title],
                    ['og:description', this.info.description],
                ],
                this.info.title
            ),
        }
    },
})
