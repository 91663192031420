
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { Api } from '@/global'

import { currentDate } from '~/libs/date-functions'
export default Vue.extend({
    name: 'Stores',
    data() {
        const info = this.$store.state.pages.stores as Api.Responses.Pages.Stores
        const currentDates = currentDate()

        const letterTitle = 'Lojas com a Letra :letter | OKdescontos'
        const letterDesc =
            'Explore os cupons de desconto para todas as nossas lojas parceiras que começam com a letra :letter no OKdescontos. Economize nas suas lojas favoritas com :letter!'

        const placeholder: [string, string][] = !this.$route.params.slug
            ? [
                  [':month', currentDates.month || ''],
                  [':day', String(currentDates.day) || ''],
                  [':year', String(currentDates.year) || ''],
                  [':site', process.env.SITE_NAME || ''],
              ]
            : [[':letter', this.$route.params.slug.toUpperCase() || '']]

        const [title, description] = this.$util.replacePlaceholders(placeholder, [
            this.$route.params.slug ? letterTitle : info.page.title || '',
            this.$route.params.slug ? letterDesc : info.page.description || '',
        ])

        return {
            info,
            metaInfo: { title, description },
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                ],
                this.metaInfo?.title
            ),
        }
    },
    mounted() {
        setTimeout(() => {
            this.$nextTick(() => {
                this.info.page.banners && this.$store.dispatch('assignBanners', this.info.page.banners)
            })
        }, 2000)
    },
})
