
import Vue from 'vue'

export default Vue.extend({
    name: 'ModalAuthRegister',
    data() {
        return {
            termsAccept: false,
            termsAcceptAlert: false,
            newsAccept: false,
            form: {
                data: {
                    email: '',
                    name: '',
                    password: '',
                },
                validationStatus: {
                    email: false as string | boolean,
                    name: false as string | boolean,
                    password: false as string | boolean,
                },
            },
        }
    },
    computed: {
        rrss(): {
            text: string
            icon: string
            iconAlt: string
            handler: () => any
        }[] {
            return [
                {
                    text: this.$util.lang.components.modalAuthRegister.register_google,
                    icon: this.$assets.social.googleAccount,
                    iconAlt: 'Google button',
                    handler: () => this.socialSignUp('google'),
                },
                {
                    text: this.$util.lang.components.modalAuthRegister.register_facebook,
                    icon: this.$assets.social.facebookAccount,
                    iconAlt: 'Facebook button',
                    handler: () => this.socialSignUp('facebook'),
                },
            ]
        },
    },
    methods: {
        async signUp() {
            if (this.termsAccept) {
                this.$nuxt.$loading.start()

                this.$store.commit('auth/setAcceptTerms', true)

                const result = await this.$api.auth.signUp(this.form.data)

                if (result.feedback === 'params_validation_failed') {
                    this.form.validationStatus = {
                        email: result.data.email?.[0] || false,
                        name: result.data.name?.[0] || false,
                        password: result.data.password?.[0] || false,
                    }
                }

                if (result.feedback === 'singup_success') {
                    this.$store.commit('setModal', {
                        name: 'ModalAuthEmailVerification',
                        info: {
                            isPage: false,
                        },
                        onClose: () =>
                            this.$root.$emit('notification', {
                                duration: 5000,
                                text: this.$util.lang.components.modalAuthRegister.email_confirmation,
                                type: 'help',
                            }),
                    })
                }
                this.$nuxt.$loading.finish()
            } else {
                this.termsAcceptAlert = true
            }
        },
        socialSignUp(strategy: 'google' | 'facebook') {
            this.$api.auth.socialSingIn(strategy, {
                success_url: location.origin + location.pathname,
                error_url: location.origin + location.pathname,
            })
        },
        changeModal() {
            this.$store.commit('setModal', { name: 'ModalAuthLogin' })
        },
    },
})
