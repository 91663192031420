import { render, staticRenderFns } from "./GlobalFeatured.vue?vue&type=template&id=73401792&scoped=true&"
import script from "./GlobalFeatured.vue?vue&type=script&lang=ts&"
export * from "./GlobalFeatured.vue?vue&type=script&lang=ts&"
import style0 from "./GlobalFeatured.vue?vue&type=style&index=0&id=73401792&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73401792",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroUserImage: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/userImage.vue').default})
