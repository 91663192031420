
import Vue from 'vue'

export default Vue.extend({
    name: 'DropdownDetailsCommentsOrder',
    props: {
        options: {
            type: Object as () => {
                data: Array<{
                    title: string
                    icon: string
                    style: string
                    description: string
                }>
                selected: string
            },
        },
        classes: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        selectColor: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        filterBySelected(newSelected: any) {
            const newoption = this.options
            newoption.selected = newSelected
            this.$emit('update:options', newoption)
        },
    },
})
