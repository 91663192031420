
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'

export default Vue.extend({
    name: 'ModalAuthPassword',
    middleware: ['ifNotAuthenticated'],
    validate({ query, redirect, $util }) {
        if (typeof query?.code !== 'string') {
            redirect(302, { path: $util.lang.routes.forgotPassword })
        }
        return true
    },
    data() {
        return {
            ready: false,
            form: {
                data: {
                    password: '',
                    password_confirmation: '',
                    token: this.$route.query.code as string,
                },
                validationStatus: {
                    password: false as string | boolean,
                    password_confirmation: false as string | boolean,
                    token: false as string | boolean,
                },
            },
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.nuevaContrasena.title_seo
        const description = this.$util.lang.pages.nuevaContrasena.description_seo
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
    watch: {
        'form.data': {
            deep: true,
            handler() {
                this.ready =
                    this.form.data.password === this.form.data.password_confirmation &&
                    !!this.form.data.password?.length &&
                    !!this.form.data.password_confirmation?.length
            },
        },
    },
    methods: {
        async resetPassword() {
            this.$nuxt.$loading.start()

            const result = await this.$api.auth.recoveryFinish('withToken', this.form.data)

            if (result.feedback === 'data_error') {
                this.form.validationStatus.password = result.data.password?.[0] || false

                this.form.validationStatus.password_confirmation =
                    result.data.password_confirmation?.[0] || false

                this.form.validationStatus.token = result.data.token?.[0] || false

                this.$root.$emit('notification', {
                    duration: 5000,
                    dismissButtonText: this.$util.lang.pages.nuevaContrasena.close,
                    text: this.$util.lang.pages.nuevaContrasena.incorrect_fields,
                    type: 'error',
                })
            }

            if (result.feedback === 'not_found') {
                this.$root.$emit('notification', {
                    duration: 5000,
                    dismissButtonText: 'Cerrar',
                    text: this.$util.lang.pages.nuevaContrasena.token_failed,
                    type: 'error',
                })
            }
            if (result.feedback === 'data_updated_success') {
                this.$router.push({ name: 'contrasena-actualizada-exitosamente' })
            }

            this.$nuxt.$loading.finish()
        },
    },
})
