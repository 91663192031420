
import Vue from 'vue'

export default Vue.extend({
    name: 'LayoutModalSecondary',
    props: {
        modal: Object,
    },
    data() {
        return {
            mountModal: false,
        }
    },
    watch: {
        modal: {
            immediate: true,
            handler(modal: object | null) {
                !!modal && this.mount(true)
            },
        },
        $route: {
            deep: true,
            handler() {
                this.modal && this.mount(false)
            },
        },
    },
    methods: {
        mount(mount: boolean) {
            if (mount && this.modal) {
                this.mountModal = mount

                this.modal.onOpen && this.modal.onOpen()
            } else {
                this.mountModal = mount

                if (this.modal !== null && 'onClose' in this.modal) this.modal.onClose()

                this.$nextTick(() => {
                    this.$store.commit('setModalSecondary', null)
                })
            }
        },
    },
})
