
import Vue, { PropType } from 'vue'
import { currentDate } from '~/libs/date-functions'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'SidebarDescription',
    props: {
        info: Object as PropType<Models.Content>,
    },
    data() {
        const currentDates = currentDate()
        const [title, content] = this.$util.replacePlaceholders(
            [
                [':month', currentDates.month || ''],
                [':year', String(currentDates.year) || ''],
                [':day', String(currentDates.day) || ''],
                [':site', process.env.SITE_NAME || ''],
            ],
            [this.info.title || '', this.info.content || '']
        )
        return {
            title,
            content,
        }
    },
})
