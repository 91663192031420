
import Vue from 'vue'

import { PropOptions } from 'vue/types'

export default Vue.extend({
    name: 'BtnGeneric',
    props: {
        loading: { type: Boolean, default: false },
        info: Object as PropOptions<{
            text: string
            link: string
            handler: Function
            icon: string
            iconAlt: string
            iconPosition: 'left' | 'right'
        }>,
    },
})
