
import Vue from 'vue'

export default Vue.extend({
    name: 'ModalCookiesAdjust',
    data() {
        return {
            cookies: [
                {
                    slug: 'COOKIE_STRICT_NECESSARY',
                    title: this.$util.lang.components.modalCookiesAdjust.cookies_strict,
                    description:
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis tortor mi sagittis fermentum arcu. Amet, aliquet varius vestibulum dignissim proin nibh mauris rutrum. Cras eu pulvinar vitae semper vel nisl. Facilisi lectus feugiat nam at augue.',
                    state: true,
                    disabled: true,
                },
                {
                    slug: 'COOKIE_PERSONALIZATION',
                    title: this.$util.lang.components.modalCookiesAdjust.cookies_personalization,
                    description:
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam pharetra pharetra, nunc id. Aliquam tortor id scelerisque non. Donec vitae magna justo, amet hendrerit. Id magnis odio ut vulputate. Duis diam purus elementum sed ullamcorper placerat sapien. Risus eu viverra diam pellentesque in adipiscing. Maecenas risus libero sagittis, mattis vestibulum amet sed nam malesuada. Sit mattis mollis magna nulla eu in. Accumsan, lacus erat pellentesque nunc etiam maecenas ut.',
                    state: false,
                },
                {
                    slug: 'COOKIE_FUNCTIONALS',
                    title: this.$util.lang.components.modalCookiesAdjust.cookies_functional,
                    description:
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam pharetra pharetra, nunc id. Aliquam tortor id scelerisque non. Donec vitae magna justo, amet hendrerit. Id magnis odio ut vulputate. Duis diam purus elementum sed ullamcorper placerat sapien.',
                    state: false,
                },
                {
                    slug: 'COOKIE_PERSONALIZATION_COPY',
                    title: this.$util.lang.components.modalCookiesAdjust.cookies_personalization2,
                    description:
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam pharetra pharetra, nunc id. Aliquam tortor id scelerisque non. Donec vitae magna justo, amet hendrerit. Id magnis odio ut vulputate. Duis diam purus elementum sed ullamcorper placerat sapien. Risus eu viverra diam pellentesque in adipiscing. Maecenas risus libero sagittis.',
                    state: false,
                },
            ],
        }
    },
    methods: {
        onSubmit(notification: boolean) {
            this.cookies.forEach((item) => {
                localStorage.setItem(item.slug, item.state.toString())
            })

            this.$emit('close')

            if (notification) {
                this.$root.$emit('notification', {
                    duration: 5000,
                    dismissButtonText: this.$util.lang.components.modalCookiesAdjust.hide,
                    text: this.$util.lang.components.modalCookiesAdjust.cookies_saved,
                    type: 'success',
                })
            }
        },
        changeModal() {
            this.$store.commit('setModal', { name: 'ModalCookiesCookies' })
        },
    },
})
