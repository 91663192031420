
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'LayoutHeaderSuggestions',
    props: {
        stores: { type: Array as PropType<Models.Store[]> },
        categories: { type: Array as PropType<Models.Category[]> },
        events: { type: Array as PropType<Models.Event[]> },
    },
})
