
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'SidebarRatingStars',
    props: {
        info: Object as PropType<Models.Store>,
    },
    data() {
        return {
            stars: [1, 2, 3, 4, 5],
            starsElements: null as null | HTMLCollection,
            userRating: null as null | number,
            userRatingNow: false,
            totalRatingStars: this.info.score || 0,
            totalRatingsCount: this.info.ratings_count || 0,
        }
    },
    computed: {
        ratingFeedback(): string[] {
            if (this.info.user_rating || this.userRating) {
                const feedback1 = `Hay ${this.totalRatingsCount} ${
                    this.totalRatingsCount > 1 ? 'votos' : 'voto'
                } y la media es ${this.totalRatingStars}`

                const feedback2 = `Votaste con ${this.info.user_rating || this.userRating} estrellas`

                return [feedback1, feedback2]
            } else if (this.info.score) {
                const feedback1 = `${this.info.ratings_count || 0} ${
                    (this.info.ratings_count || 0) > 1 ? 'usuarios' : 'usuario'
                } ${(this.info.ratings_count || 0) > 1 ? 'votaron' : 'votó'} y la media es de ${
                    this.info.score
                }`

                return [feedback1]
            } else {
                const feedback1 = `Dar clic para calificar a la tienda ${this.info.name}`
                return [feedback1]
            }
        },
        starsCanBeStyledOnMouseEvents(): boolean {
            return !this.info.user_rating && !this.userRating && !this.userRatingNow
        },
    },
    mounted() {
        this.$forceUpdate()

        this.starsElements = (this.$refs.starsRatingContainer as HTMLDivElement).children

        if (this.info && this.info.score) {
            let i = 0

            while (i < 5) {
                const color = Math.round(this.info.score) < i + 1 ? '#eeeeee' : '#ecd500'

                const firstChild = this.starsElements[i].firstChild as HTMLElement

                if (firstChild) {
                    firstChild.setAttribute('fill', color)
                }

                i++
            }
        }
    },
    methods: {
        onMouseEnter(e: MouseEvent, grade: number) {
            if (this.starsElements && this.starsCanBeStyledOnMouseEvents) {
                const scoredStarsRestricted = this.info.score && grade > Math.round(this.info.score)

                if (scoredStarsRestricted || !this.info.score) {
                    let targetIluminated = false

                    let i = this.info.score ? Math.round(this.info.score) - 1 : 0

                    while (i < this.starsElements.length) {
                        const color = targetIluminated ? '#eeeeee' : '#ecd500'

                        const firstChild = this.starsElements[i].firstChild as HTMLElement

                        if (firstChild) {
                            firstChild.setAttribute('fill', color)
                        }

                        if (e.target === this.starsElements[i]) targetIluminated = true

                        i++
                    }
                }
            }
        },
        onMouseLeave() {
            if (this.starsElements && this.starsCanBeStyledOnMouseEvents) {
                for (let i = Math.round(this.info.score || 0) || 0; i < this.starsElements.length; i++) {
                    const firstChild = this.starsElements[i].firstChild as HTMLElement

                    if (firstChild) {
                        firstChild.setAttribute('fill', '#eeeeee')
                    }
                }
            }
        },
        async onRating(rating: number) {
            if (!this.userRatingNow /* && !this.info.user_rating */ && !this.userRating) {
                this.userRatingNow = true
                ;(this.$refs.starsRatingContainer as HTMLDivElement).style.cursor = 'wait'

                const ratingResult = await this.$api.general.ratingStore({
                    store_id: this.info.id,
                    grade: rating,
                })

                if (ratingResult?.feedback === 'data_success') {
                    this.userRating = ratingResult.data.user_rating

                    this.totalRatingStars = ratingResult.data.new_score

                    this.totalRatingsCount = ratingResult.data.ratings_count

                    if (this.starsElements) {
                        let i = 0

                        while (i < 5) {
                            const color = this.totalRatingStars < i + 1 ? '#eeeeee' : '#ecd500'

                            const firstChild = this.starsElements[i].firstChild as HTMLElement

                            if (firstChild) {
                                firstChild.setAttribute('fill', color)
                            }

                            i++
                        }
                    }

                    this.$root.$emit('notification', {
                        type: 'success',
                        text: 'Gracias por darnos tu opinion de ' + this.info.name,
                    })
                } /*  else if (this.$store.state.auth.isAuthenticated) {
                    this.$root.$emit('notification', {
                        type: 'error',
                        text: 'Algo salio mal',
                    })
                } */
                ;(this.$refs.starsRatingContainer as HTMLDivElement).style.cursor = 'auto'

                this.userRatingNow = false
            }
        },
    },
})
