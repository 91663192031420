
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { format, parseISO } from 'date-fns'
import { pt as locale } from 'date-fns/locale'
import { Api } from '~/global'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'UserCoins',
    async asyncData({ $api, store }) {
        const user = store.getters['user/getProfile'] as Api.Responses.User.SuccessProfile['data']['user']

        const { data, feedback } = await $api.general.loadCoinHistory(user.id, {
            per_page: 10,
        })

        if (feedback === 'data_success') {
            store.commit('pages/setDashboardTab', {
                name: 'coins',
                data,
            })
        }
    },
    data() {
        const tabInfo = this.$store.state.pages.dashboard.tabs
            .coins as Api.Responses.General.IndividualModel<Models.Coin>['data']

        return {
            tabInfo,
            activeFaq: null as null | number,
            coins: tabInfo.results,
            fetchButton: {
                loading: false,
                show: tabInfo.meta.total > 0 && tabInfo.meta.current_page < tabInfo.meta.last_page,
            },
            params: {
                per_page: 10,
                page: tabInfo.meta.current_page + 1,
            },
            howEarn: [
                {
                    action: this.$util.lang.pages.dashboardMonedas.register_login,
                    pointsForVerified: {
                        count: 10,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_1_time,
                    },
                    pointsForUnverified: {
                        count: 10,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_1_time,
                    },
                },
                {
                    action: this.$util.lang.pages.dashboardMonedas.comments,
                    pointsForVerified: {
                        count: 10,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_5_time_per_day,
                    },
                    pointsForUnverified: {
                        count: 10,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_5_time_per_day,
                    },
                },
                {
                    action: this.$util.lang.pages.dashboardMonedas.report_expired_offer,
                    pointsForVerified: {
                        count: 10,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_5_time_per_day_approved,
                    },
                    pointsForUnverified: {
                        count: 10,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_5_time_per_day_approved,
                    },
                },
                {
                    action: this.$util.lang.pages.dashboardMonedas.offer_published,
                    pointsForVerified: {
                        count: 40,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_1_time_per_day_if_approved,
                    },
                    pointsForUnverified: {
                        count: 40,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_1_time_per_day_if_approved,
                    },
                },
                {
                    action: this.$util.lang.pages.dashboardMonedas.coupon_published,
                    pointsForVerified: {
                        count: 50,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_1_time_per_day_if_approved,
                    },
                    pointsForUnverified: {
                        count: 50,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_1_time_per_day_if_approved,
                    },
                },
                {
                    action: this.$util.lang.pages.dashboardMonedas.discount_reach_250,
                    pointsForVerified: {
                        count: 10,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_no_limits,
                    },
                    pointsForUnverified: {
                        count: 10,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_no_limits,
                    },
                },
                {
                    action: this.$util.lang.pages.dashboardMonedas.discount_reach_500,
                    pointsForVerified: {
                        count: 50,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_no_limits,
                    },
                    pointsForUnverified: {
                        count: 50,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_no_limits,
                    },
                },
                {
                    action: this.$util.lang.pages.dashboardMonedas.check_in_daily,
                    pointsForVerified: {
                        count: 10,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_5_time_per_day,
                    },
                    pointsForUnverified: {
                        count: 10,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_5_time_per_day,
                    },
                },
                {
                    action: this.$util.lang.pages.dashboardMonedas.antiquity_1_month,
                    pointsForVerified: {
                        count: 10,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_1_time,
                    },
                    pointsForUnverified: {
                        count: 10,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_1_time,
                    },
                },
                {
                    action: this.$util.lang.pages.dashboardMonedas.antiquity_6_month,
                    pointsForVerified: {
                        count: 50,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_1_time,
                    },
                    pointsForUnverified: {
                        count: 50,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_1_time,
                    },
                },
                {
                    action: this.$util.lang.pages.dashboardMonedas.aniversary,
                    pointsForVerified: {
                        count: 250,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_1_time_anually,
                    },
                    pointsForUnverified: {
                        count: 250,
                        frequency: this.$util.lang.pages.dashboardMonedas.action_1_time_anually,
                    },
                },
            ],
            faqs: [
                {
                    title: this.$util.lang.pages.dashboardMonedas.coins_are_free,
                    description: this.$util.lang.pages.dashboardMonedas.coins_are_free_answer,
                },
                {
                    title: this.$util.lang.pages.dashboardMonedas.can_buy_coins,
                    description: this.$util.lang.pages.dashboardMonedas.can_buy_coins_answer,
                },
                {
                    title: this.$util.lang.pages.dashboardMonedas.how_can_use_coins,
                    description: this.$util.lang.pages.dashboardMonedas.how_can_use_coins_answer,
                },
            ],
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.dashboardMonedas.title_seo
        const description = this.$util.lang.pages.dashboardMonedas.description_seo
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
    methods: {
        async fetch() {
            this.fetchButton.loading = true

            const { feedback, data } = await this.$api.general.loadCoinHistory(
                this.$store.state.user.profile.id,
                this.params
            )

            if (feedback === 'data_success') {
                if (data.meta.current_page === 1) {
                    this.coins = [...data.results]
                } else {
                    this.coins = [...data.results, ...this.coins]
                }
                if (data.meta.current_page < data.meta.last_page) {
                    this.params.page = data.meta.current_page + 1
                } else {
                    this.fetchButton.show = false
                }
            }

            this.fetchButton.loading = false
        },
        formatDate(date: string): string {
            return format(parseISO(date), 'dd MMM yyyy HH:mm', { locale })
        },
    },
})
