import { render, staticRenderFns } from "./CommentsOrder.vue?vue&type=template&id=72bec2ba&scoped=true&"
import script from "./CommentsOrder.vue?vue&type=script&lang=ts&"
export * from "./CommentsOrder.vue?vue&type=script&lang=ts&"
import style0 from "./CommentsOrder.vue?vue&type=style&index=0&id=72bec2ba&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72bec2ba",
  null
  
)

export default component.exports