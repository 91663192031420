var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{staticClass:"notification",class:!_vm.readed ? 'ring-2 ring-green-500 bg-white' : 'bg-white',style:(!_vm.readed ? 'box-shadow: 0 0 5px rgb(74 222 128 / 1), 0 8px 10px -6px rgb(0 0 0 / 0.1);' : '')},[_c('div',{staticClass:"notification__author"},[_c('NLink',{staticClass:"notification__author-image",attrs:{"to":_vm.notification.icon_user && _vm.notification.action !== 'web_many_likes_to_my_comment'
                    ? {
                          name: 'dashboard-user',
                          params: {
                              user: _vm.user.id ? _vm.user.id : '',
                          },
                      }
                    : '',"rel":"follow"}},[(_vm.notification.icon_user && _vm.notification.action !== 'web_many_likes_to_my_comment')?[(_vm.user.image)?_c('img',{staticClass:"notification__author-image-main",attrs:{"src":_vm.user.image,"alt":"icon"}}):_c('MicroUserNoImage',{attrs:{"name":_vm.user.name ? _vm.user.name : '?'}})]:_c('img',{staticClass:"notification__author-image-main",attrs:{"src":_vm.icon.main,"alt":"icon"}}),_vm._v("\n            "+_vm._s(_vm.notification.user)+"\n            "),(_vm.icon.topicon)?_c('picture',{staticClass:"notification__author-image-top-icon"},[_c('img',{attrs:{"src":_vm.icon.topicon,"alt":"top icon"}})]):_vm._e(),_vm._v(" "),(_vm.icon.subicon)?_c('picture',{staticClass:"notification__author-image-sub-icon",class:_vm.icon.subicon.style},[_c('img',{attrs:{"src":_vm.icon.subicon.data,"alt":"sub icon"}})]):_vm._e()],2)],1),_vm._v(" "),_c('div',{staticClass:"about"},[_c('div',{staticClass:"top"},[_c('p',{staticClass:"time"},[_vm._v(_vm._s(_vm.getDateDifference(_vm.notification.created_at)))]),_vm._v(" "),_c('div',{staticClass:"top__options"},[_c('button',{staticClass:"top__options-button",attrs:{"type":"button"},on:{"click":() => _vm.handleOptionsMenuToggle(true)}},[_c('img',{attrs:{"src":_vm.$assets.gray.dropdownMenuDots,"alt":"dropdown-icon"}})]),_vm._v(" "),_c('transition',{attrs:{"name":"drop-from-right"}},[(_vm.showOptionsMenu)?_c('DropdownOptions',{staticClass:"top__dropdown",attrs:{"options":_vm.optionsMenu},on:{"close":() => _vm.handleOptionsMenuToggle(false)}}):_vm._e()],1)],1)]),_vm._v(" "),_c('div',{staticClass:"action-and-button"},[_c('div',{staticClass:"action-and-button__action",domProps:{"innerHTML":_vm._s(_vm.notificationText)}}),_vm._v(" "),(!_vm.isReportOrExpired && _vm.variant && _vm.variant.name)?_c('NLink',{staticClass:"action-and-button__button",attrs:{"to":_vm.variant.path,"type":"button","rel":"follow"}},[_vm._v("\n                "+_vm._s(_vm.variant.name)+"\n            ")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }