
import Vue from 'vue'
import { parseISO } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { defaultImage } from '~/libs/useful-functions'
import { currentDate } from '~/libs/date-functions'

export default Vue.extend({
    name: 'CardEvent',
    props: {
        event: { type: Object, default: () => {} },
        expire: { type: Boolean, default: false },
    },
    data() {
        return {
            defaultImage,
            dates: currentDate(),
        }
    },
    methods: {
        formatDate(date: any): string {
            const newDate = date && parseISO(date)
            return date ? formatInTimeZone(newDate, 'America/Sao_Paulo', 'dd/MM/yyyy HH:mm') : ''
        },
        currentDate,
    },
})
