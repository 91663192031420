
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'CardFeatured',
    props: {
        info: Object as PropType<Models.Discount>,
    },
    computed: {
        isOffer(): boolean {
            return this.info.type === 'offer'
        },
        isCoupon(): boolean {
            return this.info.type === 'coupon'
        },
        showNewPrice(): boolean {
            return (
                (this.isOffer && !!this.info.new_price) ||
                (this.isCoupon &&
                    (((this.info.discount_type === 'precio' || this.info.discount_type === 'porcentaje') &&
                        this.info.discount_value !== '0') ||
                        this.info.discount_type === 'envío gratis'))
            )
        },
        newOfferPrice(): string {
            return this.info.new_price === '0'
                ? 'GRÁTIS'
                : this.$util.lang.symbol + this.info.new_price + ' OFF'
        },
        newCouponPrice(): string {
            return this.info.discount_type === 'envío gratis'
                ? this.$util.lang.components.cardFeaturedShop.free_ship
                : this.info.discount_type === 'precio'
                ? this.$util.lang.symbol + this.info.discount_value + ' OFF'
                : this.info.discount_value + '% OFF'
        },
        newPrice(): string {
            return (this.isOffer && this.newOfferPrice) || (this.isCoupon && this.newCouponPrice) || ''
        },
    },
})
