import { render, staticRenderFns } from "./chat.vue?vue&type=template&id=fb262b70&"
import script from "./chat.vue?vue&type=script&lang=ts&"
export * from "./chat.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardShareStepTwo: require('/home/runner/work/okdescontos-front/okdescontos-front/components/card/share/StepTwo.vue').default})
