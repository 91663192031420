
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'CardFeaturedGrid',
    props: {
        data: {
            type: Array as PropType<Models.Discount[]>,
            required: true,
        },
    },
})
