
import Vue from 'vue'

export default Vue.extend({
    name: 'FormQuillBig',
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        alternativeStyle: {
            type: String,
            default: '',
        },
        padding: {
            type: String,
            default: '',
        },
        description: {
            type: String,
        },
        invalid: {
            type: Boolean,
        },
    },
    data() {
        return {
            giphy: {
                show: false,
                position: '',
            },
            quill: null,
            gif_svg: `<svg width="19" height="8" viewBox="0 0 19 8" >
                        <path class="ql-fill" d="M5.472,146.286h-4.1A1.371,1.371,0,0,0,0,147.654v5.472a1.371,1.371,0,0,0,1.368,1.368h4.1a1.371,1.371,0,0,0,1.368-1.368V150.39H4.788v2H2v-4.1H6.84v-.684A1.371,1.371,0,0,0,5.472,146.286Z" transform="translate(0 -146.286)" />
                        <path class="ql-fill" d="M353.585,148.338v-2h-6.156v8h2v-2.736h2.736v-2h-2.736v-1.368Z" transform="translate(-334.432 -146.286)" />
                        <rect class="ql-fill" width="2" height="8" transform="translate(8.893)" />
                    </svg>`,
        }
    },
    head() {
        return {
            link: [
                {
                    rel: 'stylesheet',
                    href: '/css/quill.css',
                },
                {
                    rel: 'stylesheet',
                    href: '/css/quill-emoji.css',
                },
            ],
            script: [
                {
                    src: '/js/quill.js',
                    type: 'text/javascript',
                    async: true,
                    defer: true,
                    hid: 'quill-editor',
                    callback: () => this.quillInitializer(),
                },
            ],
        }
    },
    methods: {
        quillInitializer() {
            if ('Quill' in window) {
                const Embed = Quill.import('blots/block/embed')

                const Link = Quill.import('formats/link')

                class linkType extends Link {
                    static create(value) {
                        const node = super.create(value)

                        value = this.sanitize(value)

                        value.includes(window.location.origin)

                        if (value.startsWith('https://') || value.startsWith('http://')) {
                            if (value.includes(window.location.origin)) {
                                node.setAttribute('rel', 'follow')
                            } else {
                                node.setAttribute('rel', 'nofollow noopener noreferrer')
                                node.setAttribute('target', '_blank')
                            }
                        }
                        return node
                    }
                }

                class GiphyBlot extends Embed {
                    static blotName = 'giphy'
                    static tagName = 'img'

                    static create(value) {
                        const node = super.create()

                        node.setAttribute('alt', value.alt)
                        node.setAttribute('src', value.url)
                        node.setAttribute('width', '180px')

                        return node
                    }

                    static value(node) {
                        return {
                            alt: node.getAttribute('alt'),
                            url: node.getAttribute('src'),
                        }
                    }
                }

                Quill.register(linkType, true)
                Quill.register(GiphyBlot, true)

                this.quill = new Quill(this.$refs.editor, {
                    theme: 'snow',
                    placeholder: this.placeholder,
                    modules: {
                        toolbar: this.$refs.toolbar,
                        'emoji-toolbar': true,
                    },
                })

                this.quill.getModule('toolbar').addHandler('giphy', () => this.toggle(true))

                this.quill.on('text-change', () =>
                    this.$emit('update:description', this.quill.root.innerHTML)
                )
            }
        },
        giphyHandler(url) {
            const range = this.quill.getSelection(true)

            const alt = 'Gif: powered by: giphy.com'

            this.quill?.insertEmbed(range.index, 'giphy', { alt, url }, Quill.sources.USER)

            this.quill?.setSelection(range.index + 2, Quill.sources.SILENT)

            this.toggle(false)
        },
        toggle(show) {
            if (show) {
                this.giphy.position =
                    document.documentElement.clientHeight -
                        this.$refs.container.getBoundingClientRect().bottom >
                    250
                        ? '-bottom-52'
                        : 'bottom-8'
            }

            this.giphy.show = show
        },
        giphyElements() {
            return [this.$refs.giphy]
        },
    },
})
