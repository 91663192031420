
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { format, parseISO } from 'date-fns'
import { pt as locale } from 'date-fns/locale'
import { Api, ServicesQueryParameters } from '~/global'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'UserGifts',
    async asyncData({ $api, store }) {
        const user = store.getters['user/getProfile'] as Api.Responses.User.SuccessProfile['data']['user']

        const history = await $api.general.loadGiftsHistory(user.id)

        const gifts = await $api.general.loadGifts({
            per_page: 10,
        })

        if (history.feedback === 'data_success') {
            store.commit('pages/setDashboardTab', {
                name: 'ownGifts',
                data: history.data,
            })
        }

        if (gifts.feedback === 'data_success') {
            store.commit('pages/setDashboardTab', {
                name: 'gifts',
                data: gifts.data,
            })
        }
    },
    data() {
        const historyTabInfo = this.$store.state.pages.dashboard.tabs
            .ownGifts as Api.Responses.General.RewardsHistory['data']

        const giftsTabInfo = this.$store.state.pages.dashboard.tabs
            .gifts as Api.Responses.General.IndividualModel<Models.Gift>['data']

        return {
            history: historyTabInfo.gifts,
            gifts: giftsTabInfo.results,
            activeTab: false,
            fetchButton: {
                loading: false,
                show:
                    giftsTabInfo.meta.total > 0 &&
                    giftsTabInfo.meta.current_page < giftsTabInfo.meta.last_page,
            },
            params: {
                per_page: 10,
                page: giftsTabInfo.meta.current_page,
            } as ServicesQueryParameters.GetGifts,
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.dashboardPremios.title_seo
        const description = this.$util.lang.pages.dashboardPremios.description_seo
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
    methods: {
        formatDate(date: string): string {
            return format(parseISO(date), 'dd/MM/yyyy', { locale })
        },
        async fetch() {
            this.fetchButton.loading = true

            const { feedback, data } = await this.$api.general.loadGifts(this.params)

            if (feedback === 'data_success') {
                if (data.meta.current_page === 1) {
                    this.gifts = [...data.results]
                } else {
                    this.gifts = [...data.results, ...this.gifts]
                }
                if (data.meta.current_page < data.meta.last_page) {
                    this.params.page = data.meta.current_page + 1
                } else {
                    this.fetchButton.show = false
                }
            }

            this.fetchButton.loading = false
        },
        async redeem(id: number) {
            const result = await this.$api.general.redeemGifts({
                gift_id: id,
            })
            if (result.feedback === 'resource_created') {
                this.$root.$emit('notification', {
                    text: this.$util.lang.pages.dashboardPremios.redeem_success_awaiting,
                    duration: 4000,
                    dismissButtonText: this.$util.lang.pages.dashboardPremios.close,
                    type: 'success',
                })
            }
            if (result.feedback === 'params_validation_failed') {
                this.$root.$emit('notification', {
                    text: result.data.gift_id?.length
                        ? result.data.gift_id[0]
                        : result.data.coins_available?.length
                        ? result.data.coins_available[0]
                        : this.$util.lang.pages.dashboardPremios.error_on_redeem,
                    duration: 4000,
                    dismissButtonText: this.$util.lang.pages.dashboardPremios.close,
                    type: 'error',
                })
            }
        },
    },
})
