
import Vue, { PropOptions } from 'vue'

type TDataOptions = {
    id: number
    name: string
    title: string
    selected: string
    icon: string
    style: string
}

interface IPropsOptions {
    chatData: TDataOptions[]
    data: TDataOptions[]
    selected: any
}

export default Vue.extend({
    name: 'DropdownShareSelect',
    props: {
        options: {
            type: Object,
            default: () => {},
        } as PropOptions<IPropsOptions>,
        classes: {
            type: String,
            default: '',
        },
        invalid: {
            type: [Boolean, String],
        },
        kindData: {
            type: String,
        },
        handleClose: {
            type: Function,
        },
    },
    methods: {
        emitChangeSelected(newSelected: any, newImage: any = null) {
            const newoption = this.options
            if (newImage) {
                newoption.selected = { title: newSelected, icon: newImage }
            } else {
                newoption.selected = { name: newSelected.name, id: newSelected.id }
            }
            this.$emit('update:options', newoption)
            this.handleClose()
        },
    },
})
