
import Vue from 'vue'

export default Vue.extend({
    name: 'SidebarStoresTwoSkeleton',
    props: {
        maxItems: {
            type: Number,
            default: 7,
        },
    },
    data() {
        const skeletons: number[] = []

        for (let i = 0; i < this.maxItems; i++) skeletons.push(i)

        return {
            skeletons,
        }
    },
})
