
import Vue, { PropOptions } from 'vue'

export default Vue.extend({
    name: 'ModalConfirm',
    props: {
        info: {
            type: Object,
        } as PropOptions<{
            text: string
            continue: {
                handler: () => any
                text: string
            }
            cancel: {
                handler: () => any
                text: string
            }
        }>,
    },
    methods: {
        cancelHandler() {
            this.$emit('close')
            this.$nextTick(() => {
                this.info.cancel.handler()
            })
        },
        continueHandler() {
            this.$emit('close')
            this.$nextTick(() => {
                this.info.continue.handler()
            })
        },
    },
})
