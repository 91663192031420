
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { format, parseISO } from 'date-fns'
import { pt as locale } from 'date-fns/locale'
import { remove as removeCookie } from 'js-cookie'
import { cookies } from '~/config/constants'
import { Api } from '~/global'

export default Vue.extend({
    name: 'DashboardPrivate',

    middleware: ['ifAuthenticated'],
    async asyncData({ $api, store, error, $util }) {
        const user = store.getters['user/getProfile'] as Api.Responses.User.SuccessProfile['data']['user']
        try {
            const userData = await $api.pages.loadUserProfile(user.id)

            if (userData.feedback === 'data_success') {
                store.commit('pages/setDashboard', userData.data)
            }

            if (userData.feedback === 'resource_not_found') {
                error({
                    statusCode: 404,
                    message: $util.lang.pages.dashboardParent.profile_not_found,
                })
            }
        } catch (e) {}
    },
    data() {
        const userData = this.$store.state.pages.dashboard.index as Api.Responses.Pages.UserProfile['data']

        const coins = [
            {
                title: this.$util.lang.pages.dashboardParent.coins_available,
                coins: userData.statics.third_section.coins_available,
                icon: this.$assets.yellow.coin,
            },
            {
                title: this.$util.lang.pages.dashboardParent.coins_total,
                coins: userData.statics.third_section.collected_coins,
                icon: this.$assets.yellow.coinStack,
            },
            {
                title: this.$util.lang.pages.dashboardParent.awards,
                coins: userData.statics.third_section.awards_count,
                icon: this.$assets.yellow.coinPrize,
            },
        ]
        const statistics = [
            {
                items: [
                    {
                        icon: this.$assets.primary.dashOffersAndCoupons,
                        count:
                            userData.statics.first_section.published_offers_count +
                            userData.statics.first_section.published_coupons_count,
                        text: this.$util.lang.pages.dashboardParent.discounts,
                    },
                    {
                        icon: this.$assets.primary.dashComments,
                        count: userData.statics.first_section.published_comments_count,
                        text: this.$util.lang.pages.dashboardParent.comments,
                    },
                    {
                        icon: this.$assets.primary.dashChat,
                        count: userData.statics.first_section.published_forums_count,
                        text: this.$util.lang.pages.dashboardParent.chats,
                    },
                    {
                        icon: this.$assets.primary.dashLike,
                        count: userData.statics.second_section.likes_in_comments_published_count,
                        text: this.$util.lang.pages.dashboardParent.likes_sended,
                    },
                ],
            },
        ]
        const accountState = {
            follow: false,
            notifications: false,
        }
        return {
            coins,
            statistics,
            accountState,
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.dashboardParent.my_title_seo
        const description = this.$util.lang.pages.dashboardParent.my_description_seo
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
    computed: {
        userData() {
            return this.$store.state.pages.dashboard.index as Api.Responses.Pages.UserProfile['data']
        },
        memberSince(): string {
            return format(parseISO(this.userData.user.created_at || ''), "d 'de' MMMM 'del' yyyy", { locale })
        },
        discountsTotal(): number {
            const total =
                this.userData.statics.first_section.published_offers_count +
                this.userData.statics.first_section.published_coupons_count
            return total >= 100 ? 100 : total
        },
        commentsTotal(): number {
            const total = this.userData.statics.first_section.published_comments_count
            return total >= 100 ? 100 : total
        },
        discountsPercent(): number {
            return ((this.discountsTotal >= 25 ? 25 : this.discountsTotal) / 25) * 100
        },
        commentsPercent(): number {
            return ((this.commentsTotal >= 50 ? 50 : this.commentsTotal) / 50) * 100
        },
        userPercent(): number {
            return this.discountsPercent * 0.5 + this.commentsPercent * 0.5
        },
    },
    methods: {
        async signOut() {
            this.$nuxt.$loading.start()

            const result = await this.$api.auth.signOut()

            removeCookie(cookies.access_token_key)
            removeCookie(cookies.token_expires_at_key)

            if (this.$route.name === 'index') {
                window.location.reload()
            } else {
                const notification =
                    result?.feedback === 'closed_session_success'
                        ? result.data.message
                        : this.$util.lang.components.layoutHeaderUser.session_closed

                this.$root.$emit('notification', {
                    duration: 4000,
                    text: notification,
                    type: result?.feedback === 'closed_session_success' ? 'success' : 'warn',
                })

                this.$store.commit('auth/setAuthenticationStatus', false)
                this.$store.commit('auth/setAuthData', null)

                this.$store.commit('auth/setNextNavigationHandler', () => {
                    this.$store.commit('user/setProfile', null)

                    this.$store.commit('auth/setNextNavigationHandler', null)
                })
                this.$router.push({ name: 'index', query: {} })
            }
        },
    },
})
