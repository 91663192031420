
import Vue from 'vue'

export default Vue.extend({
    name: 'DropdownDetailsReport',
    props: {
        classes: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        options: {
            type: Object as () => {
                title: string
                data: {
                    title: string
                    icon: string
                    handler: () => void
                }[]
            },
        },
    },
    data() {
        return {}
    },
    methods: {},
})
