
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { parseISO } from 'date-fns'
import { Api } from '~/global'
import { currentDate } from '~/libs/date-functions'
import { Helpers } from '~/types/helpers'
import { Models } from '~/types/models'
import { defaultImage } from '~/libs/useful-functions'

export default Vue.extend({
    name: 'CategoryDetails',
    data() {
        const info = this.$store.state.pages.category as Api.Responses.Pages.CategoryDetails['data']

        let tabData: any[] = [] /* 
        let tabExpiredData: Models.Discount[] = [] */
        let tabMeta: Helpers.Meta | null = null

        /* const newsExt = `-${this.$util.lang.views.categoryDetails.news.toLowerCase()}`
        const commentedExt = `-${this.$util.lang.views.categoryDetails.commented.toLowerCase()}`

        const isNew = this.$route.params.slug.endsWith(newsExt)
        const isCommented = this.$route.params.slug.endsWith(commentedExt)

        const newSlug =
            isNew || isCommented
                ? this.$route.params.slug.replace(newsExt, '').replace(commentedExt, '')
                : this.$route.params.slug
 */
        tabMeta = {
            ...info.discounts.meta,
        }

        tabData = /* 
            !isNew && (!tabMeta.last_page || tabMeta.current_page === tabMeta.last_page)
                ? [...info.discounts.data, ...info.expired_discounts.data]
                :  */ [...info.discounts.data]

        /* tabExpiredData = 
            !isNew && (!tabMeta.last_page || tabMeta.current_page === tabMeta.last_page)
                ? []
                : [
            ...(info.expired_discounts && info.expired_discounts.data instanceof Array
                ? info.expired_discounts.data
                : []),
        ] */

        const currentDates = currentDate()

        const [title, description, h1, h2] = this.$util.replacePlaceholders(
            [
                [':category', info.category.name || ''],
                [':count', String(info.category.all_count) || ''],
                [':month', currentDates.month || ''],
                [':year', String(currentDates.year) || ''],
                [':day', String(currentDates.day) || ''],
                [':site', process.env.SITE_NAME || ''],
            ],
            [
                info.category.seo?.title || '',
                info.category.seo?.description || '',
                info.category.seo?.h1 || '',
                info.category.seo?.h2 || '',
            ]
        )

        const mainSectionTitle = `${
            /* 
            this.$route.path.includes('-nuevos')
                ? 'Nuevas ofertas en'
                : this.$route.path.includes('-comentados')
                ? 'Ofertas mas comentadas de'
                :  */ 'Ofertas populares de'
        } ${info.category.name}`

        if (info.cta_cards.length) {
            info.cta_cards.forEach((cta) => {
                tabData.splice(cta.position - 1, 0, { ...cta, isCtaCard: true })
            })
        }

        return {
            mainSectionTitle,
            defaultImage,
            /*  isNew,
            isCommented,
            newSlug, */
            info,
            secondaryInfo: {
                similarCategories: null as null | Models.Category[],
                popularStores: null as null | Models.Store[],
            },
            metaInfo: {
                title,
                description,
                h1,
                h2,
            },
            tabData,
            tabMeta,
            /* tabExpiredData, */
            loading: false,
            /*   tabsInfo: {
                show: isCommented ? 2 : isNew ? 1 : 0,
                tabs: [
                    {
                        to: `/${this.$util.lang.routes.categories}/${newSlug}`,
                        title: this.$util.lang.views.categoryDetails.populars,
                    },
                    {
                        to: `/${
                            this.$util.lang.routes.categories
                        }/${newSlug}-${this.$util.lang.views.categoryDetails.news.toLowerCase()}`,
                        title: this.$util.lang.views.categoryDetails.news,
                    },
                    {
                        to: `/${
                            this.$util.lang.routes.categories
                        }/${newSlug}-${this.$util.lang.views.categoryDetails.commented.toLowerCase()}`,
                        title: this.$util.lang.views.categoryDetails.commented,
                    },
                ],
            }, */
            fetchButton: {
                loading: false,
                show:
                    tabMeta?.total &&
                    tabMeta?.current_page &&
                    tabMeta?.last_page &&
                    tabMeta?.total > 0 &&
                    tabMeta?.current_page < tabMeta?.last_page,
            },
            params: {
                page: 2,
            },
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['og:image', this.info.category.image?.url || ''],
                    ['twitter:image', this.info.category.image?.url || ''],
                    ['og:image:secure_url', this.info.category.image?.url || ''],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                    [
                        'robots',
                        /* !this.isNew && !this.isCommented ?  */ 'index, follow' /*  : 'noindex, follow' */,
                    ],
                ],
                this.metaInfo?.title
            ),
        }
    },
    computed: {
        breadcrumbs(): { path?: string; title: string }[] {
            const hierarchy = [] as { path: string; title: string }[]
            if (this.info.category.parent) {
                hierarchy.push({
                    path: `/${this.$util.lang.routes.categories}/` + this.info.category.parent.slug,
                    title: this.info.category.parent.name,
                })
                if (this.info.category.parent?.parent) {
                    hierarchy.push({
                        path:
                            `/${this.$util.lang.routes.categories}/` + this.info.category.parent.parent.slug,
                        title: this.info.category.parent.parent.name,
                    })
                }
            }
            return [
                {
                    path: '/',
                    title: 'Home',
                },
                ...hierarchy.reverse(),
                {
                    title: this.info?.category.name || '',
                },
            ]
        },
        anyDiscountExists(): boolean {
            return this.tabData.length > 0
        },
    },
    async mounted() {
        try {
            const secondaryInfo = await this.$api.pages.loadSecondaryDataOfCategory(this.$route.params.slug)

            if (secondaryInfo.feedback === 'data_success') {
                this.secondaryInfo.similarCategories = [...secondaryInfo.data.similar_categories]
                this.secondaryInfo.popularStores = [...secondaryInfo.data.popular_stores]
            }
        } catch (e) {}
    },
    methods: {
        isExpired(date: string | null): boolean {
            if (date) {
                return parseISO(date) < new Date()
            }
            return false
        },
        async loadMoreCategory() {
            this.fetchButton.loading = true
            const order_by = /* this.isNew ?  */ 'latest' /*  : this.isCommented ? 'most-comment' : null */

            const result = (await this.$api.pages.loadCategory(this.$route.params.slug, {
                'order-by': order_by,
                page: this.params.page,
            })) as Api.Responses.Pages.CategoryDetails

            if (result.feedback === 'data_success') {
                this.tabData = [...this.tabData, ...result.data.discounts.data]

                /* if (!this.isNew) {
                    this.tabExpiredData = [...this.tabExpiredData, ...result.data.expired_discounts.data]
                } */

                this.tabMeta = {
                    ...result.data.discounts.meta,
                }

                const { current_page, last_page } = result.data.discounts.meta

                if (!last_page || current_page === last_page) {
                    /* if (!this.isNew) {
                        this.tabData = [...this.tabData, ...this.tabExpiredData]
                    } */
                    this.fetchButton.show = false
                } else {
                    this.params.page = current_page + 1
                }
            }

            this.fetchButton.loading = false
        },
    },
})
