
import Vue from 'vue'

export default Vue.extend({
    layout: 'none',
    async validate(ctx) {
        if (ctx.route.params.slug) {
            const status = await ctx.$api.pages.gatStoreLPStatus(ctx.route.params.slug)
            if (status.data.store.lp) {
                return true
            } else {
                ctx.error({
                    statusCode: 404,
                })
                return false
            }
        }
        return true
    },
    data() {
        return {
            iframe: null as null | Window,
            wasOpened: false,
            wasClosed: false,
        }
    },
    computed: {
        step(): 1 | 2 | 3 {
            return this.wasClosed ? 3 : this.wasOpened ? 2 : 1
        },
    },
    methods: {
        install() {
            if (navigator.userAgent.indexOf('Firefox') > 0) {
                return false
            }

            const fullwidth = window.screen.availWidth * 0.6
            const fullheight = window.screen.availHeight

            this.iframe = window.open(
                'https://chrome.google.com/webstore/detail/okdescontos/gkochegofmljpmgegphbcljdigenjlhd?hl=es',
                'newwindow',
                'height=' +
                    fullheight +
                    ',width=' +
                    fullwidth +
                    ',top=0,left=0,toolbar=no,menubar=no,scrollbars=no, resizable=no,location=no, status=no'
            )
            this.wasOpened = true

            const loop = setInterval(() => {
                if (this.iframe != null && this.iframe.closed) {
                    clearInterval(loop)

                    this.wasOpened = false
                    this.wasClosed = true
                }
            }, 100)

            return false
        },
    },
})
