
import Vue, { PropOptions } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'ModalDashboardPassword',
    props: {
        info: Object as PropOptions<{
            callback: (success: boolean) => void
        }>,
    },
    data() {
        const form = {
            data: {
                password: '',
                new_password: '',
                new_password_confirmation: '',
            },
            validationStatus: {
                password: false as boolean | string,
                new_password: false as boolean | string,
                new_password_confirmation: false as boolean | string,
            },
        }

        return {
            form,
            loading: false,
        }
    },
    computed: {
        userData(): Models.User {
            return this.$store.state.user.profile
        },
    },
    methods: {
        cancelHandler() {
            this.$emit('close')
        },
        async continueHandler() {
            this.loading = true
            await this.onSave()
            this.loading = false
        },
        async updateProfile() {
            const profileBody = {} as { [key: string]: any }

            if (this.userData.has_password) {
                !!this.form.data.password && (profileBody.password = this.form.data.password)
                !!this.form.data.new_password && (profileBody.new_password = this.form.data.new_password)
                !!this.form.data.new_password_confirmation &&
                    (profileBody.new_password_confirmation = this.form.data.new_password_confirmation)
            }

            try {
                const profileResult = await this.$api.user.updateProfile(
                    String(this.userData.id),
                    profileBody
                )

                if (profileResult.feedback === 'data_updated_success') {
                    await this.updateProfileInfo()

                    this.$root.$emit('notification', {
                        type: 'success',
                        text: this.$util.lang.components.modalDashboardPassword.profile_updated,
                        duration: 4000,
                    })

                    this.$emit('close')
                } else if (
                    profileResult.feedback === 'data_error' ||
                    profileResult.feedback === 'params_validation_failed'
                ) {
                    this.form.validationStatus.password = profileResult.data.password[0] || false
                    this.form.validationStatus.new_password = profileResult.data.new_password[0] || false
                    this.form.validationStatus.new_password_confirmation =
                        profileResult.data.new_password_confirmation[0] || false
                }
            } catch (e) {}
        },
        async updateProfileInfo() {
            try {
                const userData = await this.$api.pages.loadUserProfile(this.userData.id)

                if (userData.feedback === 'data_success') {
                    this.$store.commit('pages/setDashboard', userData.data)
                }
            } catch (e) {}
        },
        async updateRRSSPassword() {
            const passwordBody = {} as { [key: string]: any }

            passwordBody.new_password = this.form.data.new_password

            passwordBody.new_password_confirmation = this.form.data.new_password_confirmation

            try {
                const passwordResult = await this.$api.user.updateRRSSPassword(passwordBody)

                if (passwordResult.feedback === 'data_updated_success') {
                    await this.updateProfileInfo()

                    this.$root.$emit('notification', {
                        type: 'success',
                        text: this.$util.lang.components.modalDashboardPassword.password_updated,
                        duration: 4000,
                    })
                }
            } catch (e) {}
        },
        async onSave() {
            if (
                !!this.userData.has_password &&
                !!this.form.data.password &&
                !!this.form.data.new_password &&
                !!this.form.data.new_password_confirmation
            ) {
                await this.updateProfile()
            }

            if (
                !this.userData.has_password &&
                !!this.form.data.new_password &&
                !!this.form.data.new_password_confirmation
            ) {
                await this.updateRRSSPassword()
            }
        },
    },
})
