
import Vue from 'vue'
import { MetaInfo } from 'vue-meta'
import { Api, ServicesQueryParameters } from '~/global'
import { currentDate } from '~/libs/date-functions'
import { Models } from '~/types/models'

type SearchMore = {
    title: string
    image: string
    path: string
    ribbon?: string
}

export default Vue.extend({
    name: 'Expirados',
    async asyncData({ store, $api }) {
        const result = await $api.general.loadDiscounts({
            status: 'expired',
            order_by: 'latest',
        })
        if (result.feedback === 'data_success') {
            store.commit('pages/setValue', {
                name: 'expired',
                page: result,
            })
        }
    },
    data() {
        const { data } = this.$store.state.pages
            .expired as Api.Responses.General.IndividualModel<Models.Discount>

        const searchMore: SearchMore[] = [
            {
                title: this.$util.lang.pages.expirados.coupons,
                image: this.$assets.illustration.megaCoupons,
                path: `/${this.$util.lang.routes.coupons}`,
            },
            {
                title: this.$util.lang.pages.expirados.offers,
                image: this.$assets.illustration.megaOfertas,
                path: `/${this.$util.lang.routes.offers}`,
            },
            {
                title: this.$util.lang.pages.expirados.categories,
                image: this.$assets.illustration.megaCategories,
                path: `/${this.$util.lang.routes.categories}`,
            },
            {
                title: this.$util.lang.pages.expirados.brands,
                image: this.$assets.illustration.megaBrands,
                path: `/${this.$util.lang.routes.brands}`,
            },
            {
                title: this.$util.lang.pages.expirados.register,
                image: this.$assets.illustration.megaCommunity,
                ribbon: this.$assets.misc.ribbonRegister,
                path: `/${this.$util.lang.routes.register}`,
            },
        ]
        return {
            discounts: data?.results || [],
            searchMore,
            fetchButton: {
                loading: false,
                show: data?.meta.total > 0 && data?.meta.current_page < data?.meta.last_page,
            },
            params: {
                per_page: 10,
                page: 2,
                order_by: 'latest',
            } as ServicesQueryParameters.GetDiscounts,
            tabsInfo: {
                show: 0,
                tabs: [
                    { title: this.$util.lang.pages.expirados.popular, amount: 8 },
                    { title: this.$util.lang.pages.expirados.news, amount: 2 },
                    { title: this.$util.lang.pages.expirados.commented, amount: 3 },
                ],
            },
        }
    },
    head(): MetaInfo {
        const currentDates = currentDate()

        const description = this.$util.replacePlaceholders(
            [
                [':month', currentDates.month],
                [':year', String(currentDates.year)],
            ],
            this.$util.lang.pages.expirados.all_coupons
        )

        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description as string],
                    ['title', this.$util.lang.pages.expirados.expired_recently],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.$util.lang.pages.expirados.expired_recently],
                    ['og:image:alt', this.$util.lang.pages.expirados.expired_recently],
                    ['og:description', description as string],
                ],
                this.$util.lang.pages.expirados.expired_recently
            ),
        }
    },
    computed: {
        categories(): any {
            return this.$store.state.layout?.sidebar?.categories || null
        },
        stores(): any {
            return this.$store.state.layout?.sidebar?.stores || null
        },
    },
    methods: {
        async fetch() {
            this.fetchButton.loading = true

            const { feedback, data } = await this.$api.general.loadDiscounts(this.params)

            if (feedback === 'data_success') {
                this.discounts = [...this.discounts, ...data.results]

                if (data.meta.current_page < data.meta.last_page) {
                    this.params.page = data.meta.current_page + 1
                } else {
                    this.fetchButton.show = false
                }
            }

            this.fetchButton.loading = false
        },
    },
})
