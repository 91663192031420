var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chat-index"},[_c('MicroBreadcrumbs',{attrs:{"layers":_vm.breadcrumbs,"text-color":"text-gray-900"}}),_vm._v(" "),_c('div',{staticClass:"chat-index__content"},[_c('main',{staticClass:"main"},[_c('h1',{staticClass:"main__title"},[_vm._v(_vm._s(_vm.$util.lang.pages.foros.forum))]),_vm._v(" "),_c('div',{staticClass:"flex justify-between w-full gap-2 lg:hidden"},[_c('NLink',{staticClass:"flex w-1/2 create-new-thread md:w-1/4",attrs:{"to":`/${_vm.$util.lang.routes.share}/${_vm.$util.lang.routes.chat}`}},[_c('img',{attrs:{"src":_vm.$assets.white.createThread,"alt":_vm.$util.lang.pages.foros.create_new_forum}}),_vm._v(" "),_c('span',{staticClass:"overflow-hidden whitespace-nowrap text-ellipsis"},[_vm._v(_vm._s(_vm.$util.lang.pages.foros.create_new_forum))])]),_vm._v(" "),_c('div',{staticClass:"relative flex items-center justify-center w-1/2 md:w-1/4 text-sm p-1.5 rounded-lg border border-opacity-5 bg-white mt-5;"},[_c('button',{staticClass:"w-full",attrs:{"type":"button"},on:{"click":function($event){return _vm.dropdownToggle(!_vm.dropdown.show.category, 'category')}}},[_c('h3',{staticClass:"flex items-center justify-between gap-1 p-1 text-sm"},[_vm._v("\n                            "+_vm._s(_vm.dropdown.category.selected.name)+"\n                            "),_c('img',{staticClass:"h-1.5",class:_vm.dropdown.show.category
                                        ? 'transition-all rotate-180'
                                        : 'transition-all',attrs:{"src":_vm.$assets.black.dropdown,"alt":"dropdown"}})])]),_vm._v(" "),_c('Transition',{attrs:{"name":"fade"}},[(_vm.dropdown.show.category)?_c('DropdownShareSelect',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
                                handler: () => _vm.dropdownToggle(false, 'category'),
                            }),expression:"{\n                                handler: () => dropdownToggle(false, 'category'),\n                            }"}],attrs:{"classes":"w-full top-10","handle-close":() => _vm.dropdownToggle(false, 'category'),"options":_vm.dropdown.category},on:{"update:options":[function($event){return _vm.$set(_vm.dropdown, "category", $event)},function($event){return _vm.onChangeCategory($event)}]}}):_vm._e()],1)],1)],1),_vm._v(" "),_c('ListThreads',{attrs:{"thread-list":_vm.filteredThreads},on:{"changeCategory":_vm.onChangeCategoryByName}}),_vm._v(" "),(_vm.showLoadMore)?_c('BtnSeeMoreResults',{attrs:{"handler":_vm.loadMoreForos,"loading":_vm.loading}}):_vm._e()],1),_vm._v(" "),_c('aside',{staticClass:"sidebar"},[_c('NLink',{staticClass:"hidden create-new-thread lg:flex",attrs:{"to":`/${_vm.$util.lang.routes.share}/${_vm.$util.lang.routes.chat}`}},[_c('img',{attrs:{"src":_vm.$assets.white.createThread,"alt":_vm.$util.lang.pages.foros.create_new_forum}}),_vm._v("\n                "+_vm._s(_vm.$util.lang.pages.foros.create_new_forum)+"\n            ")]),_vm._v(" "),_c('section',{staticClass:"categories-dropdown"},[_c('div',{staticClass:"content_head"},[_c('h3',{staticClass:"content_head__heading"},[_vm._v(_vm._s(_vm.$util.lang.pages.foros.categories))])]),_vm._v(" "),_c('button',{staticClass:"dropdown",attrs:{"type":"button"},on:{"click":function($event){return _vm.dropdownToggle(!_vm.dropdown.show.category, 'category')}}},[_c('div',{staticClass:"dropdown-title"},[_c('h3',[_vm._v(_vm._s(_vm.dropdown.category.selected.name))]),_vm._v(" "),_c('img',{staticClass:"dropdown-title__icon-drop",class:_vm.dropdown.show.category ? 'transition-all rotate-180' : 'transition-all',attrs:{"src":_vm.$assets.black.dropdown,"alt":"dropdown"}}),_vm._v(" "),_c('Transition',{attrs:{"name":"fade"}},[(_vm.dropdown.show.category)?_c('DropdownShareSelect',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
                                    handler: () => _vm.dropdownToggle(false, 'category'),
                                }),expression:"{\n                                    handler: () => dropdownToggle(false, 'category'),\n                                }"}],attrs:{"handle-close":() => _vm.dropdownToggle(false, 'category'),"classes":"w-max left-0 pt-1","options":_vm.dropdown.category},on:{"update:options":[function($event){return _vm.$set(_vm.dropdown, "category", $event)},function($event){return _vm.onChangeCategory($event)}]}}):_vm._e()],1)],1)])]),_vm._v(" "),_c('SidebarStoresLazy',{attrs:{"path":`/${_vm.$util.lang.routes.brands}`,"title":_vm.$util.lang.pages.foros.popular_shops,"info":_vm.stores,"max-items":15}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }