
import Vue from 'vue'

export default Vue.extend({
    name: 'LayoutHeaderLinksWarning',

    props: {
        title: String,
    },
})
