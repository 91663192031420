
import Vue from 'vue'

export default Vue.extend({
    name: 'CardDashboardCoin',
    props: {
        info: Object,
        isPrivate: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isCoin(): boolean {
            return this.info.title.includes(this.$util.lang.components.cardDashboardCoin.coins)
        },
    },
})
