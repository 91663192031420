
import Vue from 'vue'

export default Vue.extend({
    name: 'CardBlog',
    props: {
        post: {
            type: Object,
            required: true,
        },
        category: {
            type: String,
        },
    },
    computed: {
        content(): string {
            return this.post.content.replace(/<[^>]*>?/gm, ' ') || ''
        },
        fullPath(): string {
            return '/blog/' + this.category + '/' + this.post.slug
        },
    },
})
