
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { remove as removeCookie } from 'js-cookie'
import { cookies } from '~/config/constants'
import { Models } from '~/types/models'

type Preference = {
    [key: string]: boolean
}

export default Vue.extend({
    name: 'DashboardUserPreferences',
    async asyncData({ $api, store, error, $util }) {
        const email = await $api.user.emailPreferences()
        const web = await $api.user.webPreferences()

        const emailPreferences = {} as Preference
        const webPreferences = {} as Preference

        if (email.feedback === 'data_success' && web.feedback === 'data_success') {
            email.data.preferences.forEach((preference) => {
                emailPreferences[preference.action as keyof Preference] = Boolean(preference.type)
            })

            web.data.preferences.forEach((preference) => {
                webPreferences[preference.action as keyof Preference] = Boolean(preference.type)
            })

            store.commit('user/setUserPreferences', { email: emailPreferences, web: webPreferences })
        } else {
            error({
                statusCode: 404,
                message: $util.lang.pages.dashboardAjustes.preferences_get_error,
            })
        }
    },
    data() {
        const preferences = { ...this.$store.state.user.userPreferences } as {
            email: Preference
            web: Preference
        }

        const emailPreferences = { ...preferences.email }
        const webPreferences = { ...preferences.web }

        const form = {
            data: {
                emails: emailPreferences || null,
                web: webPreferences || null,
                notifications: emailPreferences || null,
                email: {
                    general: true,
                    entry_email: true,
                    coins: true,
                    tagged_comment: true,
                    answer_comment: true,
                    publication_commented: true,
                    comment_published: true,
                    comment_approved: true,
                    offer_popular_250: true,
                    offer_popular_500: true,
                    like_mycomment: true,
                    offer_recommended_other: true,
                    offer_expired: true,
                    offer_published: true,
                    offer_rejected: true,
                },
                avatar: null as null | { file: File },
            },
        }
        return {
            verificationButtonState: {
                disabled: false,
                loading: false,
            },
            emailLoading: false,
            webLoading: false,
            tabNotifications: false,
            form,
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.dashboardAjustes.title_seo
        const description = this.$util.lang.pages.dashboardAjustes.description_seo
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
    computed: {
        title(): { title: string; icon: string } {
            switch (this.$route.query.tab) {
                case 'conexion-social':
                    return {
                        title: this.$util.lang.pages.dashboardAjustes.social_connection,
                        icon: 'fas fa-chart-network',
                    }
                case 'notificaciones':
                    return {
                        title: this.$util.lang.pages.dashboardAjustes.notifications,
                        icon: 'fas fa-bell',
                    }
            }
            return { title: this.$util.lang.pages.dashboardAjustes.account, icon: 'fas fa-user-alt' }
        },
        userData(): Models.User {
            return this.$store.state.user.profile
        },
        socialConnected(): {
            google: {
                icon: string
                text: string
            }
            facebook: {
                icon: string
                text: string
            }
        } {
            return {
                google: {
                    icon: this.userData.google_account
                        ? this.$assets.green.connected
                        : this.$assets.red.disconnected,
                    text: this.userData.google_account
                        ? `${this.$util.lang.pages.dashboardAjustes.google_connected} ` + this.userData.email
                        : this.$util.lang.pages.dashboardAjustes.google_not_connected,
                },
                facebook: {
                    icon: this.userData.facebook_account
                        ? this.$assets.green.connected
                        : this.$assets.red.disconnected,
                    text: this.userData.facebook_account
                        ? `${this.$util.lang.pages.dashboardAjustes.facebook_connected} ` +
                          this.userData.email
                        : this.$util.lang.pages.dashboardAjustes.facebook_not_connected,
                },
            }
        },
    },
    watch: {
        'form.data.avatar.file': {
            async handler() {
                if (this.form.data.avatar?.file) {
                    this.$nuxt.$loading.start()
                    try {
                        const result = await this.$api.user.updateAvatar(this.form.data.avatar.file)

                        if (result.feedback === 'data_updated_success') {
                            this.updateProfileInfo()

                            this.$root.$emit('notification', {
                                type: 'success',
                                duration: 4000,
                                text: this.$util.lang.pages.dashboardAjustes.success_account_image,
                            })
                        } else {
                            this.$nuxt.$loading.finish()
                            this.$root.$emit('notification', {
                                type: 'error',
                                duration: 4000,
                                text: this.$util.lang.pages.dashboardAjustes.error_account_image,
                            })
                        }
                    } catch (e) {}
                    this.$nuxt.$loading.finish()
                }
            },
        },
    },
    mounted() {
        if (this.$route.hash === '#notificaciones') {
            this.$nextTick(() => this.changeCurrentQuery({ tab: 'notificaciones' }))
        }

        if (typeof this.$route.query.action === 'string') {
            this.form.data.emails[this.$route.query.action] = false

            this.$nextTick(async () => {
                try {
                    this.$nuxt.$loading.start()
                    await this.saveEmailConfig()
                    this.$nuxt.$loading.finish()
                } catch (e) {
                    console.log('Error al guardar preferencias: ', e)
                }
            })
        }
    },
    methods: {
        changeCurrentQuery(newQuery?: object): void {
            this.$router.replace({ query: { ...newQuery } })
        },
        socialSignIn(strategy: 'google' | 'facebook') {
            this.$nuxt.$loading.start()
            try {
                this.$api.auth.socialSingIn(strategy, {
                    success_url: location.origin + location.pathname,
                    error_url: location.origin + location.pathname,
                })
            } catch (e) {
                this.$nuxt.$loading.finish()
            }
        },
        async saveWebConfig(fromURI?: { preferences: {} }): Promise<void> {
            this.webLoading = true
            let payload
            if (fromURI?.preferences) {
                payload = fromURI
            } else {
                payload = {
                    preferences: this.form.data.web
                        ? Object.keys(this.form.data.web).map((key) => ({
                              action: key,
                              type: this.form.data.web[key] ? 1 : 0,
                          }))
                        : [],
                }
                payload.preferences = payload.preferences.filter(
                    (item) =>
                        item.action !== 'undefined' &&
                        item.action !== 'web_tagged_in_a_comment' &&
                        item.action !== 'web_post_receives_first_comment' &&
                        item.action !== 'web_post_receives_comments' &&
                        item.action !== 'web_approved_comment' &&
                        item.action !== 'web_banned' &&
                        item.action !== 'web_published_post' &&
                        item.action !== 'web_rejected_post'
                )
            }

            const res = await this.$api.user.updatePreferences({ type: 'web' }, { ...payload })

            if (res.feedback === 'data_updated_success') {
                if (fromURI) {
                    this.form.data.web[this.$route.query.action as keyof Preference] = false
                }
                localStorage.removeItem('action')
                this.$root.$emit('notification', {
                    type: 'success',
                    duration: 4000,
                    text: this.$util.lang.pages.dashboardAjustes.success_updating_preferences_web,
                })
            } else {
                this.$root.$emit('notification', {
                    type: 'error',
                    duration: 4000,
                    text: this.$util.lang.pages.dashboardAjustes.error_updating_preferences_web,
                })
            }

            this.webLoading = false
        },
        async saveEmailConfig(fromURI?: { preferences: {} }): Promise<void> {
            this.emailLoading = true
            let payload
            if (fromURI?.preferences) {
                payload = fromURI
            } else {
                payload = {
                    preferences: this.form.data.emails
                        ? Object.keys(this.form.data.emails).map((key) => ({
                              action: key,
                              type: this.form.data.emails[key] ? 1 : 0,
                          }))
                        : [],
                }
                payload.preferences = payload.preferences.filter((item) => item.action !== 'undefined')
            }

            const res = await this.$api.user.updatePreferences({ type: 'email' }, { ...payload })

            if (res.feedback === 'data_updated_success') {
                if (fromURI) {
                    this.form.data.emails[this.$route.query.action as keyof Preference] = false
                }
                localStorage.removeItem('action')
                this.$root.$emit('notification', {
                    type: 'success',
                    duration: 4000,
                    text: this.$util.lang.pages.dashboardAjustes.success_updating_preferences_email,
                })
            } else {
                this.$root.$emit('notification', {
                    type: 'error',
                    duration: 4000,
                    text: this.$util.lang.pages.dashboardAjustes.error_updating_preferences_email,
                })
            }

            this.emailLoading = false
        },
        async updateProfileInfo() {
            try {
                const result = await this.$api.user.profile()
                if (result.feedback === 'data_success') {
                    this.$store.commit('user/setProfile', result.data.user)
                }
            } catch (e) {}
        },
        async sendVerificationMail() {
            this.verificationButtonState.loading = true
            try {
                const result = await this.$api.user.requestAccountVerification()

                if (result.feedback === 'data_updated_success') {
                    this.verificationButtonState.disabled = true
                    this.$root.$emit('notification', {
                        text: this.$util.lang.pages.dashboardAjustes.email_verification_sended,
                        duration: 4000,
                        type: 'success',
                    })
                }
            } catch (e) {}
            this.verificationButtonState.loading = false
        },
        deleteAccount() {
            this.$store.commit('setModal', {
                name: 'ModalConfirm',
                info: {
                    text: this.$util.lang.pages.dashboardAjustes.are_sure_delete_account,
                    continue: {
                        text: this.$util.lang.pages.dashboardAjustes.delete_account,
                        handler: async () => {
                            this.$nuxt.$loading.start()

                            try {
                                const result = await this.$api.user.deleteAccount(String(this.userData.id))
                                if (result.feedback === 'closed_account') {
                                    removeCookie(cookies.access_token_key)
                                    removeCookie(cookies.token_expires_at_key)
                                    this.$store.commit('auth/setAuthenticationStatus', false)
                                    this.$store.commit('auth/setAuthData', null)

                                    this.$store.commit('auth/setNextNavigationHandler', () => {
                                        this.$store.commit('user/setProfile', null)

                                        this.$store.commit('auth/setNextNavigationHandler', null)

                                        this.$root.$emit('notification', {
                                            duration: 4000,
                                            text: this.$util.lang.pages.dashboardAjustes
                                                .success_deleting_account,
                                            type: 'success',
                                        })
                                    })
                                    this.$router.push({ name: 'index', query: {} })
                                } else {
                                    this.$root.$emit('notification', {
                                        duration: 4000,
                                        text: this.$util.lang.pages.dashboardAjustes.error_deleting_account,
                                        type: 'error',
                                    })
                                }
                            } catch (e) {
                                this.$root.$emit('notification', {
                                    duration: 4000,
                                    text: this.$util.lang.pages.dashboardAjustes.error_deleting_account,
                                    type: 'error',
                                })
                                this.$nuxt.$loading.start()
                            }
                        },
                    },
                    cancel: {
                        text: this.$util.lang.pages.dashboardAjustes.cancel,
                        handler: () => null,
                    },
                },
            })
        },
    },
})
