
import Vue from 'vue'

export default Vue.extend({
    name: 'SitemapIndex',
    computed: {
        breadcrumbs(): { path?: string; title: string }[] {
            const fragment =
                this.$route.name === 'sitemap-index'
                    ? [
                          {
                              path: '/sitemap',
                              title: 'Categorías',
                          },
                      ]
                    : this.$route.name === 'sitemap-index-tiendas'
                    ? [
                          {
                              path: '/sitemap/tiendas',
                              title: 'Tiendas',
                          },
                      ]
                    : this.$route.name === 'sitemap-index-promociones'
                    ? [
                          {
                              path: '/sitemap/promociones',
                              title: 'Promociones',
                          },
                      ]
                    : [
                          {
                              path: '/sitemap/promociones',
                              title: 'Promociones',
                          },
                          {
                              title:
                                  this.$route.params.slug.slice(0, 1).toUpperCase() +
                                  this.$route.params.slug.slice(1),
                          },
                      ]
            return [
                {
                    path: '/',
                    title: this.$util.lang.pages.forosSlug.home,
                },
                ...fragment,
            ]
        },
        head() {
            return {
                title: 'Mapa del sitio - MEGAdescuentos',
                description:
                    'Mapa del sitio de MEGAdescuentos. Consulta categorías, tiendas, cupones, ofertas y promociones de forma ordenada y accesible.',
            }
        },
        title(): string {
            return this.$route.name === 'sitemap-index'
                ? 'Categorías'
                : this.$route.name === 'sitemap-index-tiendas'
                ? 'Tiendas'
                : 'Promociones'
        },
    },
})
