import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0aab5c0e&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=0aab5c0e&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0aab5c0e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroBreadcrumbs: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/Breadcrumbs.vue').default,DropdownShareSelect: require('/home/runner/work/okdescontos-front/okdescontos-front/components/dropdown/Share/Select.vue').default,ListThreads: require('/home/runner/work/okdescontos-front/okdescontos-front/components/list/Threads.vue').default,BtnSeeMoreResults: require('/home/runner/work/okdescontos-front/okdescontos-front/components/btn/SeeMoreResults.vue').default,SidebarStoresLazy: require('/home/runner/work/okdescontos-front/okdescontos-front/components/sidebar/StoresLazy.vue').default})
