import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=347365be&scoped=true&"
import script from "./_slug.vue?vue&type=script&lang=ts&"
export * from "./_slug.vue?vue&type=script&lang=ts&"
import style0 from "./_slug.vue?vue&type=style&index=0&id=347365be&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "347365be",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroBreadcrumbs: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/Breadcrumbs.vue').default,MicroUserNoImage: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/userNoImage.vue').default,MicroBadge: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/Badge.vue').default,DropdownOptions: require('/home/runner/work/okdescontos-front/okdescontos-front/components/dropdown/Options.vue').default,FormMakeComment: require('/home/runner/work/okdescontos-front/okdescontos-front/components/form/MakeComment.vue').default,CardCommentThread: require('/home/runner/work/okdescontos-front/okdescontos-front/components/card/CommentThread.vue').default,BtnSeeMoreResults: require('/home/runner/work/okdescontos-front/okdescontos-front/components/btn/SeeMoreResults.vue').default,MicroNoResults: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/NoResults.vue').default,SidebarShare: require('/home/runner/work/okdescontos-front/okdescontos-front/components/sidebar/Share.vue').default,DropdownOrderby: require('/home/runner/work/okdescontos-front/okdescontos-front/components/dropdown/Orderby.vue').default,MicroFullUser: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/fullUser.vue').default})
