
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'

interface Banner {
    type: string
    background: string
    image: {
        main: string
        secondary?: string
        tertiary?: string
    }
    title: {
        icon: boolean
        text: string
    }
    description: string
    handler?: () => void
}

export default Vue.extend({
    name: 'BannerGeneric',
    props: {
        info: {
            type: Object as PropType<Models.Banner>,
            required: true,
        },
    },
    data() {
        return {}
    },
    computed: {
        banner(): Banner {
            switch (true) {
                case this.info.template === 'register_coins':
                    return {
                        type: 'signup',
                        background: `bg-[url('static/assets/svg/coin-banner-illustration.svg')]`,
                        image: {
                            main: this.$assets.illustration.coinMegaDescuentos,
                            secondary: this.$assets.illustration.coinPesosSymbol,
                            tertiary: this.$assets.illustration.coinPesosSymbol,
                        },
                        title: { icon: false, text: this.$util.lang.components.bannerGeneric.earn_prizes },
                        description: this.$util.lang.components.bannerGeneric.register_earn_coins,
                    }
                case this.info.template === 'register_mega':
                    return {
                        type: 'signup',
                        background: `bg-[url('static/assets/svg/mega-banner-illustration.svg')]`,
                        image: {
                            main: this.$assets.illustration.coinMegaDescuentos2,
                            secondary: this.$assets.illustration.coinPesosMDsymbol,
                            tertiary: this.$assets.illustration.coinPesosMDsymbol2,
                        },
                        title: { icon: false, text: this.$util.lang.components.bannerGeneric.earn_prizes },
                        description: this.$util.lang.components.bannerGeneric.register_earn_md,
                    }
                case this.info.template === 'twitter':
                    return {
                        type: 'twitter',
                        background: `bg-[url('static/assets/svg/twitter-banner-illustration.svg')]`,
                        image: {
                            main: this.$assets.social.twitterNotCircularWhite,
                        },
                        title: { icon: true, text: 'Twitter @megadescuentosm' },
                        description: this.$util.lang.components.bannerGeneric.follow_us,
                        handler: () => window.open('https://twitter.com/megadescuentosm'),
                    }
                // instagram ↓
                default:
                    return {
                        type: 'instagram',
                        background: `bg-[url('static/assets/svg/instagram-banner-illustration.svg')]`,
                        image: {
                            main: this.$assets.social.instagramWhite,
                        },
                        title: { icon: true, text: 'Instagram @megadescuentosmx' },
                        description: this.$util.lang.components.bannerGeneric.follow_us,
                        handler: () => window.open('https://www.instagram.com/megadescuentosmx/'),
                    }
            }
        },
    },
    methods: {
        socialSignIn(strategy: 'google' | 'facebook') {
            this.$nuxt.$loading.start()
            try {
                this.$api.auth.socialSingIn(strategy, {
                    success_url: location.origin + location.pathname,
                    error_url: location.origin + location.pathname,
                })
            } catch (e) {
                this.$nuxt.$loading.finish()
            }
        },
        onSubmit() {
            this.$emit('close')
        },
    },
})
