import { render, staticRenderFns } from "./News.vue?vue&type=template&id=971614e4&scoped=true&"
import script from "./News.vue?vue&type=script&lang=ts&"
export * from "./News.vue?vue&type=script&lang=ts&"
import style0 from "./News.vue?vue&type=style&index=0&id=971614e4&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "971614e4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroUserImage: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/userImage.vue').default,MicroBadge: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/Badge.vue').default})
