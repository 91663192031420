var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"filter-by-letter"},[_c('nav',{staticClass:"filter-by-letter__nav"},_vm._l((_vm.filterLetters),function(letter,index){return _c('NLink',{key:index,staticClass:"filter-by-letter__nav-item",class:_vm.active === letter.name
                    ? 'text-white bg-site-primary'
                    : 'text-gray-800 bg-white hover:text-site-primary',attrs:{"to":_vm.path + letter.path,"rel":"follow"},on:{"click":function($event){return _vm.changeSelected(letter.name)}}},[_c('span',[(letter.icon)?_c('img',{attrs:{"src":_vm.active === 'popular' ? letter.icon2 : letter.icon,"alt":"image"}}):_vm._e(),_vm._v("\n                "+_vm._s(letter.name)+"\n            ")])])}),1),_vm._v(" "),_c('div',{staticClass:"filter-by-letter__content",class:_vm.limit ? 'filter-by-letter__content_limit' : 'filter-by-letter__content_nolimit'},[_vm._l((_vm.filteredFilterLetters),function(letter,index){return [(letter.name !== 'popular' && letter.data.length > 0)?_c('div',{key:index,staticClass:"filter-by-letter__content-child"},[_c('p',{staticClass:"filter-by-letter__content-child-letter"},[_vm._v(_vm._s(letter.name))]),_vm._v(" "),(_vm.limit)?_c('div',{staticClass:"filter-by-letter__content-child-items"},_vm._l((letter.data.slice(0, 10)),function(item,key){return _c('NLink',{key:key,staticClass:"item-wrapper",attrs:{"to":_vm.path + '/' + item.slug,"rel":"follow"}},[_c('picture',{staticClass:"item-wrapper_image",class:_vm.path === `/${_vm.$util.lang.routes.brands}` ? 'bg-white' : ''},[(item.image)?_c('img',{attrs:{"src":item.image.url,"alt":item.name,"width":"32","height":"32","loading":"lazy","onerror":`this.onerror=null;this.src='${
                                    _vm.defaultImage[
                                        _vm.path === `/${_vm.$util.lang.routes.brands}` ? 'store' : 'category'
                                    ]
                                }'`}}):_vm._e()]),_vm._v("\n                        "+_vm._s(item.name)+"\n                    ")])}),1):_vm._e(),_vm._v(" "),(!_vm.limit)?_c('div',{staticClass:"filter-by-letter__content-child-items columns-2 md:columns-3"},_vm._l((letter.data),function(item,key){return _c('NLink',{key:key,staticClass:"item-wrapper",attrs:{"rel":"follow","to":_vm.path + '/' + item.slug}},[_c('picture',{staticClass:"item-wrapper_image",class:_vm.path === `/${_vm.$util.lang.routes.brands}` ? 'bg-white' : ''},[(item.image)?_c('img',{attrs:{"src":item.image.url,"alt":item.name,"width":"32","height":"32","loading":"lazy","onerror":`this.onerror=null; this.src='${
                                    _vm.defaultImage[
                                        _vm.path === `/${_vm.$util.lang.routes.brands}` ? 'store' : 'category'
                                    ]
                                }'`}}):_vm._e()]),_vm._v("\n                        "+_vm._s(item.name)+"\n                    ")])}),1):_vm._e(),_vm._v(" "),(letter.data.length > 10 && _vm.$route.params.slug === undefined)?_c('NLink',{staticClass:"see-more",attrs:{"rel":"follow","to":_vm.path + letter.path}},[_vm._v("\n                    "+_vm._s(_vm.$util.lang.components.formFilterByLetter.see_more)+"\n                    "),_c('img',{attrs:{"src":_vm.$assets.primary.arrowRightCategory,"alt":"icon show more"}})]):_vm._e()],1):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }