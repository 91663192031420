import { render, staticRenderFns } from "./nueva-contrasena.vue?vue&type=template&id=d019a108&scoped=true&"
import script from "./nueva-contrasena.vue?vue&type=script&lang=ts&"
export * from "./nueva-contrasena.vue?vue&type=script&lang=ts&"
import style0 from "./nueva-contrasena.vue?vue&type=style&index=0&id=d019a108&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d019a108",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormFieldPassword: require('/home/runner/work/okdescontos-front/okdescontos-front/components/form/field/Password.vue').default,BtnGeneric: require('/home/runner/work/okdescontos-front/okdescontos-front/components/btn/Generic.vue').default})
