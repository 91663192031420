
import Vue from 'vue'

export default Vue.extend({
    name: 'FieldSelect',
    props: {
        values: {
            type: Array,
        },
    },
    data() {
        return {
            showClearButton: false,
            selected: '',
        }
    },
    methods: {
        onInput(e: InputEvent): void {
            this.$emit('input', (e.target as HTMLSelectElement).value)
        },
    },
})
