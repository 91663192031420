
import Vue from 'vue'
import { parseISO, isBefore } from 'date-fns'
import { MetaInfo } from 'vue-meta/types'
import { Api } from '~/global'
import { currentDate } from '~/libs/date-functions'
import { defaultImage } from '~/libs/useful-functions'

export default Vue.extend({
    name: 'EventDetails',
    async validate({ $api, store, params: { slug }, error, $util }) {
        const storeResult = await $api.pages.loadEvent(slug)

        if (storeResult.feedback === 'data_success') {
            store.commit('pages/setValue', {
                name: 'event',
                page: storeResult.data,
            })
        } else if (storeResult.feedback === 'resource_not_found') {
            error({
                statusCode: 404,
                message: $util.lang.pages.eventosSlug.event_not_found,
            })
        } else {
            error({
                statusCode: 404,
                message: $util.lang.pages.eventosSlug.unexpected_error,
            })
        }

        return true
    },
    data() {
        const storeResult = this.$store.state.pages.event as Api.Responses.Pages.EventDetails['data']

        const discounts: any[] = [...storeResult.discounts.data]
        const meta = storeResult.discounts.meta

        const currentDates = currentDate()

        const [title, description, h1, h2] = this.$util.replacePlaceholders(
            [
                [':month', currentDates.month || ''],
                [':day', String(currentDates.day) || ''],
                [':year', String(currentDates.year) || ''],
                [':site', process.env.SITE_NAME || ''],
            ],
            [
                storeResult.event.seo.title || '',
                storeResult.event.seo.description || '',
                storeResult.event.seo.h1 || '',
                storeResult.event.seo.h2 || '',
            ]
        )

        const endAt = parseISO(storeResult.event.end_at)
        const isEnded = isBefore(new Date(), endAt)
        if (storeResult.cta_cards.length) {
            storeResult.cta_cards.forEach((cta) => {
                discounts.splice(cta.position - 1, 0, { ...cta, isCtaCard: true })
            })
        }
        return {
            defaultImage,
            discounts,
            endAt,
            isEnded,
            storeResult,
            metaInfo: { title, description, h1, h2 },
            loading: false,
            fetchButton: {
                loading: false,
                show:
                    meta.total &&
                    meta.current_page &&
                    meta.last_page &&
                    meta.total > 0 &&
                    meta.current_page < meta.last_page,
            },
            params: {
                page: 2,
            },
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['og:image:secure_url', this.storeResult.event.image?.url || ''],
                    ['og:image', this.storeResult.event.image?.url || ''],
                    ['twitter:image', this.storeResult.event.image?.url || ''],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                    ['keywords', this.storeResult.event.seo.keywords],
                ],
                this.metaInfo?.title
            ),
        }
    },
    computed: {
        existsAnyData(): boolean {
            return !!this.discounts.length
        },
        breadcrumbs(): { path?: string; title: string }[] {
            return [
                {
                    path: '/',
                    title: this.$util.lang.pages.eventosSlug.home,
                },
                {
                    path: `/${this.$util.lang.routes.events}`,
                    title: this.$util.lang.pages.eventosSlug.events,
                },
                {
                    title: this.storeResult.event.title || '',
                },
            ]
        },
    },
    methods: {
        isExpired(date: string | null): boolean {
            if (date) {
                return parseISO(date) < new Date()
            }
            return false
        },
        async loadMore() {
            this.fetchButton.loading = true

            const result = (await this.$api.pages.loadEvent(this.$route.params.slug, {
                page: this.params.page,
            })) as Api.Responses.Pages.EventDetails

            if (result.feedback === 'data_success') {
                this.discounts = [...this.discounts, ...result.data.discounts.data]

                const { current_page, last_page } = result.data.discounts.meta

                if (!last_page || current_page === last_page) {
                    this.fetchButton.show = false
                } else {
                    this.params.page = current_page + 1
                }
            }

            this.fetchButton.loading = false
        },
    },
})
