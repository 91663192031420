import { render, staticRenderFns } from "./EmailVerification.vue?vue&type=template&id=4d08385d&scoped=true&"
import script from "./EmailVerification.vue?vue&type=script&lang=ts&"
export * from "./EmailVerification.vue?vue&type=script&lang=ts&"
import style0 from "./EmailVerification.vue?vue&type=style&index=0&id=4d08385d&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d08385d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnGeneric: require('/home/runner/work/okdescontos-front/okdescontos-front/components/btn/Generic.vue').default})
