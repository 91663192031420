
import Vue from 'vue'

export default Vue.extend({
    name: 'FormQuill',
    props: {
        placeholder: {
            type: String,
            default: 'Adicionar um comentário...',
        },
        alternativeStyle: {
            type: String,
            default: '',
        },
        padding: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            quill: null,
            callbackExecuted: false,
            giphy: {
                show: false,
                position: '',
            },
            gif_svg: `<svg width="19" height="8" viewBox="0 0 19 8" >
                        <path class="ql-fill" d="M5.472,146.286h-4.1A1.371,1.371,0,0,0,0,147.654v5.472a1.371,1.371,0,0,0,1.368,1.368h4.1a1.371,1.371,0,0,0,1.368-1.368V150.39H4.788v2H2v-4.1H6.84v-.684A1.371,1.371,0,0,0,5.472,146.286Z" transform="translate(0 -146.286)" />
                        <path class="ql-fill" d="M353.585,148.338v-2h-6.156v8h2v-2.736h2.736v-2h-2.736v-1.368Z" transform="translate(-334.432 -146.286)" />
                        <rect class="ql-fill" width="2" height="8" transform="translate(8.893)" />
                    </svg>`,
        }
    },
    head() {
        return {
            link: [
                {
                    rel: 'stylesheet',
                    href: '/css/quill.css',
                },
                {
                    rel: 'stylesheet',
                    href: '/css/quill-emoji.css',
                },
            ],
            script: [
                {
                    src: '/js/quill.js',
                    type: 'text/javascript',
                    hid: 'quill-editor',
                    async: true,
                    defer: true,
                    callback: () => this.quillInitializer(),
                },
            ],
        }
    },
    computed: {
        canEdit() {
            return (
                this.$store.getters['auth/isAuth'] &&
                this.$store.state.user.profile?.accepted_conditions_at !== null &&
                this.$store.state.user.profile?.verified_email
            )
        },
    },
    watch: {
        canEdit: {
            immediate: true,
            handler(canEdit) {
                if (canEdit) {
                    this.enableQuill()
                } else {
                    this.disableQuill()
                }
            },
        },
    },
    mounted() {
        try {
            if ('Quill' in window && 'QuillEmoji' in window && !this.callbackExecuted) {
                this.quillInitializer()
            }
        } catch (e) {
            console.log('Quill error: ', { e })
        }
    },
    destroyed() {
        this.$root.$off('clearQuill', this.clearQuill)

        this.$root.$off('focusQuill', this.focusQuill)
    },
    methods: {
        quillInitializer() {
            try {
                if ('Quill' in window && 'QuillEmoji' in window) {
                    this.callbackExecuted = true

                    const Embed = Quill.import('blots/block/embed')

                    class GiphyBlot extends Embed {
                        static blotName = 'giphy'
                        static tagName = 'img'

                        static create(value) {
                            const node = super.create()

                            node.setAttribute('alt', value.alt)
                            node.setAttribute('src', value.url)
                            node.setAttribute('width', '180px')

                            return node
                        }

                        static value(node) {
                            return {
                                alt: node.getAttribute('alt'),
                                url: node.getAttribute('src'),
                            }
                        }
                    }

                    Quill.register(GiphyBlot, true)

                    this.quill = new Quill(this.$refs.editor, {
                        theme: 'snow',
                        placeholder: this.placeholder,
                        modules: {
                            toolbar: this.$refs.toolbar,
                            'emoji-toolbar': true,
                        },
                    })

                    if (this.canEdit) {
                        this.enableQuill()
                    } else {
                        this.disableQuill()
                    }

                    this.quill.getModule('toolbar').addHandler('giphy', () => this.toggle(true))

                    this.quill.on('text-change', () => this.$emit('html', this.quill.root.innerHTML))

                    this.$root.$on('clearQuill', this.clearQuill)

                    this.$root.$on('focusQuill', this.focusQuill)
                }
            } catch (e) {
                console.log('Quill error: ', { e })
            }
        },
        clearQuill() {
            try {
                if (this.quill && 'enable' in this.quill) {
                    this.quill.setContents([])
                }
            } catch (e) {
                console.log('Quill error: ', { e })
            }
        },
        focusQuill() {
            try {
                if (this.quill && 'enable' in this.quill) {
                    this.quill.focus()
                }
            } catch (e) {
                console.log('Quill error: ', { e })
            }
        },
        disableQuill() {
            try {
                if (this.quill && 'enable' in this.quill) {
                    this.quill.enable(false)
                }
            } catch (e) {
                console.log('Quill error: ', { e })
            }
        },
        enableQuill() {
            try {
                if (this.quill && 'enable' in this.quill) {
                    this.quill.enable()
                }
            } catch (e) {
                console.log('Quill error: ', { e })
            }
        },
        showModalIfCantEdit() {
            try {
                if (!this.$store.getters['auth/isAuth']) {
                    this.$store.commit('setModal', {
                        name: 'ModalAuthLogin',
                    })
                } else if (this.$store.state.user.profile?.accepted_conditions_at === null) {
                    this.$store.commit('setModal', {
                        name: 'ModalAuthAcceptTerms',
                        info: {
                            message: this.$util.lang.components.formQuill.accept_terms,
                        },
                    })
                } else if (!this.$store.state.user.profile?.verified_email) {
                    this.$store.commit('setModal', {
                        name: 'ModalAuthEmailVerification',
                        info: {
                            message: this.$util.lang.components.formQuill.verify_account,
                            isForUserVerification: true,
                        },
                    })
                }
            } catch (e) {
                console.log('Quill error: ', { e })
            }
        },
        giphyHandler(url) {
            try {
                const range = this.quill.getSelection(true)

                const alt = 'Gif: powered by: giphy.com'

                this.quill.insertEmbed(range.index, 'giphy', { alt, url }, Quill.sources.USER)

                this.quill.setSelection(range.index + 2, Quill.sources.SILENT)

                this.toggle(false)
            } catch (e) {
                console.log('Quill error: ', { e })
            }
        },
        toggle(show) {
            try {
                if (show) {
                    this.giphy.position =
                        document.documentElement.clientHeight -
                            this.$refs.container.getBoundingClientRect().bottom >
                        250
                            ? '-bottom-68'
                            : 'bottom-0'
                }

                this.giphy.show = show
            } catch (e) {
                console.log('Quill error: ', { e })
            }
        },
        giphyElements() {
            try {
                return [this.$refs.giphy]
            } catch (e) {
                console.log('Quill error: ', { e })
            }
        },
    },
})
