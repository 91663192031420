
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { Api } from '~/global'
import { currentDate } from '~/libs/date-functions'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'Blog',
    props: {},
    async asyncData({ $api, store }) {
        const blog = await $api.pages.loadBlog()

        store.commit('pages/setValue', {
            name: 'blog',
            page: blog,
        })
    },
    data() {
        const info = this.$store.state.pages.blog as Api.Responses.Pages.Blog
        const currentDates = currentDate()

        const [title, description] = this.$util.replacePlaceholders(
            [
                [':month', currentDates.month || ''],
                [':day', String(currentDates.day) || ''],
                [':year', String(currentDates.year) || ''],
                [':site', process.env.SITE_NAME || ''],
            ],
            [info.page.title || '', info.page.description || '']
        )
        return { info, metaInfo: { title, description } }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                ],
                this.metaInfo?.title
            ),
        }
    },
    computed: {
        // Filter categories if there are posts
        categories(): Models.BlogCategory[] {
            return this.info.page.data.categories.filter((category) => {
                return this.info.page.data.featured_articles.some((article) => {
                    return article.category.slug === category.slug
                })
            })
        },
    },
    methods: {
        calculateHowMuchCols(category: string, type: string) {
            if (category === 'Familia' || category === 'Deportes') {
                if (type === 'class') {
                    return 'wrapper-2'
                } else return 2
            } else if (category) {
                if (type === 'class') {
                    return 'wrapper-3'
                } else return 3
            }
        },
    },
})
