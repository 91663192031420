
import Vue from 'vue'

export default Vue.extend({
    name: 'SidebarShare',
    props: {
        socials: {
            type: Object,
            default() {
                return {
                    facebook: true,
                    messenger: true,
                    twitter: true,
                    whatsapp: true,
                    copylink: false,
                    email: false,
                }
            },
        },
        title: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showCopyTooltip: false,
        }
    },
    computed: {
        fullpage(): string {
            return window.location.host + this.$route.fullPath
        },
    },
    methods: {
        shareTwitter() {
            window.open(`https://twitter.com/intent/tweet?text=${this.title}&url=${this.fullpage}`, '_blank')
        },
        shareFacebook() {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.fullpage}`, '_blank')
        },
        shareWhatsapp() {
            window.open(`https://api.whatsapp.com/send?text=${this.title + ' ' + this.fullpage}`, '_blank')
        },
        shareMessenger() {
            window.open(`https://www.messenger.com/t/${this.title + ' ' + this.fullpage}`, '_blank')
        },
        copyToClipboard() {
            this.$copyText(this.fullpage)
            this.showCopyTooltip = true
            setTimeout(() => {
                this.showCopyTooltip = false
            }, 2000)
        },
        shareEmail() {
            window.open(`mailto:?body=${this.title + ' ' + this.fullpage}`, '_blank')
        },
    },
})
