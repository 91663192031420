
import Vue from 'vue'
import { getDictionary } from '@/libs/variant-dict'
import { MetaInfo } from 'vue-meta/types'

type sitemapPromos = {
    discounts: { title: string; slug: number; type: 'coupon' | 'offer' }[]
    month: number
    year: number
}

export default Vue.extend({
    name: 'SitemapDiscounts',
    async asyncData({ $api, params: { slug }, redirect, query: { year } }) {
        const months = [
            'enero',
            'febrero',
            'marzo',
            'abril',
            'mayo',
            'junio',
            'julio',
            'agosto',
            'septiembre',
            'octubre',
            'noviembre',
            'diciembre',
        ]

        if (!months.includes(slug)) {
            return redirect(302, '/sitemap/promociones')
        }

        const sitemap: sitemapPromos = await $api.pages.loadSitemap('discounts', {
            month: months.indexOf(slug) + 1,
            year: year,
        })

        return {
            sitemap,
        }
    },
    data() {
        return {
            getDictionary,
            months: [
                'enero',
                'febrero',
                'marzo',
                'abril',
                'mayo',
                'junio',
                'julio',
                'agosto',
                'septiembre',
                'octubre',
                'noviembre',
                'diciembre',
            ],
            sitemap: {} as sitemapPromos,
            metaInfo: {
                title: 'Mapa del sitio - MEGAdescuentos',
                description:
                    'Mapa del sitio de MEGAdescuentos. Consulta categorías, tiendas, cupones, ofertas y promociones de forma ordenada y accesible.',
            },
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                ],
                this.metaInfo?.title
            ),
        }
    },
})
