
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { Api } from '@/global'
import { currentDate } from '~/libs/date-functions'

export default Vue.extend({
    name: 'Novelty',
    async asyncData({ $api, store }) {
        const news = await $api.pages.loadNovelties()
        store.commit('pages/setValue', { name: 'news', page: news })
    },
    data() {
        const news = this.$store.state.pages.news as Api.Responses.Pages.Novelties
        const currentDates = currentDate()

        const [title, description] = this.$util.replacePlaceholders(
            [
                [':month', currentDates.month || ''],
                [':day', String(currentDates.day) || ''],
                [':year', String(currentDates.year) || ''],
                [':site', process.env.SITE_NAME || ''],
            ],
            [news.page.title || '', news.page.description || '']
        )

        return {
            news,
            metaInfo: { title, description },
            pagination: {
                loading: false,
                show: true,
            },
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                ],
                this.metaInfo?.title
            ),
        }
    },
    methods: {
        loadMoreNews() {
            this.pagination.loading = true
            const newNews = JSON.parse(JSON.stringify(this.news)) as Api.Responses.Pages.Novelties
            const page = newNews.page.data.novelties.meta.current_page + 1

            const { novelties } = newNews.page.data

            this.$api.pages
                .loadNovelties({
                    page,
                })
                .then((res: Api.Responses.Pages.Novelties) => {
                    const { meta, links, data } = res.page.data.novelties
                    novelties.data.push(...data)
                    novelties.meta = meta
                    novelties.links = links

                    this.$store.commit('pages/setValue', { name: 'news', page: newNews })
                    this.news = this.$store.state.pages.news as Api.Responses.Pages.Novelties

                    this.pagination = {
                        loading: false,
                        show: page < this.news.page.data.novelties.meta.last_page,
                    }
                })
                .catch((err: any) => {
                    console.error('error', err)
                    this.pagination.loading = false
                })
        },
    },
})
