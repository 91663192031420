
import Vue, { PropType } from 'vue'
import DatePicker from 'vue2-datepicker'
import { Api } from '~/global'
import { Models } from '~/types/models'
import { getDictionary } from '~/libs/variant-dict'
import 'vue2-datepicker/locale/es'
import 'vue2-datepicker/index.css'
import { defaultImage } from '~/libs/useful-functions'

export default Vue.extend({
    name: 'CardShareStepTwo',
    components: {
        DatePicker,
    },
    props: {
        kind: {
            type: String as PropType<'coupon' | 'offer' | 'chat'>,
        },
        kindtext: {
            type: String,
        },
        loading: {
            default: false,
            type: Boolean,
        },
        validationStatus: {
            type: Object,
        },
    },
    data() {
        const type = getDictionary(this.kind)
        return {
            type,
            useDefaultImage: false,
            latestScrapped: '' as string,
            elementsError: [] as Array<any>,
            placeholder: {
                description: this.$util.lang.components.cardShareStepTwo.description_long,
            },
            dropdown: {
                show: {
                    category: false,
                    country: false,
                    participate: false,
                },
                country: {
                    ready: false,
                    selected: { name: this.$util.lang.components.cardShareStepTwo.select, id: null },
                    data: [] as Models.Country[],
                },
                category: {
                    ready: false,
                    selected: { name: this.$util.lang.components.cardShareStepTwo.select, id: null },
                    data: [] as Models.Category[],
                },
                forumCategories: {
                    ready: false,
                    selected: { name: this.$util.lang.components.cardShareStepTwo.select, id: null },
                    data: [] as Models.ForumCategory[],
                },
                participate: {
                    ready: true,
                    selected: { name: this.$util.lang.components.cardShareStepTwo.select, icon: null, id: 0 },
                    data: [
                        {
                            id: 1,
                            name: 'Facebook',
                            icon: this.$assets.social.facebook,
                        },
                        {
                            id: 2,
                            name: 'Instagram',
                            icon: this.$assets.social.instagram,
                        },
                        {
                            id: 3,
                            name: 'Twitter',
                            icon: this.$assets.social.twitter,
                        },
                        {
                            id: 4,
                            name: this.$util.lang.components.cardShareStepTwo.website,
                            icon: this.$assets.social.website,
                        },
                        {
                            id: 5,
                            name: this.$util.lang.components.cardShareStepTwo.other,
                            icon: this.$assets.social.other,
                        },
                    ],
                },
            },
            formData: {
                imageActive: 0,
                imagesUploaded: [] as Array<{ dataUrl?: string | ArrayBuffer | null; file?: File }>,
                url: '',
                offer_details: {
                    price_offer: '',
                    regular_price: '',
                    ship_costs: '',
                    freeship: false,
                },
                discount: {
                    type: '',
                    value: null,
                },
                code: '',
                title: '',
                description: '',
                categoryId: null,
                countryId: null,
                forumCategoryId: null,
                datePicker: {
                    start: {
                        date: '',
                        time: '00:01',
                    },
                    end: {
                        date: '',
                        time: '23:59',
                    },
                },
                isLocal: false,
                messages: 0,
                type: 'see-coupon',
            },
        }
    },
    computed: {
        defaultImage(): string {
            return this.type.variant === 'offer' ? defaultImage.offer : defaultImage.coupon
        },
        buttonType(): string {
            if (this.type.variant === 'offer') {
                return this.formData.url?.length > 0 && this.formData.code?.length > 0
                    ? 'offer-code'
                    : this.formData.url?.length > 0
                    ? 'offer'
                    : 'no-link-offer'
            } else return this.type.variant
        },
        dropdownSelect: {
            get(): any {
                return this.kind === 'chat' ? this.dropdown.forumCategories : this.dropdown.category
            },
            set(newValue: any) {
                if (this.kind === 'chat') {
                    this.dropdown.forumCategories = newValue
                } else {
                    this.dropdown.category = newValue
                }
            },
        },
    },
    watch: {
        '$route.query': {
            handler(val) {
                this.formData.url = val.url
                this.formData.code = val.code
                if (val.images && val.images.length > 0) {
                    const newImages = val.images.split(',')
                    this.formData.imagesUploaded = [...newImages.reverse().map((i: any) => ({ dataUrl: i }))]
                } else {
                    this.formData.imagesUploaded = [{ dataUrl: this.defaultImage }]
                    this.useDefaultImage = true
                }
            },
            immediate: true,
        },
        'dropdown.country.selected'(val) {
            if (val) {
                this.formData.countryId = val.id
            }
        },
        'dropdown.category.selected'(val) {
            if (val) {
                this.formData.categoryId = val.id
            }
        },
        'dropdown.forumCategories.selected'(val) {
            if (val) {
                this.formData.forumCategoryId = val.id
            }
        },
        kind: {
            handler(val) {
                if (val === 'oferta') {
                    this.formData.type = 'see-offer'
                }
            },
            immediate: true,
        },
        'formData.title': {
            handler(val) {
                if (this.kind === 'offer') {
                    if (
                        val.includes('cupon') ||
                        val.includes('cupón') ||
                        this.formData.description.includes('cupon') ||
                        this.formData.description.includes('cupón')
                    ) {
                        this.formData.type = 'see-coupon'
                    } else {
                        this.formData.type = 'see-offer'
                    }
                }
            },
            immediate: true,
        },
        'formData.description': {
            handler(val) {
                if (this.kind === 'offer') {
                    if (
                        val.includes('cupon') ||
                        val.includes('cupón') ||
                        this.formData.title.includes('cupon') ||
                        this.formData.title.includes('cupón')
                    ) {
                        this.formData.type = 'see-coupon'
                    } else {
                        this.formData.type = 'see-offer'
                    }
                }
            },
            immediate: true,
        },
    },
    async created() {
        const storedCategories = this.$store.state.create.categories
        const categories: Api.Responses.General.Categories =
            storedCategories ||
            (await this.$api.general.loadCategories({
                first_level: true,
                limit: 500,
                without_pagination: true,
            }))
        !!categories && this.$store.commit('create/setCategories', categories)

        this.dropdown.category.data.push(...categories.data.results)
        this.dropdown.category.ready = true

        const storedForumCategories = this.$store.state.create.forumCategories
        const forumCategories = storedForumCategories || (await this.$api.general.loadForumCategories())
        !!forumCategories && this.$store.commit('create/setForumCategories', forumCategories)

        this.dropdown.forumCategories.data.push(...forumCategories.data.results)
        this.dropdown.forumCategories.ready = true

        const storedCountries = this.$store.state.create.countries
        const countries = storedCountries || (await this.$api.general.loadCountries())
        !!countries && this.$store.commit('create/setCountries', countries)

        this.dropdown.country.data.push(...countries.data.results)
        this.dropdown.country.ready = true
    },

    methods: {
        async getImagesUsingScrapper() {
            if (!this.formData.url && this.formData.url === this.latestScrapped) {
                this.formData.imagesUploaded = [{ dataUrl: this.defaultImage }]
                this.useDefaultImage = true
                return
            }
            if (
                !this.formData.url ||
                (this.latestScrapped === this.formData.url && this.formData.imagesUploaded.length > 1)
            ) {
                return
            }
            const res = await this.$api.general.useScrapper({
                url: this.formData.url,
                type: this.type.variant,
            })

            if (res.feedback === 'params_validation_failed') {
                this.$emit(
                    'setValidations',
                    'url',
                    this.$util.lang.components.cardShareStepTwo.insert_valid_url
                )
            } else if (res.feedback === 'data_success') {
                this.formData.imagesUploaded = [...res.data.images.reverse().map((i) => ({ dataUrl: i }))]
                this.useDefaultImage = false
            } else if (res.feedback === 'data_empty') {
                this.formData.imagesUploaded = [{ dataUrl: this.defaultImage }]
                this.useDefaultImage = true
                this.$root.$emit('notification', {
                    duration: 3000,
                    dismissButtonText: this.$util.lang.components.cardShareStepTwo.hide,
                    text: this.$util.lang.components.cardShareStepTwo.images_not_found,
                    type: 'help',
                })
            } else {
                this.formData.imagesUploaded = [{ dataUrl: this.defaultImage }]
                this.useDefaultImage = true
                this.$root.$emit('notification', {
                    duration: 3000,
                    dismissButtonText: this.$util.lang.components.cardShareStepTwo.hide,
                    text: this.$util.lang.components.cardShareStepTwo.images_not_found,
                    type: 'help',
                })
            }
            this.latestScrapped = this.formData.url
        },
        validateTitle(): void {
            if (this.formData.title.length < 40) {
                this.$emit(
                    'setValidations',
                    'title',
                    this.$util.lang.components.cardShareStepTwo.title_40_chars
                )
            }
        },
        disabledBeforeToday(date: any) {
            const today = new Date()
            today.setHours(0, 0, 0, 0)

            return date < today
        },
        disabledBeforeStartTime(date: any) {
            const today = new Date(this.formData.datePicker.start.date)
            // today.setHours(0, 0, 0, 0)

            return date < today
        },
        notBeforeStartTime(date: any) {
            const hours = this.formData.datePicker.start.time.split(':')[0]
            const minutes = this.formData.datePicker.start.time.split(':')[1]
            return date.getHours() < hours || date.getMinutes() < minutes
            // return date < new Date(new Date().setHours(12, 5, 0, 0))
        },
        removeImage(key: number): void | any {
            if (this.formData.imagesUploaded.length === 1) {
                this.formData.imagesUploaded = [{ dataUrl: this.defaultImage }]
                this.useDefaultImage = true
                this.makeImageActive(0)
            } else {
                this.formData.imagesUploaded.splice(key, 1)

                if (
                    key < this.formData.imageActive ||
                    (this.formData.imagesUploaded.length === key && key === this.formData.imageActive)
                ) {
                    this.makeImageActive(this.formData.imageActive - 1)
                }
            }
        },
        makeImageActive(key: number): void {
            if (typeof key === 'number') {
                this.formData.imageActive = key
            }
        },
        handleNext() {
            this.$emit('makePost', { ...this.formData, ...this.dropdown })
        },
        uploadImage(ev: any) {
            const image = ev.target.files[0] as File | undefined

            if (image) {
                if (
                    image.type !== 'image/jpg' &&
                    image.type !== 'image/jpeg' &&
                    image.type !== 'image/png' &&
                    image.type !== 'image/svg'
                ) {
                    return this.$root.$emit('notification', {
                        duration: 3000,
                        dismissButtonText: this.$util.lang.components.cardShareStepTwo.hide,
                        text: this.$util.lang.components.cardShareStepTwo.file_not_permitted,
                        type: 'error',
                    })
                } else if (image.size > 1000000) {
                    return this.$root.$emit('notification', {
                        duration: 3000,
                        dismissButtonText: this.$util.lang.components.cardShareStepTwo.hide,
                        text: this.$util.lang.components.cardShareStepTwo.size_exceeded,
                        type: 'error',
                    })
                } else {
                    try {
                        const reader = new FileReader()

                        reader.readAsDataURL(image)

                        reader.onload = (e) => {
                            if (this.useDefaultImage) {
                                this.formData.imagesUploaded = [{ file: image, dataUrl: e.target?.result }]
                                this.useDefaultImage = false
                            } else {
                                this.formData.imagesUploaded.push({ file: image, dataUrl: e.target?.result })
                            }
                        }
                    } catch (e) {
                        console.log('Fail on reading image url', { e })
                    }
                }
            }
        },

        dropdownToggle(show: boolean, name: string): void {
            this.dropdown.show[name as 'category'] = show
        },
    },
})
