var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"field-quantity",class:{ disabled: _vm.isDisabled }},[_c('div',{staticClass:"field-quantity_box",class:[
                { invalid: _vm.invalid },
                _vm.isFocus[0] ? 'bg-site-primary text-white' : 'bg-[#F8F7FC]',
                _vm.discountLocked ? 'bg-[#f8f7fc80]' : '',
            ]},[_c('div',{staticClass:"head",class:[
                    _vm.isFocus[0] ? 'text-white' : 'bg-[#F8F7FC]',
                    _vm.discountLocked ? 'text-gray-800 bg-[#f8f7fc80]' : '',
                ]},[_vm._v("\n                "+_vm._s(_vm.$util.lang.components.formFieldPrice.offer_price)+"\n                "),_c('span',{class:[
                        _vm.isFocus[0] ? 'text-gray-200' : 'text-gray-800',
                        _vm.discountLocked ? 'text-gray-800' : '',
                    ]},[_vm._v("("+_vm._s(_vm.$util.lang.components.formFieldPrice.optional)+")")])]),_vm._v(" "),_c('div',{staticClass:"input-wrap",class:[_vm.isFocus[0] ? 'text-white' : '', _vm.discountLocked ? 'text-gray-800' : '']},[_vm._v("\n                $"),_c('input',{class:[
                        [
                            _vm.isFocus[0]
                                ? 'bg-site-primary text-white placeholder:text-gray-200'
                                : 'bg-[#F8F7FC]',
                            _vm.discountLocked ? 'text-gray-800 bg-[#f8f7fc80]' : '',
                        ],
                    ],attrs:{"disabled":_vm.discountLocked,"type":"number","placeholder":"0"},domProps:{"value":_vm.priceoffer},on:{"input":(e) => _vm.$emit('update:priceoffer', e.target.value),"focus":function($event){return _vm.handleFocus(0, true)},"blur":function($event){return _vm.handleFocus(0, false)}}})])]),_vm._v(" "),_c('div',{staticClass:"field-quantity_box",class:[
                { invalid: _vm.invalid },
                _vm.isFocus[1] ? 'bg-site-primary text-white' : 'bg-[#F8F7FC]',
                _vm.discountLocked ? 'bg-[#f8f7fc80]' : '',
            ]},[_c('div',{staticClass:"head",class:[
                    _vm.isFocus[1] ? 'text-white' : 'bg-[#F8F7FC]',
                    _vm.discountLocked ? 'text-gray-800 bg-[#f8f7fc80]' : '',
                ]},[_vm._v("\n                "+_vm._s(_vm.$util.lang.components.formFieldPrice.regular_price)+"\n                "),_c('span',{class:[
                        _vm.isFocus[1] ? 'text-gray-200' : 'text-gray-800',
                        _vm.discountLocked ? 'text-gray-800' : '',
                    ]},[_vm._v("\n                    ("+_vm._s(_vm.$util.lang.components.formFieldPrice.optional)+")\n                ")])]),_vm._v(" "),_c('div',{staticClass:"input-wrap",class:[_vm.isFocus[1] ? 'text-white' : '', _vm.discountLocked ? 'text-gray-800' : '']},[_vm._v("\n                $"),_c('input',{class:[
                        [
                            _vm.isFocus[1]
                                ? 'bg-site-primary text-white placeholder:text-gray-200'
                                : 'bg-[#F8F7FC]',
                            _vm.discountLocked ? 'text-gray-800 bg-[#f8f7fc80]' : '',
                        ],
                    ],attrs:{"disabled":_vm.discountLocked,"placeholder":"0","type":"number"},domProps:{"value":_vm.priceregular},on:{"input":(e) => _vm.$emit('update:priceregular', e.target.value),"focus":function($event){return _vm.handleFocus(1, true)},"blur":function($event){return _vm.handleFocus(1, false)}}})])]),_vm._v(" "),_c('div',{staticClass:"field-quantity_box relative",class:[
                _vm.isFocus[2] ? 'bg-site-primary text-white' : 'bg-[#F8F7FC]',
                _vm.costshipLocked ? 'bg-[#f8f7fc80]' : '',
            ]},[_c('div',{staticClass:"head",class:[
                    _vm.isFocus[2] ? 'text-white' : 'bg-[#F8F7FC]',
                    _vm.costshipLocked ? 'text-gray-800 bg-[#f8f7fc80]' : '',
                ]},[_vm._v("\n                "+_vm._s(_vm.$util.lang.components.formFieldPrice.ship_price)+"\n                "),_c('span',{class:[
                        _vm.isFocus[2] ? 'text-gray-200' : 'text-gray-800',
                        _vm.costshipLocked ? 'text-gray-800' : '',
                    ]},[_vm._v("("+_vm._s(_vm.$util.lang.components.formFieldPrice.optional)+")")])]),_vm._v(" "),_c('div',{staticClass:"input-wrap",class:[_vm.isFocus[2] ? 'text-white' : '', _vm.costshipLocked ? 'text-gray-800' : '']},[_vm._v("\n                $"),_c('input',{class:[
                        _vm.isFocus[2]
                            ? 'bg-site-primary text-white placeholder:text-gray-200'
                            : 'bg-[#F8F7FC]',
                        _vm.costshipLocked ? 'text-gray-800 bg-[#f8f7fc80]' : '',
                    ],attrs:{"disabled":_vm.costshipLocked,"type":"number","placeholder":"0"},domProps:{"value":_vm.shipcost},on:{"focus":function($event){return _vm.handleFocus(2, true)},"blur":function($event){return _vm.handleFocus(2, false)}}})])])]),_vm._v(" "),_c('div',{staticClass:"field-quantity__checkboxes"},[_c('FormFieldCheckbox',{model:{value:(_vm.freediscount),callback:function ($$v) {_vm.freediscount=$$v},expression:"freediscount"}},[_vm._v("\n            "+_vm._s(_vm.$util.lang.components.formFieldPrice.free_promotion)+"\n        ")]),_vm._v(" "),_c('FormFieldCheckbox',{model:{value:(_vm.freeshiping),callback:function ($$v) {_vm.freeshiping=$$v},expression:"freeshiping"}},[_vm._v("\n            "+_vm._s(_vm.$util.lang.components.formFieldPrice.free_ship)+"\n        ")])],1),_vm._v(" "),(_vm.invalid && typeof _vm.invalid === 'string')?_c('div',{staticClass:"field-quantity__error"},[_c('img',{attrs:{"src":_vm.$assets.red.formError,"alt":"icon"}}),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$util.lang.components.formFieldPrice.price_offer_higher_than_regular))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }