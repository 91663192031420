
import { Context } from '@nuxt/types'
import { MetaInfo } from 'vue-meta/types'
import Vue from 'vue'
import { /* parse as parseCookie, */ serialize as serializeCookie } from 'cookie'
import { cookies as cookiesKeys } from '~/config/constants'

const setAuthenticationCookies = (
    res: Context['res'],
    cookies: { access_token: string; expires_at: number }
) => {
    res.setHeader('Set-Cookie', [
        serializeCookie(cookiesKeys.access_token_key, cookies.access_token, {
            expires: new Date(cookies.expires_at * 1000),
            path: '/',
        }),
        serializeCookie(cookiesKeys.token_expires_at_key, String(cookies.expires_at), {
            expires: new Date(cookies.expires_at * 1000),
            path: '/',
        }),
    ])
}

export default Vue.extend({
    name: 'VerifyAccount',
    validate({ query: { code }, error, res, $util }) {
        if (!code || !res) {
            error({
                statusCode: 401,
                message: $util.lang.pages.verificarCuenta.request_failed,
            })
        }
        return true
    },
    async asyncData({ query, $api, store, $assets, $axios, res, $util }) {
        const init = {
            message: '',
            title: '',
            status: null as null | boolean,
            buttons: [] as { text: string; icon: string; link?: string; handler?: () => void }[],
            redirect: { link: '/', text: $util.lang.pages.verificarCuenta.redirect } as object | null,
        }

        try {
            const result = await $api.user.verifyAccount(query.code as string)

            if (result.feedback === 'verified_account_success') {
                store.commit('auth/setAuthData', result.data)

                store.commit('auth/setAuthenticationStatus', true)

                $axios.setToken(result.data.access_token, result.data.token_type)

                setAuthenticationCookies(res, {
                    access_token: result.data.access_token,
                    expires_at: result.data.expires_at,
                })

                if (!store.state.user.profile?.id) {
                    const user = await $api.user.profile()

                    if (user.feedback === 'data_success') {
                        store.commit('user/setProfile', user.data.user)
                    }
                }

                init.status = true

                init.title = $util.lang.pages.verificarCuenta.verified_success

                init.title = $util.lang.pages.verificarCuenta.enjoy

                init.buttons = [
                    {
                        icon: $assets.white.homeMenu,
                        text: $util.lang.pages.verificarCuenta.go_home,
                        link: '/',
                    },
                    {
                        icon: $assets.white.user,
                        text: $util.lang.pages.verificarCuenta.go_profile,
                        link: `/${$util.lang.routes.dashboard}`,
                    },
                ]
            } else {
                init.status = false

                init.redirect = null

                init.title = $util.lang.pages.verificarCuenta.error_on_validate

                init.message = result.data.code?.[0] ? $util.lang.pages.verificarCuenta.code_not_valid : ''

                if (store.getters['auth/isAuth']) {
                    init.buttons = [
                        {
                            icon: $assets.white.userHead,
                            text: $util.lang.pages.verificarCuenta.go_to_settings,
                            link: `/${$util.lang.routes.dashboard}/${$util.lang.routes.settings}`,
                        },
                    ]
                } else if (result.feedback === 'params_validation_failed') {
                    init.redirect = null

                    init.buttons = [
                        {
                            icon: $assets.white.userHead,
                            text: $util.lang.pages.verificarCuenta.contact_support,
                            link: `/${$util.lang.routes.contact}`,
                        },
                        {
                            icon: $assets.white.logout,
                            text: $util.lang.pages.verificarCuenta.go_home,
                            link: '/',
                        },
                    ]
                }
            }
        } catch (e) {
            init.message = $util.lang.pages.verificarCuenta.verify_error
            init.buttons = [
                {
                    icon: $assets.white.user,
                    text: $util.lang.pages.verificarCuenta.contact_support,
                    link: `/${$util.lang.routes.contact}`,
                },
                {
                    icon: $assets.white.homeMenu,
                    text: $util.lang.pages.verificarCuenta.go_home,
                    link: '/',
                },
            ]
        }

        return init
    },
    data() {
        return {
            countdown: 10,
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.verificarCuenta.title_seo
        const description = this.$util.lang.pages.verificarCuenta.description_seo
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
    mounted() {
        if (this.$data.redirect) {
            const timer = setInterval(() => {
                this.countdown--
                if (this.countdown === 0) {
                    clearInterval(timer)

                    this.$router.push({ path: this.$data.redirect.link })
                }
            }, 1000)
        }
    },
})
