
import Vue from 'vue'
import { remove as removeCookie, set as setCookie } from 'js-cookie'
import { cookies } from '~/config/constants'

export default Vue.extend({
    name: 'ModalAuthLogin',
    data() {
        return {
            loginError: '',
            pros: [
                this.$util.lang.components.modalAuthLogin.comment_vote_offers,
                this.$util.lang.components.modalAuthLogin.participate,
                this.$util.lang.components.modalAuthLogin.joinus,
            ],
            rememberMe: false,
            newsAccept: false,
            form: {
                data: {
                    username: '',
                    password: '',
                },
                validationStatus: {
                    username: false as string | boolean,
                    password: false as string | boolean,
                },
            },
        }
    },
    computed: {
        rrss(): {
            text: string
            icon: string
            iconAlt: string
            handler: any
        }[] {
            return [
                {
                    text: this.$util.lang.components.modalAuthLogin.login_google,
                    icon: this.$assets.social.googleAccount,
                    iconAlt: 'Google button',
                    handler: () => this.socialSignIn('google'),
                },
                {
                    text: this.$util.lang.components.modalAuthLogin.login_facebook,
                    icon: this.$assets.social.facebookAccount,
                    iconAlt: 'Facebook button',
                    handler: () => this.socialSignIn('facebook'),
                },
            ]
        },
    },
    mounted() {
        if (this.$store.state.auth.authData || this.$store.state.user.profile) {
            this.$store.commit('auth/setAuthenticationStatus', false)
            this.$store.commit('auth/setAuthData', null)
            this.$store.commit('user/setProfile', null)
            removeCookie(cookies.access_token_key)
            removeCookie(cookies.token_expires_at_key)
        }
    },
    methods: {
        async signIn() {
            this.$nuxt.$loading.start()

            try {
                const result = await this.$api.auth.signIn('withPassword', this.form.data)

                if (result.feedback === 'params_validation_failed') {
                    this.form.validationStatus = {
                        username: result.data.username?.[0] || false,
                        password: result.data.password?.[0] || false,
                    }
                }

                if (result.feedback === 'token_created_successfull') {
                    setCookie(cookies.access_token_key, result.data.access_token, {
                        expires: result.data.expires_at,
                    })

                    setCookie(cookies.token_expires_at_key, result.data.expires_at.toString(), {
                        expires: result.data.expires_at,
                    })

                    location?.reload()
                }
                if (result.feedback === 'signin_failed') {
                    this.loginError = this.$util.lang.components.modalAuthLogin.invalid_credentials
                    this.$nuxt.$loading.finish()
                }
            } catch (e) {
                console.log('Login error: ', { e })
                this.$nuxt.$loading.finish()
            }
        },
        socialSignIn(strategy: 'google' | 'facebook') {
            this.$nuxt.$loading.start()
            try {
                this.$api.auth.socialSingIn(strategy, {
                    success_url: location.origin + location.pathname,
                    error_url: location.origin + location.pathname,
                })
            } catch (e) {
                this.$nuxt.$loading.finish()
            }
        },
        forgotPassword() {
            this.$store.commit('setModal', { name: 'FormForgotPassword' })
        },
        changeModal() {
            this.$store.commit('setModal', { name: 'ModalAuthRegister' })
        },
    },
})
