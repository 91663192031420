
import Vue, { PropType } from 'vue'

export default Vue.extend({
    name: 'SidebarNetworkPages',
    props: {
        title: {
            type: String,
        },
        model: {
            type: String,
        },
        socials: {
            type: Array as PropType<
                {
                    name: 'facebook' | 'instagram' | 'twitter' | 'youtube' | 'tiktok' | 'telegram'
                    url: string
                }[]
            >,
        },
    },
    data() {
        return {}
    },
})
