
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'CardSliderFeaturedStores',
    props: {
        title: {
            type: String,
            default: 'Tiendas destacadas',
        },
        info: {
            type: Array as PropType<Models.Discount[]>,
        },
    },
    data() {
        return {
            skeletons: [1, 2, 3],
        }
    },
    computed: {
        mobile(): boolean {
            return this.$bp?.mb
        },
    },
})
