
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'BannerRandom',
    props: {
        info: {
            type: Object as PropType<Models.Banner>,
            required: true,
        },
    },
    data() {
        const images = {
            mobile: this.info.images[0].url,
            desktop: this.info.images[1].url,
        }
        return { images }
    },
})
