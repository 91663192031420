
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'
export default Vue.extend({
    name: 'DiscountDetailsFooter',
    props: {
        info: {
            type: Object as PropType<{
                similar_categories: Models.Category[]
                similar_stores: Models.Store[]
                popular_stores: Models.Store[]
            }>,
            required: true,
        },
    },
})
