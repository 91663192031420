
import Vue from 'vue'
import { set } from 'js-cookie'
import { cookies } from '@/config/constants'

export default Vue.extend({
    name: 'ModalCookiesBanner',
    methods: {
        acceptCookies() {
            const now = new Date()

            const expirationDate = new Date(new Date().setFullYear(now.getFullYear() + 1))

            set(cookies.allow_cookies, 'true', {
                expires: expirationDate,
            })

            this.$emit('close')
        },
    },
})
