
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { Api } from '~/global'
import { currentDate } from '~/libs/date-functions'
import { removeXSS } from '~/libs/useful-functions'

type postIndex = {
    id: string
    text: string
}

export default Vue.extend({
    name: 'BlogArticle',
    async validate({ $api, store, params, error, $util }) {
        const storeResult = await $api.pages.loadArticle(params.article)

        if (storeResult.feedback === 'data_success') {
            store.commit('pages/setValue', {
                name: 'article',
                page: storeResult,
            })
        } else if (storeResult.feedback === 'resource_not_found') {
            error({
                statusCode: 404,
                message: $util.lang.pages.blogArticle.event_not_found,
            })
        } else {
            error({
                statusCode: 404,
                message: $util.lang.pages.blogArticle.error,
            })
        }

        return true
    },
    data() {
        const storedArticle = this.$store.state.pages.article as Api.Responses.Pages.Articles

        const currentDates = currentDate()

        const [title, description] = this.$util.replacePlaceholders(
            [
                [':month', currentDates.month || ''],
                [':year', String(currentDates.year) || ''],
                [':title', this.$util.textShortener(storedArticle.data.article.title || '', 40)],
                [
                    ':description',
                    this.$util.textShortener(
                        storedArticle.data.article.content?.replace(
                            /<\/?[a-z][a-z0-9]*[^<>]*>|<!--.*?-->/gim,
                            ''
                        ) || '',
                        150
                    ),
                ],
                [':site', process.env.SITE_NAME || ''],
            ],
            [storedArticle.data.article.seo?.title || '', storedArticle.data.article.seo?.description || '']
        )

        return {
            metaInfo: {
                title,
                description,
            },
            storedArticle,
            valorated: storedArticle.data.article.liked,
            isIndexAvailable: false,
            postIndex: [] as postIndex[],
            // test: `<h1>esto es un h1</h1><div>esto es un div</div><h2>esto es un h2</h2><h2></h2><h2>esto es otro h2</h2><h2>Y este tambien</h2>`,
            removeXSS,
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['twitter:image', this.storedArticle.data.article.image?.url || ''],
                    ['og:image', this.storedArticle.data.article.image?.url || ''],
                    ['og:image:secure_url', this.storedArticle.data.article.image?.url || ''],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                ],
                this.metaInfo?.title
            ),
        }
    },
    computed: {
        breadcrumbs(): { path?: string; title: string }[] {
            return [
                {
                    path: '/',
                    title: this.$util.lang.pages.blogArticle.home,
                },
                {
                    path: `/${this.$util.lang.routes.blog}`,
                    title: this.$util.lang.pages.blogArticle.blog,
                },
                {
                    path: `/${this.$util.lang.routes.blog}/` + this.storedArticle.data.article.category?.slug,
                    title: this.storedArticle.data.article.category?.name || '',
                },
                {
                    title: this.storedArticle.data.article.title || '',
                },
            ]
        },
    },
    mounted() {
        this.getTextIndex()
    },
    methods: {
        gotoPostId(id: string) {
            this.$scrollTo(id)
        },
        getTextIndex(): void {
            if (this.$refs.post) {
                const h2 = (this.$refs.post as HTMLElement).querySelectorAll('h2')
                // check if h2 element has text, then get innerText and id and creates a object with it, and sets an id to each h2
                if (h2.length > 0) {
                    h2.forEach((el: HTMLElement, key: number) => {
                        if (el.innerText.length > 0) {
                            const id = 'post-' + key
                            el.id = id
                            this.postIndex.push({
                                id,
                                text: el.innerText,
                            })
                        }
                    })
                }
                if (this.postIndex.length > 0) this.isIndexAvailable = true
            }
        },
        async makeVote(): Promise<void> {
            const res = await this.$api.create.postLike({
                likeable_id: this.storedArticle.data.article.id,
                type: this.valorated ? 0 : 1,
                likeable_type: 'article',
            })

            if (res.feedback === 'data_success') {
                this.valorated = !this.valorated
            } else {
                this.$root.$emit('notification', {
                    duration: 3000,
                    dismissButtonText: this.$util.lang.pages.blogArticle.hide,
                    text: this.$util.lang.pages.blogArticle.error_on_valorate,
                    type: 'error',
                })
            }
        },
    },
})
