
import Vue from 'vue'

export default Vue.extend({
    name: 'ModalGeneric',
    data() {
        return {}
    },
    methods: {
        onNext() {
            this.$emit('close')
        },
    },
})
