
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'CardAlertNotValidOffer',
    props: {
        info: {
            type: Object as PropType<Models.HeaderMessage>,
            required: true,
        },
    },
})
