
import Vue, { PropType } from 'vue'

export default Vue.extend({
    name: 'MicroQuestion',
    props: {
        info: Object as PropType<{
            question: String
            answer: String
        }>,
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        showResponse() {
            this.show = !this.show
        },
    },
})
