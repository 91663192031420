
import Vue, { PropType } from 'vue'

export default Vue.extend({
    name: 'MicroStepper',
    props: {
        // @model Recibe mini o normal.
        model: {
            type: String,
            default: 'normal',
            required: true,
        },
        // Step actual 1-2-3 || 1-2 en el caso de que model = mini
        step: {
            type: Number as PropType<number>,
            default: 1,
        },
    },
    data() {
        return {
            steps: [
                {
                    title: this.$util.lang.components.microStepper.share,
                },
                {
                    title: this.$util.lang.components.microStepper.description,
                },
                {
                    title: this.$util.lang.components.microStepper.finished,
                },
            ],
        }
    },
    computed: {
        filteredSteps(): { title: string }[] {
            const newSteps = this.steps
            if (this.model === 'mini') {
                newSteps.shift()
            }
            return newSteps
        },
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {},
})
