import { render, staticRenderFns } from "./StepOne.vue?vue&type=template&id=4a976542&scoped=true&"
import script from "./StepOne.vue?vue&type=script&lang=ts&"
export * from "./StepOne.vue?vue&type=script&lang=ts&"
import style0 from "./StepOne.vue?vue&type=style&index=0&id=4a976542&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a976542",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroStepper: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/Stepper.vue').default,MicroTabs: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/Tabs.vue').default,FormFieldGeneric: require('/home/runner/work/okdescontos-front/okdescontos-front/components/form/field/Generic.vue').default})
