import { render, staticRenderFns } from "./index.vue?vue&type=template&id=ab0b1eea&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=ab0b1eea&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroBreadcrumbs: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/Breadcrumbs.vue').default})
