
import Vue, { PropType } from 'vue'

export default Vue.extend({
    name: 'MicroBreadcrumbs',
    props: {
        layers: {
            type: Array as PropType<Object>,
        },
        textColor: {
            type: String,
        },
        margin: {
            type: String,
        },
        styling: {
            type: String,
            default: 'flex',
        },
    },
})
