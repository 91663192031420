import { render, staticRenderFns } from "./PostUserInfo.vue?vue&type=template&id=fd39d5e2&scoped=true&"
import script from "./PostUserInfo.vue?vue&type=script&lang=ts&"
export * from "./PostUserInfo.vue?vue&type=script&lang=ts&"
import style0 from "./PostUserInfo.vue?vue&type=style&index=0&id=fd39d5e2&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd39d5e2",
  null
  
)

export default component.exports