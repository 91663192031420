var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"blog-post"},[_c('MicroBreadcrumbs',{attrs:{"layers":_vm.breadcrumbs,"text-color":"text-gray-900","styling":"flex lg:hidden"}}),_vm._v(" "),_c('aside',{staticClass:"blog-post__sidebar"},[_c('div',{staticClass:"sidebar-wrapper"},[(_vm.isIndexAvailable)?_c('div',{staticClass:"indice"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$util.lang.pages.blogArticle.index))]),_vm._v(" "),_c('ul',_vm._l((_vm.postIndex),function(item,key){return _c('li',{key:key,on:{"click":function($event){return _vm.gotoPostId(`#${item.id}`)}}},[_vm._v("\n                        "+_vm._s(item.text)+"\n                    ")])}),0)]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"hidden valorate lg:flex",on:{"click":_vm.makeVote}},[_c('img',{attrs:{"src":_vm.valorated ? _vm.$assets.primary.valorateFilled : _vm.$assets.primary.valorate,"alt":_vm.$util.lang.pages.blogArticle.valorate}}),_vm._v("\n                "+_vm._s(_vm.$util.lang.pages.blogArticle.valorate)+"\n            ")]),_vm._v(" "),_c('SidebarShare',{staticClass:"hidden lg:block",attrs:{"socials":{
                    facebook: true,
                    twitter: true,
                    messenger: true,
                    whatsapp: true,
                    copylink: true,
                    email: true,
                },"title":_vm.storedArticle.data.article.title}})],1)]),_vm._v(" "),_c('main',{staticClass:"main-post"},[_c('MicroBreadcrumbs',{attrs:{"layers":_vm.breadcrumbs,"text-color":"text-gray-900","styling":"hidden lg:flex"}}),_vm._v(" "),(_vm.storedArticle.data.article.title)?_c('h1',{staticClass:"main-post__title"},[_vm._v("\n            "+_vm._s(_vm.storedArticle.data.article.title)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.storedArticle.data.article.image)?_c('div',{staticClass:"main-post__image"},[_c('img',{attrs:{"src":_vm.storedArticle.data.article.image.url,"alt":_vm.storedArticle.data.article.title,"title":_vm.storedArticle.data.article.title}})]):_vm._e(),_vm._v(" "),_c('div',{ref:"post",staticClass:"main-post__content",attrs:{"id":"post"},domProps:{"innerHTML":_vm._s(_vm.removeXSS(_vm.storedArticle.data.article.content))}}),_vm._v(" "),_c('CardPostUserInfo',{staticClass:"block md:flex",attrs:{"post":_vm.storedArticle.data.article}}),_vm._v(" "),_c('button',{staticClass:"flex valorate lg:hidden",on:{"click":_vm.makeVote}},[_c('img',{attrs:{"src":_vm.valorated ? _vm.$assets.primary.valorateFilled : _vm.$assets.primary.valorate,"alt":_vm.$util.lang.pages.blogArticle.valorate}}),_vm._v("\n            "+_vm._s(_vm.$util.lang.pages.blogArticle.valorate)+"\n        ")]),_vm._v(" "),_c('SidebarShare',{staticClass:"block lg:hidden",attrs:{"socials":{
                facebook: true,
                twitter: true,
                messenger: false,
                whatsapp: true,
                copylink: true,
                email: true,
            },"title":_vm.storedArticle.data.article.title}}),_vm._v(" "),_c('div',{staticClass:"blog-posts-3"},[_c('div',{staticClass:"blog-posts-3__head"},[_c('h2',{staticClass:"blog-posts-3__head__title"},[_vm._v("\n                    "+_vm._s(_vm.$util.lang.pages.blogArticle.similar_articles)+"\n                ")])]),_vm._v(" "),_c('ul',{staticClass:"blog-posts-3__wrapper"},_vm._l((_vm.storedArticle.data.similar.slice(0, 3)),function(item,key){return _c('div',{key:key,staticClass:"post"},[_c('CardBlog',{attrs:{"post":item,"category":item.category ? item.category.slug : 'undefined'}})],1)}),0)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }