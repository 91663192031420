
import Vue, { PropType } from 'vue'
import { formatDistanceToNowStrict, parseISO } from 'date-fns'
import { pt as locale } from 'date-fns/locale'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'CardDashboardActivity',
    props: {
        info: Object as PropType<Models.Activity>,
    },
    data() {
        const timestamp = formatDistanceToNowStrict(parseISO(this.info.created_at || ''), { locale }).split(
            ' '
        )

        return {
            showDef: false,
            timestamp: timestamp[0] + ' ' + timestamp[1]?.charAt(0),
            buttonMap: {
                forum: {
                    text: this.$util.lang.components.cardDashboardActivity.see_forum,
                    linkPrefix: `/${this.$util.lang.routes.forum}/`,
                },
                offer: {
                    text: this.$util.lang.components.cardDashboardActivity.see_offer,
                    linkPrefix: `/${this.$util.lang.routes.offers}/`,
                },
                coupon: {
                    text: this.$util.lang.components.cardDashboardActivity.see_coupon,
                    linkPrefix: `/${this.$util.lang.routes.coupons}/`,
                },
            },
        }
    },
    computed: {
        defImage(): string {
            return this.info.properties.type === 'offer'
                ? this.$assets.illustration.dashOffer
                : this.info.properties.type === 'coupon'
                ? this.$assets.primary.dashCoupon
                : this.$assets.illustration.dashGift
        },
        srcImage(): string {
            return this.showDef ? this.defImage : this.info.properties.model.image || ''
        },
        buttonText(): string {
            return (
                this.buttonMap[this.info.properties.type]?.text ||
                this.$util.lang.components.cardDashboardActivity.unsupported
            )
        },
        linkUrl(): string {
            return (
                this.buttonMap[this.info.properties.type]?.linkPrefix + this.info.properties.model.slug ||
                this.$util.lang.components.cardDashboardActivity.unsupported
            )
        },
    },
})
