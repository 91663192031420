
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { currentDate } from '@/libs/date-functions.js'
import { Api } from '~/global'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'Search',
    async validate({ store, $api, query }) {
        if (!store.state.pages.search) {
            const searchResult = await $api.pages.loadSearch({ search: query.q })
            store.commit('pages/setValue', {
                name: 'search',
                page: searchResult,
            })
        }
        return true
    },
    data() {
        const searchResult = this.$store.state.pages.search as Api.Responses.Pages.SearchResults

        const { day, month, year } = currentDate()

        return {
            currentDate: day + ` ${this.$util.lang.pages.search.of} ` + month + ' ' + year,

            searchPage: searchResult.page,
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['title', `${this.$util.lang.pages.search.results_for} ` + this.$route.query.q + '"'],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', `${this.$util.lang.pages.search.results_for} ` + this.$route.query.q + '"'],
                    [
                        'og:image:alt',
                        `${this.$util.lang.pages.search.results_for} ` + this.$route.query.q + '"',
                    ],
                ],
                `${this.$util.lang.pages.search.results_for} ` + this.$route.query.q + '"'
            ),
        }
    },
    computed: {
        stores(): Models.Store | null {
            return this.$store.state.layout?.sidebar?.stores || null
        },
        notResults(): boolean {
            return this.searchPage.data.discounts.length === 0 && this.searchPage.data.stores.length === 0
        },
        searchResult(): Api.Responses.Pages.SearchResults['page'] {
            return this.$data.searchPage
        },
    },
    watch: {
        async '$route.query'() {
            try {
                const result = await this.$api.pages.loadSearch({
                    search: this.$route.query.q,
                })
                if (result.feedback === 'data_success') {
                    this.$data.searchPage = result.page
                }
            } catch (e) {}
        },
    },
})
