
import Vue, { PropType } from 'vue'

export default Vue.extend({
    name: 'FieldAvatarSelect',
    model: {
        event: 'onData',
        prop: 'file',
    },
    props: {
        optional: {
            type: Boolean,
        },
        file: {
            type: Object as PropType<{
                file: File
            }>,
        },
    },
    data() {
        return {
            selected: this.$store.state.user.profile.image?.url || (null as null | string),
        }
    },
    watch: {
        file: {
            deep: true,
            handler() {
                if (this.file) {
                    const reader = new FileReader()

                    reader.readAsDataURL(this.file.file)

                    reader.addEventListener('load', (e: FileReaderEventMap['load']) => {
                        this.selected = e.target?.result as string
                    })
                } else {
                    this.selected = null
                }
            },
        },
    },
    methods: {
        getPicture(e: InputEvent) {
            const ref = e.target as HTMLInputElement
            this.$emit(
                'onData',
                ref.files?.[0]
                    ? {
                          file: ref.files?.[0],
                      }
                    : null
            )
        },
        dispatchClick(): void {
            ;(this.$refs.input as HTMLInputElement).click()
        },
    },
})
