
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'ModalMessagesNewMessage',
    props: {
        info: {
            type: Object as PropType<{ refresh: (id: number | null) => void }>,
        },
    },
    data() {
        return {
            toInputHasFocus: false,
            timeout: null as null | NodeJS.Timeout,
            formData: {
                name: null as string | null,
                id: null as number | null,
                message: null as string | null,
            },
            users: [] as Models.User[],
            loading: false,
            loadingUsers: false,
            selected: false,
            validationStatus: {
                receiver_id: false as boolean | string,
                message: false as boolean | string,
            },
        }
    },
    computed: {
        showSuggests(): boolean {
            return this.toInputHasFocus
        },
    },
    async mounted() {
        this.loadingUsers = true

        try {
            const users = await this.$api.general.loadUsers({
                only_admins: true,
                limit: 15,
            })

            this.users = users.data.results
        } catch (e) {}

        this.loadingUsers = false
    },
    methods: {
        onChangeSearch() {
            this.timeout && clearTimeout(this.timeout)

            this.loadingUsers = true

            this.users = []

            this.timeout = setTimeout(async () => {
                try {
                    const users = await this.$api.general.loadUsers({
                        only_admins: true,
                        limit: 15,
                        search: this.formData.name || '',
                    })

                    if (users) {
                        this.users = users.data.results
                    } else {
                        this.$root.$emit('notification', {
                            duration: 3000,
                            dismissButtonText: 'Ocultar',
                            text: 'Ocurrió un error al obtener los usuarios',
                            type: 'Error',
                        })
                    }
                } catch (e) {}

                this.loadingUsers = false

                this.timeout = null
            }, 500)
        },
        includes() {
            return [(this.$refs.input as any).$el]
        },
        handleCloseSuggests(): void {
            this.toInputHasFocus = false
        },
        handleSelectUser(user: Models.User): void {
            this.selected = true
            this.formData.id = user.id
            this.formData.name = user.name
            this.toInputHasFocus = false
        },
        onCancel() {
            this.$emit('close')
        },
        async onSubmit() {
            this.loading = true

            const response = await this.$api.messages.createConversation({
                receiver_id: this.formData.id,
                message: this.formData.message,
            })

            if (response.feedback === 'data_success') {
                this.$emit('close')

                this.info.refresh(response.data.id)
            } else if (response.feedback === 'params_validation_failed') {
                this.validationStatus.receiver_id = response.data.receiver_id?.[0] || false
                this.validationStatus.message = response.data.message?.[0] || false
            } else {
                this.$root.$emit('notification', {
                    duration: 3000,
                    dismissButtonText: 'Ocultar',
                    text: 'No se pudo enviar el mensaje, por favor intentelo nuevamente en unos minutos',
                    type: 'error',
                })
            }

            this.loading = false
        },
    },
})
