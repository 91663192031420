
import Vue, { PropType } from 'vue'
import { parseISO, format } from 'date-fns'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'SidebarOfferSummary',
    props: {
        title: {
            type: String,
        },
        store: Object as PropType<Models.Store>,
        info: {
            type: Object as PropType<{
                discounts_active: number
                offers_count: number
                coupons_count: number
                discounts_updated: number
                last_updated: string
            }>,
        },
        model: {
            type: String,
        },
    },
    data() {
        return {
            images: [
                'https://cdn.worldvectorlogo.com/logos/visa.svg',
                'https://cdn.worldvectorlogo.com/logos/mastercard-2.svg',
                'https://cdn.worldvectorlogo.com/logos/american-express-1.svg',
            ],
            stars: [1, 2, 3, 4, 5],
            starsElements: null as null | HTMLCollection,
            userRating: null as null | number,
            userRatingNow: false,
            totalRatingStars: this.store.score || 0,
            totalRatingsCount: this.store.ratings_count || 0,
        }
    },
    computed: {
        lastUpdate(): string {
            return format(parseISO(this.info.last_updated), 'dd/MM/yyyy')
        },
        ratingFeedback(): string[] {
            if (this.userRating) {
                /* Há 10 votos e a média é de 3,2 */
                const feedback1 = `Há ${this.totalRatingsCount} ${
                    this.totalRatingsCount > 1 ? 'votos' : 'voto'
                } e a média é de ${this.totalRatingStars}`

                const feedback2 = `¡Obrigado pela sua avaliação!`

                return [feedback1, feedback2]
            } else if (this.store.score) {
                const feedback1 = `${this.store.ratings_count || 0} ${
                    (this.store.ratings_count || 0) > 1 ? 'utilizadores' : 'utilizador'
                } ${(this.store.ratings_count || 0) > 1 ? 'votaram' : 'votou'} e a média é de ${
                    this.totalRatingStars
                }`

                return [feedback1]
            } else {
                const feedback1 = `Dê um clique para se qualificar a ${this.store.name}`
                return [feedback1]
            }
        },
        starsCanBeStyledOnMouseEvents(): boolean {
            return !this.userRatingNow && !this.userRating
        },
    },
    mounted() {
        this.$forceUpdate()
        this.starsElements = (this.$refs.starsRatingContainer as HTMLDivElement).children

        this.resetStars()
    },
    methods: {
        resetStars() {
            if (this.store && this.store.score && this.starsElements) {
                let i = 0

                while (i < 5) {
                    const color = Math.round(this.store.score) < i + 1 ? '#eeeeee' : '#ecd500'

                    const firstChild = this.starsElements[i].firstChild as HTMLElement

                    if (firstChild) {
                        firstChild.setAttribute('fill', color)
                    }

                    i++
                }
            }
        },
        onMouseEnter(e: MouseEvent) {
            if (this.starsElements && this.starsCanBeStyledOnMouseEvents) {
                let targetIluminated = false

                let i = 0

                while (i < this.starsElements.length) {
                    const color = targetIluminated ? '#eeeeee' : '#ecd500'

                    const firstChild = this.starsElements[i]?.firstChild as HTMLElement

                    if (firstChild) {
                        firstChild.setAttribute('fill', color)
                    }

                    if (e.target === this.starsElements[i]) targetIluminated = true

                    i++
                }
            }
        },
        onMouseLeave() {
            if (this.starsCanBeStyledOnMouseEvents) {
                this.resetStars()
            }
        },
        async onRating(rating: number) {
            if (!this.userRatingNow && !this.userRating) {
                this.userRatingNow = true
                ;(this.$refs.starsRatingContainer as HTMLDivElement).style.cursor = 'wait'

                const ratingResult = await this.$api.general.ratingStore({
                    store_id: this.store.id,
                    grade: rating,
                })

                if (ratingResult?.feedback === 'data_success') {
                    this.userRating = ratingResult.data.new_score

                    this.totalRatingStars = ratingResult.data.new_score

                    this.totalRatingsCount = ratingResult.data.ratings_count

                    if (this.starsElements) {
                        let i = 0

                        while (i < 5) {
                            const color = this.totalRatingStars < i + 1 ? '#eeeeee' : '#ecd500'

                            const firstChild = this.starsElements[i].firstChild as HTMLElement

                            if (firstChild) {
                                firstChild.setAttribute('fill', color)
                            }

                            i++
                        }
                    }

                    this.$root.$emit('notification', {
                        type: 'success',
                        text: 'Gracias por darnos tu opinion de ' + this.store.name,
                    })
                }
                ;(this.$refs.starsRatingContainer as HTMLDivElement).style.cursor = 'auto'

                this.userRatingNow = false
            }
        },
    },
})
