
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'

export default Vue.extend({
    name: 'SingInWithoutPassword',
    middleware: ['ifNotAuthenticated'],
    validate({ redirect, store, $util }) {
        if (!store.state.auth.recoveryEmail) {
            redirect(302, { path: `/${$util.lang.routes.forgotPassword}` })
        }
        return true
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.inicioSinContrasena.title_seo
        const description = this.$util.lang.pages.inicioSinContrasena.description_seo
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
})
