
import Vue from 'vue'
import { formatDate } from '@/libs/date-functions.js'

export default Vue.extend({
    name: 'CardNews',
    props: {
        data: {
            type: Object,
        },
    },
    data() {
        return { formatDate }
    },
    computed: {
        content(): string {
            return this.data.content.replace(/<[^>]*>?/gm, ' ') || ''
        },
    },
})
