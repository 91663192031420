
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'
import { ServicesQueryParameters } from '~/global'

export default Vue.extend({
    name: 'CardCommentThread',
    props: {
        comment: {
            type: Object as PropType<Models.Comment>,
        },
    },
    data() {
        return {
            show: {
                loadComments: true,
            },
            disable: {
                loadComments: false,
            },
            nextPageQuery: {
                child_of: this.comment.id,
                per_page: 6,
                page: 1,
                order_by: 'latest',
            } as ServicesQueryParameters.GetComments,
            children: [] as Models.Comment[],
        }
    },
    watch: {
        'comment.id': {
            immediate: true,
            handler() {
                this.children = this.comment.comments ? [...this.comment.comments].reverse() : []
            },
        },
    },
    methods: {
        async loadComments() {
            this.disable.loadComments = true

            const commentsResult = await this.$api.general.loadComments(this.nextPageQuery)

            if (commentsResult.feedback === 'data_success') {
                if (commentsResult.data.meta.current_page === 1) {
                    this.$set(this, 'children', [...commentsResult.data.results].reverse())
                } else {
                    this.$set(this, 'children', [
                        ...this.children,
                        ...[...commentsResult.data.results].reverse(),
                    ])
                }

                if (commentsResult.data.meta.current_page < commentsResult.data.meta.last_page) {
                    this.nextPageQuery.page = commentsResult.data.meta.current_page + 1
                } else {
                    this.show.loadComments = false
                }
            }
            this.disable.loadComments = false
        },
        concatReply(newChild: Models.Comment) {
            this.children = [...this.children, newChild]
            this.$nextTick(() => {
                this.$root.$emit('active-comment-pulses', newChild.id)
            })
        },
    },
})
