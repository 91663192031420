
import Vue from 'vue'

export default Vue.extend({
    name: 'FormFieldToggle',
    props: {
        value: Boolean,
        label: String,
        id: String,
    },
})
