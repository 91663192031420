
import Vue from 'vue'

export default Vue.extend({
    name: 'DropdownMenu',
    props: {
        links: {
            type: Array as () => Array<{
                path: string
                title: string
                icon: string
                style: string
            }>,
            required: true,
            default: () => [],
        },
        width: {
            type: String,
            default: '',
        },
    },
})
