
import Vue from 'vue'

export default Vue.extend({
    name: 'MicroUserNoImage',
    props: {
        name: {
            type: String,
            default: '?',
            required: true,
        },
        expire: {
            type: Boolean,
            default: false,
        },
        badge: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        nameFirstChar(): string {
            if (this.name) return this.name?.split('')[0]
            else return '?'
        },
        hexCode(): string {
            return this.expire ? 'bg-[#94949B]' : 'bg-[#0086E8]'
        },
    },
})
