
import Vue from 'vue'
import { defaultImage } from '~/libs/useful-functions'

export default Vue.extend({
    name: 'ChipCategory',
    props: {
        info: {
            type: Object,
            required: true,
        },
        path: {
            type: String,
        },
    },
    data() {
        return { defaultImage }
    },
    computed: {
        isActive(): boolean {
            return this.info.slug === this.$route.params.slug
        },
    },
})
