
import Vue from 'vue'

export default Vue.extend({
    name: 'LayoutMenu',
    props: {
        menu: Array,
    },
    data() {
        return {
            mountMenu: false,
            mountWrapper: false,
        }
    },
    watch: {
        menu: {
            immediate: true,
            handler(menu: object | null) {
                !!menu && this.mount(true)
            },
        },
        $route: {
            deep: true,
            handler() {
                this.menu && this.mount(false)
            },
        },
    },
    methods: {
        mount(mount: boolean) {
            if (mount) {
                this.mountMenu = mount

                setTimeout(() => (this.mountWrapper = mount), 100)
            } else {
                this.mountWrapper = mount

                setTimeout(() => {
                    this.mountMenu = mount
                }, 100)

                setTimeout(() => this.$store.commit('setDropdown', null), 210)
            }
        },
    },
})
