
import Vue from 'vue'

export default Vue.extend({
    props: {
        title: {
            type: String,
        },
        icon: {
            type: String,
            default: null,
        },
        fasIcon: {
            type: String,
            default: null,
        },
        goback: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        goBack(): void {
            this.$router.push(
                `/${this.$util.lang.routes.dashboard}${
                    this.$route.params.user ? '/' + this.$route.params.user : ''
                }`
            )
        },
    },
})
