
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'

type sitemapCategory = {
    children: sitemapCategory[]
    name: string
    slug: string
}

export default Vue.extend({
    name: 'SitemapCategories',
    async asyncData({ $api }) {
        const sitemap = await $api.pages.loadSitemap('categories')

        return {
            sitemap,
        }
    },
    data() {
        return {
            sitemap: [] as sitemapCategory[],
            metaInfo: {
                title: 'Mapa del sitio - MEGAdescuentos',
                description:
                    'Mapa del sitio de MEGAdescuentos. Consulta categorías, tiendas, cupones, ofertas y promociones de forma ordenada y accesible.',
            },
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                ],
                this.metaInfo?.title
            ),
        }
    },
})
