
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { Api } from '~/global'

declare global {
    interface Window {
        CFdataCallback: any
        CFdataExpiredCallback: any
    }
}

export default Vue.extend({
    name: 'Contact',
    async asyncData({ $api }) {
        const home = await $api.info.loadContact()
        return {
            info: home.page,
        }
    },
    data() {
        return {
            info: {} as Api.Responses.Info.Basic['page'],
            loading: false,
            elementsError: [] as Array<any>,
            cfValidToken: null as null | boolean,
            form: {
                name: '',
                title: '',
                email: '',
                message: '',
            },
            validations: {
                name: false as boolean | string,
                title: false as boolean | string,
                subject: false as boolean | string,
                email: false as boolean | string,
                message: false as boolean | string,
            },
            errorFields: [],
            dropdown: {
                show: {
                    subject: false,
                },
                subject: {
                    ready: true,
                    selected: { name: this.$util.lang.pages.contacto.why_contact, id: 0 },
                    data: [
                        {
                            id: 1,
                            name: this.$util.lang.pages.contacto.share_offer,
                        },
                        {
                            id: 2,
                            name: this.$util.lang.pages.contacto.account,
                        },
                        {
                            id: 3,
                            name: this.$util.lang.pages.contacto.comercial,
                        },
                        {
                            id: 4,
                            name: this.$util.lang.pages.contacto.suggests,
                        },
                        {
                            id: 5,
                            name: this.$util.lang.pages.contacto.complaints_legal,
                        },
                        {
                            id: 6,
                            name: this.$util.lang.pages.contacto.others,
                        },
                    ],
                },
            },
        }
    },
    beforeMount() {
        window.CFdataCallback = (data: string) => {
            if (data && data.length) {
                this.cfValidToken = true
            } else {
                this.cfValidToken = false
            }
        }
        window.CFdataExpiredCallback = () => {
            this.cfValidToken = false
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            script: [
                {
                    hid: 'cloudflare-captcha-head',
                    type: 'text/javascript',
                    async: true,
                    defer: true,
                    src: 'https://challenges.cloudflare.com/turnstile/v0/api.js',
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.info.description],
                    ['title', this.info.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.info.title],
                    ['og:image:alt', this.info.title],
                    ['og:description', this.info.description],
                ],
                this.info.title
            ),
        }
    },
    computed: {
        isFormFullfilled(): boolean {
            return (
                !!this.form.name.length &&
                !!this.form.title.length &&
                this.dropdown.subject.selected.name !== '¿Sobre qué asunto nos contactas?' &&
                !!this.form.email.length &&
                !!this.form.message.length &&
                this.form.message !== '<p><br></p>'
            )
        },
    },
    methods: {
        dataExpiredCallback(data: any) {
            console.log(data)
        },
        dataCallback(data: any) {
            console.log(data)
        },
        async onSubmit() {
            if (this.cfValidToken) {
                this.loading = true

                // Creamos el nuevo formData
                const bodyFormData = new FormData()

                bodyFormData.append('name', this.form.name)
                bodyFormData.append('title', this.form.title)
                bodyFormData.append('subject', this.dropdown.subject.selected.name)
                bodyFormData.append('email', this.form.email)
                bodyFormData.append('message', this.form.message)

                const response = await this.$api.info.postContact(bodyFormData)

                if (!response) {
                    this.$root.$emit('notification', {
                        duration: 5000,
                        text: this.$util.lang.pages.contacto.message_error,
                        type: 'error',
                    })
                } else if (response.feedback === 'params_validation_failed') {
                    this.validations.name = response.data.name?.[0] || false
                    this.validations.title = response.data.title?.[0] || false
                    this.validations.subject = response.data.subject?.[0] || false
                    this.validations.email = response.data.email?.[0] || false
                    this.validations.message = response.data.message?.[0] || false

                    try {
                        this.$scrollTo(`#${Object.keys(response.data)[0]}`)
                    } catch (error) {
                        this.$scrollTo('#title')
                    }
                } else if (response.feedback === 'mail_sent') {
                    this.$root.$emit('notification', {
                        duration: 5000,
                        text: this.$util.lang.pages.contacto.message_success,
                        type: 'success',
                    })
                    this.$router.push({
                        path: `/`,
                    })
                }
                this.loading = false
            } else {
            }
        },
        dropdownToggle(show: boolean, name: string): void {
            this.dropdown.show[name as 'subject'] = show
        },
    },
})
