
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { get } from 'js-cookie'
import { cookies } from '@/config/constants'
import { Models } from '~/types/models'
import { variantConfig } from '~/config/variant'
/* import { cookies as cookiesKeys } from '@/config/constants' */
declare global {
    interface Window {
        Cookiebot: any
    }
}

export default Vue.extend({
    name: 'LayoutDefault',
    data() {
        return {
            mode: '',
            mounted: false,
            /*  showCookiesBanner: false, */
        }
    },
    head() {
        const allowMarketingCookies = this.$store.state.allowMarketingCookies
        const adbarsDesktop = this.layoutInfo?.header.adbar?.desktop
        const adbarsMobile = this.layoutInfo?.header.adbar?.mobile

        if (allowMarketingCookies) {
            return {
                bodyAttrs: {
                    class:
                        (this.layoutInfo?.header.adbar ? 'add-adbars-height' : '') +
                        (adbarsDesktop ? ' show-desktop-adbars' : '') +
                        (adbarsMobile ? ' show-mobile-adbars' : ''),
                },
                script: [
                    {
                        hid: 'gtag-src-head',
                        type: 'text/javascript',
                        async: true,
                        defer: true,
                        src: 'https://www.googletagmanager.com/gtag/js?id=G-LDB3WB60H3',
                    },
                    {
                        hid: 'gtag-head',
                        type: 'text/javascript',
                        charset: 'utf-8',
                        body: false,
                        innerHTML: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-LDB3WB60H3'); `,
                    },
                    {
                        hid: 'gtm-head',
                        charset: 'utf-8',
                        type: 'text/javascript',
                        innerHTML: `setTimeout(function() {(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${variantConfig.GTMKey}');}, 6000)`,
                        body: false,
                    },
                    {
                        hid: 'digidip-head',
                        charset: 'utf-8',
                        type: 'text/javascript',
                        innerHTML: `(function(){
                   var loc = window.location.href;
                   var dd = document.createElement('script');
                   dd.type = 'text/javascript'; dd.src = '//static.digidip.net/okdescontoscombr.js?loc=' + loc;
                   var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(dd, s);
                 })();`,
                        body: false,
                    },
                ],
                noscript: [
                    {
                        hid: 'gtm-body',
                        innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${variantConfig.GTMKey}" height="0" width="0" style="display: none; visibility: hidden"></iframe>`,
                        body: true,
                    },
                ],
                __dangerouslyDisableSanitizersByTagID: {
                    'gtm-head': ['innerHTML'],
                    'gtag-head': ['innerHTML'],
                    'digidip-head': ['innerHTML'],
                    'gtm-body': ['innerHTML'],
                },
            }
        }
        return {
            bodyAttrs: {
                class:
                    (this.layoutInfo?.header.adbar ? 'add-adbars-height' : '') +
                    (adbarsDesktop ? ' show-desktop-adbars' : '') +
                    (adbarsMobile ? ' show-mobile-adbars' : ''),
            },
        }
    },
    computed: {
        ...mapGetters({
            layoutInfo: 'getLayout',
            modal: 'getModal',
            modalSecondary: 'getModalSecondary',
            banners: 'getBanners',
            menu: 'getDropdown',
            /* isAuth: 'auth/isAuth', */
        }),
        showFloatingResume(): boolean {
            return (
                !!this.discount &&
                /^(ofertas|cupones)-slug$/.test(this.$route.name as string) &&
                /^(coupon|offer|offer-code)$/.test(this.mode)
            )
        },
        discount(): Models.Discount | null {
            return this.$store.state.pages.discount?.offer || this.$store.state.pages.discount?.coupon
        },
    },
    watch: {
        discount: {
            deep: true,
            immediate: true,
            handler(discount: Models.Discount | null) {
                if (discount) {
                    if (discount.type === 'offer') {
                        if (discount.has_url) {
                            if (discount.code) {
                                this.mode = 'offer-code'
                            } else {
                                this.mode = 'offer'
                            }
                        } else {
                            this.mode = 'no-link-offer'
                        }
                    } else if (discount.type === 'coupon') {
                        if (discount.has_url) {
                            this.mode = 'coupon'
                        } else {
                            this.mode = 'no-link-coupon'
                        }
                    }
                }
            },
        },
        '$route.path'() {
            this.$nextTick(() => this.$store.state.auth.nextNavigationHandler?.())
        },
        isAuth: {
            handler(newValue) {
                if (process.client && newValue) {
                    const ls = localStorage.getItem('action')
                    if (ls) {
                        this.$router.push({
                            name: 'preferences',
                            query: { action: ls },
                            hash: '#notificaciones',
                        })
                    }
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.$nextTick(() => {
            /*    setInterval(() => {
                this.getNotifications()
            }, 60000) */

            this.mounted = true

            /*   this.getNotifications() */

            if (window.Cookiebot && !(window.Cookiebot.consented || window.Cookiebot.declined)) {
                const handleEvent = () => {
                    const cookiesConfig = {
                        [cookies.cookies_bot_config.strict_necessary]: window.Cookiebot.consent.necessary,
                        [cookies.cookies_bot_config.preferences]: window.Cookiebot.consent.preferences,
                        [cookies.cookies_bot_config.statistics]: window.Cookiebot.consent.statistics,
                        [cookies.cookies_bot_config.marketing]: window.Cookiebot.consent.marketing,
                    }

                    localStorage.setItem('cookies_bot_config', JSON.stringify(cookiesConfig))

                    this.$cookies.set(
                        cookies.cookies_bot_config.marketing,
                        window.Cookiebot.consent.marketing,
                        {
                            path: '/',
                            maxAge: 60 * 60 * 24 * 365,
                        }
                    )

                    this.$store.commit('setAllowMarketingCookies', window.Cookiebot.consent.marketing)
                }

                window.addEventListener('CookiebotOnAccept', handleEvent, false)
                window.addEventListener('CookiebotOnDecline', handleEvent, false)
            }
            11
        })
    },
    methods: {},
})
