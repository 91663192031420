
import Vue, { PropType } from 'vue'

import { format, parseISO } from 'date-fns'

import { es as locale } from 'date-fns/locale'

import { Models } from '~/types/models'

export default Vue.extend({
    name: 'CardStoreArticle',
    props: {
        article: {
            type: Object as PropType<Models.ArticleModel>,
        },
    },
    computed: {
        date(): string {
            return format(parseISO(this.article.created_at), "dd 'de' MMMM 'del' yyyy", { locale })
        },
    },
})
