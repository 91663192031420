
import Vue from 'vue'

export default Vue.extend({
    name: 'DropdownConfirmAction',
    props: {
        classes: {
            type: String,
            default: '',
        },
        info: {
            type: Object as () => {
                text: string
            },
        },
    },
    methods: {
        onSubmit() {
            this.$root.$emit('notification', {
                duration: 3000,
                dismissButtonText: this.$util.lang.components.dropdownConfirmAction.hide,
                text: this.$util.lang.components.dropdownConfirmAction.expired_success,
                type: 'success',
            })
        },
    },
})
