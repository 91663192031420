
import Vue from 'vue'

export default Vue.extend({
    name: 'DropdownDetailsCommentOptions',
    props: {
        options: {
            type: Array as () => Array<{
                title: string
                icon: string
                style: string
                handler: () => void
            }>,
            required: true,
            default: () => [],
        },
        classes: {
            type: String,
            default: '',
        },
    },
    methods: {},
})
