
import Vue, { PropOptions } from 'vue'

export default Vue.extend({
    name: 'FilterTabs',
    props: {
        mode: String,
        active: Number,
        updateFromOutside: Boolean,
        tabs: {
            type: Array,
        } as PropOptions<
            {
                name: string
                icon: string
            }[]
        >,
    },
    data() {
        return {
            activeIndex: this.active || 0,
        }
    },
    watch: {
        active(index: number) {
            this.activeIndex = index
        },
    },
    methods: {
        onTab(index: number) {
            this.$emit('tab', this.tabs[index])

            !this.updateFromOutside && (this.activeIndex = index)
        },
    },
})
