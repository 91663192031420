
import Vue from 'vue'
/* import { parseISO, isBefore, addMonths } from 'date-fns'
import { Api } from '~/global'

type shortLink = {
    data: {
        shortlink: string
    }
}

type storedDiscountMask = Api.Responses.Pages.DiscountDetails & shortLink

interface Data {
    counter: number
    interval?: number
    storedDiscountMask: storedDiscountMask['data']
    copyCalled: boolean
} */

export default Vue.extend({
    name: 'DiscountMask',
    layout: 'none',
    async validate({ params, $api, redirect, route, error, /* store, */ $util }) {
        let url = ''

        if (route.name === 'go-id') {
            if (params.id.includes('-direct-')) {
                const newurl = params.id.replace('-direct-', '')
                redirect(301, newurl)
            }
            url = await $api.general.loadOfferShortLink(params.id)

            redirect(301, url)
        }

        if (route.name === 'open-slug') {
            try {
                const result = await $api.general.useCoupon(params.slug)

                if (result.feedback === 'data_success') {
                    url = await $api.general.loadCouponShortLink(result.data.coupon.id)

                    redirect(301, url)
                    /*  if (url) {
                        store.commit('pages/setValue', {
                            name: 'discountMask',
                            page: { shortlink: url, ...result.data },
                        })
                    } */
                } else {
                    /* error({
                        statusCode: 404,
                        message: $util.lang.views.discountMask.unexpected_error,
                    }) */
                }
            } catch (e) {
                error({
                    statusCode: 500,
                    message: $util.lang.views.discountMask.error_obtaining_coupon,
                })
            }
        }

        return true
    },
    /* data(): Data {
        return {
            counter: 5,
            interval: undefined,
            storedDiscountMask: this.$store.state.pages.discountMask,
            copyCalled: false,
        }
    },
    computed: {
        expired(): boolean {
            const parseExpiredAt =
                this.storedDiscountMask.coupon.expired_at &&
                parseISO(this.storedDiscountMask.coupon.expired_at)
            const parseCreatedAt = parseISO(this.storedDiscountMask.coupon.created_at)

            return (
                (!!parseExpiredAt && isBefore(parseExpiredAt, new Date())) ||
                isBefore(addMonths(parseCreatedAt, 3), new Date())
            )
        },
        code(): string {
            return this.storedDiscountMask?.coupon?.code
                ? this.storedDiscountMask.coupon.code
                : this.$util.lang.views.discountMask.no_coupon_required
        },
        store(): string | null {
            return this.storedDiscountMask?.coupon?.store &&
                this.storedDiscountMask?.coupon?.store.name !== 'Sin tienda asignada'
                ? ' a ' + this.storedDiscountMask.coupon.store.name
                : null
        },
        gotoButtonText(): string {
            return this.store
                ? this.$util.lang.views.discountMask.go_to + this.store
                : this.$util.lang.views.discountMask.go_store
        },
        isIOS(): boolean {
            return false
        },
    },
    mounted() {
        if (document.visibilityState === 'visible') {
            this.copyUsingAllMethods()
        } else {
            addEventListener(
                'visibilitychange',
                () => {
                    if (document.visibilityState === 'visible') {
                        this.copyUsingAllMethods()
                    }
                },
                { once: true }
            )
        }

        this.interval = window.setInterval(() => {
            if (this.counter > 0) {
                this.counter--
            } else {
                try {
                    clearInterval(this.interval)
                    window.location.href = this.storedDiscountMask?.shortlink
                } catch (error) {
                    console.log(error)
                }
            }
        }, 1000)
    },
    beforeDestroy() {
        if (this.interval) {
            window.clearInterval(this.interval)
            this.interval = undefined
        }
    },
    methods: {
        copyUsingTempSelection() {
            const tempSelection = document.getSelection()
            const range = document.createRange()
            const referenceNode = document.getElementsByTagName('input')[0]
            referenceNode.setAttribute('style', '-webkit-user-select: text !important')
            referenceNode.setAttribute('readonly', 'false')
            referenceNode.setAttribute('contentEditable', 'true')
            range.selectNodeContents(referenceNode)
            if (tempSelection) {
                tempSelection.removeAllRanges()
                tempSelection.addRange(range)
                document.execCommand('copy')
                tempSelection.removeAllRanges()
            }
        },
        copyUsingRegularEXEC() {
            const textArea = document.createElement('textarea')
            textArea.value = this.code
            textArea.setAttribute('readonly', 'false')
            textArea.setAttribute('contentEditable', 'true')
            document.body.appendChild(textArea)
            textArea.select()
            document.execCommand('copy')
            document.body.removeChild(textArea)
        },
        copyTextUsingDOM(text: string) {
            try {
                const t = document.createElement('div')
                t.setAttribute('style', '-webkit-user-select: text !important')
                const n = t
                t.attachShadow({ mode: 'open' })
                if (t.shadowRoot) {
                    t.shadowRoot.innerHTML = `<textarea style="position: absolute; top: -9999px; left: -9999px;">${text}</textarea>`
                    document.body.appendChild(t)
                    if (n && n.shadowRoot && n.shadowRoot.querySelector('textarea')) {
                        n.shadowRoot.querySelector('textarea')!.select()
                        document.execCommand('copy')
                        document.body.removeChild(t)
                    }
                }
            } catch (e) {
                console.log(e)
            }
        },
        copyCreatingTextAreaElement() {
            const text = this.code
            const el = document.createElement('textarea')
            el.value = text
            el.setAttribute('readonly', 'false')
            el.setAttribute('contentEditable', 'true')
            el.style.position = 'absolute'
            el.style.left = '-9999px'
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)
        },
        copyCreatingInputElement() {
            const text = this.code
            const el = document.createElement('input')
            el.value = text
            el.setAttribute('readonly', 'false')
            el.setAttribute('contentEditable', 'true')
            el.style.position = 'absolute'
            el.style.left = '-9999px'
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)
        },
        copyWriteTextWithPermissions() {
            if (navigator && navigator.clipboard && navigator.permissions) {
                navigator.permissions
                    .query({ name: 'clipboard-write' as PermissionName })
                    .then(({ state }) => {
                        if (state === 'granted') {
                            try {
                                navigator.clipboard.writeText(this.code).then(() => {
                                    if (this.isIOS) {
                                        window.location.href = this.storedDiscountMask?.shortlink
                                    }
                                })
                            } catch (e) {
                                console.log(e)
                            }
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            }
        },
        copyUsingAllMethods() {
            if (!this.copyCalled) {
                this.copyCalled = true
                setTimeout(() => {
                    this.copyWriteTextWithPermissions()
                    this.copyCreatingTextAreaElement()
                    this.copyCreatingInputElement()
                    this.copyUsingTempSelection()
                    this.copyTextUsingDOM(this.code)
                    this.$copyText(this.code)
                }, 750)
            }
        },
    }, */
})
