
import Vue, { PropOptions } from 'vue'
import { Models } from '@/types/models'
import { formatDatAndMonth } from '@/libs/date-functions'

export default Vue.extend({
    name: 'ListThreads',
    props: {
        threadList: Array as PropOptions<Models.Forum[]>,
    },
    methods: {
        formatDatAndMonth,
        changeCategory(name: string) {
            this.$emit('changeCategory', name)
        },
    },
})
