
import Vue from 'vue'

export default Vue.extend({
    name: 'ChipGallery',
    props: {
        info: {
            type: Object,
            default: () => {},
        },
        path: {
            type: String,
        },
    },
    computed: {
        isActive(): boolean {
            return this.info.slug === this.$route.params.slug
        },
    },
})
