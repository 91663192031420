
import Vue from 'vue'

export default Vue.extend({
    name: 'DropdownDefault',
    props: {
        options: {
            type: Array as () => Array<string>,
            default: () => [],
        },
        classes: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        handleClose: {
            type: Function,
        },
    },
    methods: {
        emitChangeSelected(newSelected: any) {
            this.$emit('changeSelected', newSelected)
            this.handleClose()
        },
    },
})
