
import Vue from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'CardOtherCountries',

    props: {
        countries: {
            type: Array as () => Models.CountryFlag[],
            required: true,
        },
    },
})
