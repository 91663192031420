
import Vue, { PropOptions } from 'vue'

export default Vue.extend({
    name: 'ModalAuthAcceptTerms',
    props: {
        info: Object as PropOptions<{
            message: string
        }>,
    },
    data() {
        return {
            form: {
                data: {
                    accepted: false,
                },
                validationStatus: {
                    accepted: true,
                },
            },
        }
    },
    methods: {
        async acceptTerms() {
            this.form.validationStatus.accepted = this.form.data.accepted

            if (this.form.validationStatus.accepted) {
                this.$nuxt.$loading.start()

                const result = await this.$api.user.acceptTerms(this.$store.state.user.profile.id)

                this.$emit('close')

                if (result.feedback === 'data_updated_success') {
                    this.$store.commit('user/setProfile', {
                        ...this.$store.state.user.profile,
                        accepted_conditions_at: new Date().toISOString(),
                    })
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.components.modalAuthAcceptTerms.hide,
                        text: this.$util.lang.components.modalAuthAcceptTerms.can_interact,
                        type: 'success',
                    })
                } else if (/(token_expired|token_invalid|token_not_found)/.test(result.feedback)) {
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.components.modalAuthAcceptTerms.hide,
                        text: this.$util.lang.components.modalAuthAcceptTerms.login_to_verify,
                        type: 'error',
                    })
                } else {
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.components.modalAuthAcceptTerms.hide,
                        text: this.$util.lang.components.modalAuthAcceptTerms.not_valid,
                        type: 'error',
                    })
                }

                this.$nuxt.$loading.finish()
            }
        },
    },
})
