
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'CardFaq',
    props: {
        title: {
            type: String,
            default: 'Question',
        },
        icon: {
            type: String,
            default: 'Question',
        },
        toggle: Object as PropType<{
            index: number | null
            value: boolean
        }>,
        questions: {
            type: Array as PropType<Models.Faq[]>,
        },
    },
    data() {
        return {
            show: [] as boolean[],
        }
    },
    watch: {
        toggle: {
            deep: true,
            handler(toggle: { index?: number; active: boolean }) {
                if (typeof toggle.index === 'number') {
                    Vue.set(this.show, toggle.index, toggle.active)
                    this.show.forEach((...params) => {
                        if (params[1] !== toggle.index) {
                            Vue.set(this.show, params[1], false)
                        }
                    })
                }
            },
        },
    },
    methods: {
        switchDescription(key: any) {
            Vue.set(this.show, key, !this.show[key])
        },
    },
})
