
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { Api } from '~/global'

export default Vue.extend({
    name: 'Login',
    middleware: ['ifNotAuthenticated'],
    async asyncData({ $api }) {
        const info = await $api.pages.loadLogin()
        return { seo: info.page }
    },
    data() {
        return {
            seo: {} as Api.Responses.Pages.Login['page'],
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.seo.description],
                    ['title', this.seo.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.seo.title],
                    ['og:image:alt', this.seo.title],
                    ['og:description', this.seo.description],
                ],
                this.seo.title
            ),
        }
    },
})
