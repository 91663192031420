
import Vue from 'vue'

export default Vue.extend({
    name: 'LayoutModal',
    props: {
        modal: Object,
    },
    data() {
        return {
            mountModal: false,
        }
    },
    computed: {
        fullpage(): boolean {
            return this.modal.fullpage || false
        },
    },
    watch: {
        modal: {
            immediate: true,
            handler(modal: object | null) {
                !!modal && this.mount(true)
            },
        },
        $route: {
            deep: true,
            handler() {
                this.modal && this.mount(false)
            },
        },
    },
    methods: {
        mount(mount: boolean) {
            if (mount && this.modal) {
                this.mountModal = mount

                this.$nextTick(() => {
                    const elm = this.$refs.modal as HTMLDivElement | undefined

                    let topPos = ''

                    if (this.$bp.mb || this.$bp.sm || this.$bp.xs || this.$bp.md) {
                        topPos = scrollY + 'px'
                    } else {
                        topPos = this.fullpage ? '0px' : scrollY + window.innerHeight * 0.5 + 'px'
                    }

                    elm && (elm.style.top = topPos)
                })

                this.modal?.onOpen && this.modal.onOpen()
            } else {
                this.mountModal = mount

                if (this.modal !== null && 'onClose' in this.modal) this.modal.onClose()

                this.$nextTick(() => {
                    this.$store.commit('setModal', null)
                })
            }
        },
    },
})
