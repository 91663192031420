
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'
import { defaultImage } from '~/libs/useful-functions'

export default Vue.extend({
    name: 'SidebarCategoriesTwo',
    props: {
        path: {
            type: String,
            required: true,
        },
        info: {
            type: Array as PropType<Models.Category[]>,
        },
        title: {
            type: String,
        },
        model: {
            type: String as PropType<'secondary'>,
            default: 'primary',
        },
        border: {
            type: Boolean,
            default: true,
        },
        iconColor: {
            type: String as PropType<'primary'>,
            default: 'primary',
        },
        iconName: {
            type: String as PropType<'shop'>,
            default: 'shop',
        },
    },
    data() {
        return {
            defaultImage,
        }
    },
    computed: {
        icon(): object {
            // @ts-ignore
            return this.$assets[this.iconColor][this.iconName]
        },
    },
})
