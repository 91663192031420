
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'

export default Vue.extend({
    name: 'PasswordResetSuccess',
    middleware: ['ifNotAuthenticated'],
    data() {
        return {
            countdown: 5,
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.contrasenaActualizadaExitosamente.title_seo
        const description = this.$util.lang.pages.contrasenaActualizadaExitosamente.description_seo
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
    mounted() {
        const timer = setInterval(() => {
            this.countdown--
            if (this.countdown === 0) {
                clearInterval(timer)

                this.$router.push({ name: 'login' })
            }
        }, 1000)
    },
})
