
import Vue, { PropType } from 'vue'
import { getDictionary } from '~/libs/variant-dict'

export default Vue.extend({
    name: 'CardSharePreviewOfferAndCoupon',
    props: {
        carddata: {
            type: Object,
            default: () => {},
        },
        buttonType: {
            type: String as PropType<'coupon' | 'offer' | 'offer-code' | 'no-link-offer'>,
            default: '',
        },
    },
    data() {
        const type = getDictionary(this.carddata.kind)
        return {
            type,
            dropdown: {
                show: {
                    info: false,
                },
            },
        }
    },
    computed: {
        content(): string {
            return this.carddata.formData.description.replace(/<[^>]*>?/gm, ' ') || ''
        },
        defaultImage(): string {
            return this.carddata.kind === 'offer'
                ? this.$assets.illustration.share.newoffer
                : this.$assets.illustration.share.newcoupon
        },
        priceOffer(): string {
            const price = this.carddata.formData.offer_details.price_offer
            if (price === '0') {
                return this.$util.lang.components.cardSharePreviewOfferAndCoupon.free
            } else {
                return `$${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
            }
        },
        priceRegular(): string {
            const price = this.carddata.formData.offer_details.regular_price
            if (price === '0') {
                return this.$util.lang.components.cardSharePreviewOfferAndCoupon.free
            } else {
                return `$${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
            }
        },
        pricePercent(): string {
            const formula = (
                (this.carddata.formData.offer_details.price_offer * 100) /
                this.carddata.formData.offer_details.regular_price
            ).toFixed(0)
            const percent = 100 - parseInt(formula)
            if (isNaN(percent)) {
                return ''
            } else if (
                this.carddata.formData.offer_details.price_offer === '0' ||
                this.carddata.formData.offer_details.regular_price === '0'
            ) {
                return ''
            }
            return ` - ${percent}%`
        },
    },
    methods: {
        dropdownToggle(show: boolean, name: string): void {
            this.dropdown.show[name as 'info'] = show
        },
    },
})
