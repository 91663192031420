
import Vue from 'vue'
import encodeUrl from 'encodeurl'
import { MetaInfo } from 'vue-meta/types'
import {
    parseISO,
    format,
    isToday,
    isTomorrow,
    addDays,
    subDays,
    isBefore,
    formatDistanceToNowStrict,
    addMonths,
} from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { pt as locale } from 'date-fns/locale'
import { Api, ServicesQueryParameters } from '~/global'
import { getDictionary } from '~/libs/variant-dict'
import { DropdownOptionType } from '~/components/dropdown/Options.vue'
import { Models } from '~/types/models'
import { currentDate } from '~/libs/date-functions'
import { removeXSS, defaultImage } from '~/libs/useful-functions'

export default Vue.extend({
    name: 'DiscountDetails',
    async validate({ store, $api, $util, params: { slug }, route, error }) {
        const routeHandler =
            route.name === 'ofertas-slug'
                ? $api.pages.loadOffer
                : route.name === 'cupones-slug'
                ? $api.pages.loadCoupon
                : false

        if (routeHandler) {
            const result = await routeHandler(slug)

            if (result?.feedback === 'data_success') {
                store.commit('pages/setValue', {
                    name: 'discount',
                    page: result.data,
                })
            } else if (result?.feedback === 'resource_not_found') {
                error({
                    statusCode: 404,
                    message: $util.lang.views.discountDetails.no_offer_found,
                })
                return false
            } else {
                error({
                    statusCode: 404,
                    message: $util.lang.views.discountDetails.unexpected_error,
                })
            }
        }
        return true
    },
    data() {
        const storedPage = this.$store.state.pages.discount as Api.Responses.Pages.DiscountDetails['data']

        const discount = storedPage.coupon || storedPage.offer

        const isCoupon = !!storedPage.coupon

        const isOffer = !!storedPage.offer

        const isExclusive = discount.is_exclusive

        const isVerified = discount.is_verified

        const type = discount.type

        const variant = getDictionary(type)

        const parsedDates = {
            memberSince: discount.user?.created_at && parseISO(discount.user.created_at),
            expiredAt: discount.expired_at && parseISO(discount.expired_at),
            updatedAt: discount.updated_at && parseISO(discount.updated_at),
            createdAt: parseISO(discount.created_at),
            startAt: discount.start_at && parseISO(discount.start_at),
        }

        const dates = {
            endSoon: '',

            updatedDate:
                parsedDates.updatedAt &&
                formatInTimeZone(parsedDates.updatedAt, 'America/Sao_Paulo', 'dd/MM/yyyy', { locale }),

            endingDate:
                parsedDates.expiredAt &&
                formatInTimeZone(parsedDates.expiredAt, 'America/Sao_Paulo', 'dd/MM/yyyy HH:mm', { locale }),

            startingDate:
                parsedDates.startAt &&
                parsedDates.startAt > new Date() &&
                formatInTimeZone(parsedDates.startAt, 'America/Sao_Paulo', 'dd/MM/yyyy HH:mm', { locale }),

            createTimeAgo: formatDistanceToNowStrict(parsedDates.createdAt, { locale }),

            updateTimeAgo:
                parsedDates.updatedAt && formatDistanceToNowStrict(parsedDates.updatedAt, { locale }),

            isExpired:
                !discount.never_expires &&
                ((!!parsedDates.expiredAt && isBefore(parsedDates.expiredAt, new Date())) ||
                    (!!parsedDates.updatedAt && isBefore(addMonths(parsedDates.updatedAt, 6), new Date()))),

            memberSince: parsedDates.memberSince && format(parsedDates.memberSince, 'PPP', { locale }),

            endToday: parsedDates.expiredAt && isToday(parsedDates.expiredAt),

            endTomorrow: parsedDates.expiredAt && isTomorrow(parsedDates.expiredAt),

            hasOneMonthOld: isBefore(
                addMonths(parsedDates.updatedAt || parsedDates.createdAt, 1),
                new Date()
            ),
        }

        const similarDiscounts =
            (isOffer && storedPage.similar_offers) || (isCoupon && storedPage.similar_coupons) || null

        let commentsLoaded = 0

        storedPage.comments.forEach((c) => {
            commentsLoaded++
            c.comments?.forEach(() => {
                commentsLoaded++
            })
        })

        const showCommentsLoadButton = discount.comments_count > commentsLoaded

        const currentDates = currentDate()

        const [title, description] = this.$util.replacePlaceholders(
            [
                [':month', currentDates.month || ''],
                [':year', String(currentDates.year) || ''],
                [':title', this.$util.textShortener(discount.title || '', 90)],
                [':description', this.$util.textShortener(discount.description || '', 165)],
                [':site', process.env.SITE_NAME || ''],
            ],
            [discount.seo?.title || '', discount.seo?.description || '']
        )

        return {
            creatingComment: false,
            defaultImage,
            storedPage,
            isOffer,
            isCoupon,
            isExclusive,
            isVerified,
            variant,
            similarDiscounts,
            comments: [] as Models.Comment[],
            discount,
            parsedDates,
            dates,
            showCode: false,
            metaInfo: {
                title,
                description,
            },
            activeFilter: 0,
            follow: false,
            saved: !!discount.saved,
            type: variant.variant as 'offer' | 'coupon',
            displayedItemFlags: {
                showCommentsLoadButton,
                showCommentMenu: false,
                showDiscountMenu: false,
            },
            loadingItemFlags: {
                commentsLoadButton: false,
            },
            nextPageQuery: {
                commentable_type: variant.variant,
                commentable_id: discount.id,
                per_page: 15,
                page: 2,
                order_by: 'most-liked',
            } as ServicesQueryParameters.GetComments,
            linkToCommentId: null as null | number,
            removeXSS,
        }
    },
    head(): MetaInfo {
        const metaTags: [string, string][] = [
            ['description', this.metaInfo?.description],
            ['title', this.metaInfo?.title],
            ['og:url', this.$util.host + this.$route.path],
            ['og:title', this.metaInfo?.title],
            ['twitter:image', this.discount.featured_image || ''],
            ['og:image:secure_url', this.discount.featured_image || ''],
            ['og:image', this.discount.featured_image || ''],
            ['og:image:alt', this.metaInfo?.title],
            ['og:description', this.metaInfo?.description],
        ]

        if (this.parsedDates.expiredAt && isBefore(this.parsedDates.expiredAt, subDays(new Date(), 7))) {
            metaTags.push(['robots', 'noindex, nofollow'])
        }
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
                {
                    rel: 'preload',
                    as: 'image',
                    href: this.discount.featured_image,
                    crossorigin: 'anonymous',
                },
            ],
            ...this.$util.metaInit(metaTags, this.metaInfo?.title),
        }
    },
    jsonld(): any {
        return {
            '@context': 'http://schema.org',
            '@graph': [
                {
                    '@context': 'https://schema.org',
                    '@type': 'Article',
                    author: {
                        '@type': 'Person',
                        name: this.discount.user?.name,
                        /*  url: `https://www.okdescontos.com.br/dashboard/${this.discount.user?.id}`, */
                    },
                    datePublished: this.discount.created_at,
                    headline: this.discount.title,
                    image: {
                        '@type': 'ImageObject',
                        url: this.discount.featured_image,
                    },
                    publisher: {
                        '@type': 'Organization',
                        name: 'OKdescontos',
                    },
                    dateModified: this.discount.updated_at,
                    description: this.discount.description,
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                        '@id': `https://www.okdescontos.com.br/${this.isOffer ? 'ofertas' : 'cupones'}/${
                            this.discount.slug
                        }`,
                    },
                },
                {
                    '@context': 'https://schema.org',
                    '@type': 'BreadcrumbList',
                    itemListElement: [
                        ...this.breadcrumbs
                            .filter((_, index) => this.breadcrumbs.length !== index + 1)
                            .map((value, index) => {
                                return {
                                    '@type': 'ListItem',
                                    position: index + 1,
                                    item: `https://www.okdescontos.com${value.path}`,
                                    name: value.title,
                                }
                            }),
                    ],
                },
            ],
        }
    },
    computed: {
        contentDescription(): string {
            return removeXSS(this.discount.description) || ''
        },
        stores(): Models.Store[] | null {
            return this.$store.state.layout?.sidebar?.stores || null
        },
        buttonMode(): string {
            if (this.isOffer) {
                if (this.discount.has_url) {
                    if (this.discount.code) {
                        return 'offer-code'
                    } else {
                        return 'offer'
                    }
                } else {
                    return 'no-link-offer'
                }
            } else if (this.isCoupon) {
                if (this.discount.has_url) {
                    return 'coupon'
                } else {
                    return 'no-link-coupon'
                }
            } else {
                return ''
            }
        },
        baseUrl(): string {
            return `${this.$util.host}${this.$route.fullPath}`
        },
        whatsapp(): string {
            return encodeUrl(`https://api.whatsapp.com/send?text=${this.discount.title}: ${this.baseUrl}`)
        },
        twitter(): string {
            return encodeUrl(`https://twitter.com/intent/tweet?text=${this.discount.title}: ${this.baseUrl}`)
        },
        /* telegram(): string {
            return encodeUrl(`https://telegram.me/share/url?url=${this.baseUrl}&text=${this.discount.title}`)
        }, */
        facebook(): string {
            return encodeUrl(`http://www.facebook.com/sharer.php?u=${this.baseUrl}&t=${this.discount.title}`)
        },
        dropdownOptions(): {
            commentOptions: DropdownOptionType[]
            discountOptions: DropdownOptionType[]
        } {
            return {
                commentOptions: [
                    {
                        title: this.$util.lang.views.discountDetails.more_relevant,
                        icon: this.$assets.primary.bookmark,
                        description: this.$util.lang.views.discountDetails.see_comments_relevant,
                        handler: () => {
                            this.nextPageQuery.order_by = 'most-liked'
                            this.activeFilter = 0
                        },
                    },
                    {
                        title: this.$util.lang.views.discountDetails.more_recent,
                        description: this.$util.lang.views.discountDetails.see_all_comments_more_recent_first,
                        icon: this.$assets.primary.subscribe,
                        handler: () => {
                            this.nextPageQuery.order_by = 'latest'
                            this.activeFilter = 1
                        },
                    },
                ],
                discountOptions: [
                    /* ...(this.isOffer || this.isCoupon
                        ? [
                              {
                                  title: this.$util.lang.views.discountDetails.save,
                                  icon: this.$assets.primary.bookmark,
                                  handler: this.savePost,
                              },
                          ]
                        : []), */
                    {
                        title: this.$util.lang.views.discountDetails.report,
                        icon: this.$assets.primary.shield,
                        description: this.$util.lang.views.discountDetails.why_report,
                        subDropdown: true,
                        options: [
                            {
                                title: this.$util.lang.views.discountDetails.spam_ofensive,
                                icon: this.$assets.primary.span,
                                handler: () => {
                                    this.createReport('Spam/Ofensivo')
                                },
                            },
                            {
                                title: this.$util.lang.views.discountDetails.duplicated,
                                description: this.$util.lang.views.discountDetails.offer_is_published,
                                icon: this.$assets.primary.copyCode,
                                handler: () => {
                                    this.createReport('Duplicado')
                                },
                            },
                            {
                                title: this.$util.lang.views.discountDetails.refered_links,
                                icon: this.$assets.primary.referralLink,
                                handler: () => {
                                    this.createReport('Enlaces referidos')
                                },
                            },
                            {
                                title: this.$util.lang.views.discountDetails
                                    .expired_offer_or_link_not_working,
                                icon: this.$assets.primary.offer,
                                handler: async () => {
                                    const reportResult = await this.$api.create.postExpire({
                                        expiredable_type: this.type as 'offer' | 'coupon',
                                        expiredable_id: this.discount.id,
                                    })
                                    if (reportResult.feedback === 'data_success') {
                                        this.$root.$emit('notification', {
                                            duration: 5000,
                                            dismissButtonText: this.$util.lang.views.discountDetails.okay,
                                            text: this.$util.lang.views.discountDetails.report_success,
                                            type: 'success',
                                        })
                                    } /* else if (
                                        this.$store.getters['auth/isAuth'] &&
                                        this.$store.state.user.profile?.accepted_conditions_at &&
                                        this.$store.state.user.profile?.verified_email
                                    ) {
                                        this.$root.$emit('notification', {
                                            duration: 5000,
                                            dismissButtonText: this.$util.lang.views.discountDetails.close,
                                            text: this.$util.lang.views.discountDetails.report_error,
                                            type: 'error',
                                        })
                                    } */
                                },
                            },
                            {
                                title: this.$util.lang.views.discountDetails.other,
                                icon: this.$assets.primary.about,
                                handler: () => {
                                    this.createReport('Otro')
                                },
                            },
                        ],
                    },
                ],
            }
        },
        shippingPrice(): string | boolean {
            return this.isFreeShipping
                ? this.$util.lang.views.discountDetails.free_ship
                : !!this.discount.shipping_price &&
                      this.discount.shipping_price !== '0' &&
                      this.$util.lang.symbol + this.discount.shipping_price
        },
        isFreeShipping(): boolean {
            return this.discount.discount_type === 'envío gratis' || !!this.discount.shipping_free
        },
        showOldPrice(): boolean {
            return !!this.discount.old_price && this.discount.new_price !== '0'
        },
        showNewPrice(): boolean {
            return (
                (this.isOffer && !!this.discount.new_price) ||
                (this.isCoupon &&
                    (((this.discount.discount_type === 'precio' ||
                        this.discount.discount_type === 'porcentaje') &&
                        this.discount.discount_value !== '0') ||
                        this.discount.discount_type === 'envío gratis'))
            )
        },
        newOfferPrice(): string {
            return this.discount.new_price === '0'
                ? this.$util.lang.views.discountDetails.free
                : this.$util.lang.symbol + this.discount.new_price
        },
        newCouponPrice(): string {
            return this.discount.discount_type === 'envío gratis'
                ? this.$util.lang.views.discountDetails.free_ship_uppercase
                : this.discount.discount_type === 'precio'
                ? this.$util.lang.symbol + this.discount.discount_value + ' OFF'
                : this.discount.discount_value === '100'
                ? this.$util.lang.views.discountDetails.free
                : this.discount.discount_value + '% OFF'
        },
        newPrice(): string {
            return (this.isOffer && this.newOfferPrice) || (this.isCoupon && this.newCouponPrice) || ''
        },
        showPercent(): boolean {
            return !!this.discount.discount_percent && this.discount.new_price !== '0'
        },
        timestamp(): string {
            const timestamp = this.dates.updateTimeAgo || this.dates.createTimeAgo || ''
            return timestamp.split(' ')[0] + timestamp.split(' ')[1]?.charAt(0)
        },
        areTimestampsEqual(): boolean {
            return this.dates.updateTimeAgo === this.dates.createTimeAgo
        },
        timestampCreatedAgo(): string {
            const timestamp = this.dates.createTimeAgo || ''
            return timestamp.split(' ')[0] + timestamp.split(' ')[1]?.charAt(0)
        },
        timestampUpdatedAgo(): string {
            const timestamp = this.dates.updateTimeAgo || ''
            return timestamp.split(' ')[0] + timestamp.split(' ')[1]?.charAt(0)
        },
        anyInformationExists(): boolean {
            return (
                !!this.dates.endSoon ||
                !!this.dates.endToday ||
                !!this.dates.endTomorrow ||
                !!this.isExclusive ||
                !!this.isVerified ||
                !!this.dates.endingDate ||
                !!this.dates.startingDate ||
                !!this.discount.is_local
            )
        },
        breadcrumbs(): { path?: string; title: string }[] {
            const hierarchy = [] as { path: string; title: string }[]

            if (this.storedPage.breadcrumbs) {
                hierarchy.push({
                    path: `/${this.$util.lang.routes.categories}/` + this.storedPage.breadcrumbs.slug,
                    title: this.storedPage.breadcrumbs.name,
                })
                if (this.storedPage.breadcrumbs.parent) {
                    hierarchy.push({
                        path:
                            `/${this.$util.lang.routes.categories}/` +
                            this.storedPage.breadcrumbs.parent.slug,
                        title: this.storedPage.breadcrumbs.parent.name,
                    })
                    if (this.storedPage.breadcrumbs.parent.parent) {
                        hierarchy.push({
                            path:
                                `/${this.$util.lang.routes.categories}/` +
                                this.storedPage.breadcrumbs.parent.parent.slug,
                            title: this.storedPage.breadcrumbs.parent.parent.name,
                        })
                    }
                }
            }

            return [
                {
                    path: '/',
                    title: this.$util.lang.views.discountDetails.home,
                },
                ...(hierarchy.length
                    ? hierarchy.reverse()
                    : [
                          {
                              path: this.variant.path,
                              title:
                                  this.variant.wordPlural.charAt(0).toUpperCase() +
                                  this.variant.wordPlural.slice(1),
                          },
                      ]),
                {
                    title: this.discount.title || '',
                },
            ]
        },
    },
    watch: {
        async 'nextPageQuery.order_by'() {
            this.$nuxt.$loading.start()
            await this.loadComments()
            this.$nuxt.$loading.finish()
        },
    },
    async mounted() {
        setTimeout(() => {
            this.$nextTick(() => {
                this.storedPage.banners?.length &&
                    this.$store.dispatch('assignBanners', this.storedPage.banners)
            })
        }, 2000)

        let comments: Models.Comment[] =
            this.storedPage.comments && this.storedPage.comments.length ? [...this.storedPage.comments] : []

        if (/#(comment|reply)-\d+/.test(this.$route.hash)) {
            const [type, id] = this.$route.hash.split('-')

            const commentType = type.slice(1)

            const commentId = Number(id)

            const comment = comments.find((comment) => {
                return comment.id === commentId || !!comment.comments?.find((reply) => reply.id === commentId)
            })

            if (comment) {
                this.comments = comments
                this.$nextTick(() => setTimeout(() => this.scrollToCommentsSection(this.$route.hash), 500))
            } else {
                try {
                    const commentResult = await this.$api.general.loadComments({
                        without_pagination: true,
                        limit: 1,
                        [commentType === 'comment' ? 'id' : 'reply_id']: id,
                    })

                    if (commentResult.feedback === 'data_success' && commentResult.data.results.length) {
                        this.linkToCommentId = commentResult.data.results[0].id

                        comments = comments.filter((comment) => comment.id !== this.linkToCommentId)

                        this.comments = [...commentResult.data.results, ...comments]

                        this.$nextTick(() =>
                            setTimeout(
                                () => this.scrollToCommentsSection(`#comment-${this.linkToCommentId}`),
                                500
                            )
                        )
                    } else {
                        this.comments = comments
                    }
                } catch (e) {}
            }
        } else if (this.$route.hash === '#bloque-de-comentarios') {
            this.comments = comments
            this.$nextTick(() =>
                setTimeout(() => this.scrollToCommentsSection('#bloque-de-comentarios'), 500)
            )
        } else {
            this.comments = comments
        }
    },
    methods: {
        scrollToCommentsSection(blockId: string) {
            this.$scrollTo(blockId, {
                duration: 1500,
            })

            if (blockId === '#bloque-de-comentarios') {
                setTimeout(() => this.$root.$emit('focusQuill'), 600)
            }
        },
        openCoupon() {
            this.showCode = true
            this.$openCoupon(this.discount.slug)
        },
        openOffer() {
            this.$openOffer(this.discount.id)
        },
        openNone() {
            return false
        },
        zoomIn() {
            this.$store.commit('setModal', {
                name: 'ModalImageZoom',
                info: {
                    image: this.discount.featured_image || this.defaultImage[this.type],
                },
                fullpage: true,
            })
        },
        dropdownToggle(
            show: boolean,
            key: 'showCommentsLoadButton' | 'showCommentMenu' | 'showDiscountMenu'
        ): void {
            if (
                (this.$bp.mb || this.$bp.xs || this.$bp.sm) &&
                (key === 'showCommentMenu' || key === 'showDiscountMenu')
            ) {
                this.$store.commit(
                    'setDropdown',
                    show
                        ? this.dropdownOptions[
                              key === 'showCommentMenu' ? 'commentOptions' : 'discountOptions'
                          ]
                        : null
                )
            } else {
                this.displayedItemFlags[key] = show
            }
        },
        /* async savePost() {
            try {
                const saveResult = await this.$api.create.savePost({
                    saveable_id: this.discount.id,
                    saveable_type: this.type,
                    type: this.saved ? 0 : 1,
                })

                if (saveResult.feedback === 'data_success') {
                    this.saved = !this.saved
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.views.discountDetails.okay,
                        text: `${
                            this.isOffer
                                ? this.$util.lang.views.discountDetails.offer
                                : this.$util.lang.views.discountDetails.coupon
                        } <span style="font-weight:600">${this.$util.textShortener(
                            this.discount.title,
                            this.$bp.mb ? 30 : this.$bp.xs || this.$bp.sm ? 60 : 90
                        )}</span> ${
                            this.saved
                                ? this.isOffer
                                    ? `${this.$util.lang.views.discountDetails.saved_a}.`
                                    : `${this.$util.lang.views.discountDetails.saved}.`
                                : this.isOffer
                                ? `${this.$util.lang.views.discountDetails.deleted_a}.`
                                : `${this.$util.lang.views.discountDetails.deleted}.`
                        }`,
                        type: 'success',
                    })
                } else if (
                    this.$store.getters['auth/isAuth'] &&
                    this.$store.state.user.profile?.accepted_conditions_at &&
                    this.$store.state.user.profile?.verified_email
                ) {
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.views.discountDetails.close,
                        text:
                            saveResult.errors.type?.[0] ||
                            saveResult.errors.saveable_type?.[0] ||
                            saveResult.errors.id?.[0] ||
                            saveResult.errors.saveable_id?.[0] ||
                            `${this.$util.lang.views.discountDetails.error_at} ${
                                this.saved
                                    ? this.$util.lang.views.discountDetails.delete
                                    : this.$util.lang.views.discountDetails.save_min
                            }, ${this.$util.lang.views.discountDetails.something_happens_with} ${
                                this.isOffer
                                    ? this.$util.lang.views.discountDetails.this_offer
                                    : this.$util.lang.views.discountDetails.this_coupon
                            }.`,
                        type: 'error',
                    })
                }
            } catch (e) {}
        }, */
        async createReport(reason: Parameters<Api.Services['create']['postReport']>[0]['reason']) {
            try {
                const reportResult = await this.$api.create.postReport({
                    reportable_type: this.type,
                    reportable_id: this.discount.id,
                    reason,
                })

                if (reportResult.feedback === 'data_success') {
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.views.discountDetails.okay,
                        text: this.$util.lang.views.discountDetails.report_success,
                        type: 'success',
                    })
                } /*  else if (
                    this.$store.getters['auth/isAuth'] &&
                    this.$store.state.user.profile?.accepted_conditions_at &&
                    this.$store.state.user.profile?.verified_email
                ) {
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.views.discountDetails.close,
                        text: this.$util.lang.views.discountDetails.report_error,
                        type: 'error',
                    })
                } */
            } catch (e) {}
        },
        async createComment(reply: { callback: (clear: boolean) => void; html: string }) {
            if (!this.creatingComment) {
                this.creatingComment = true
                try {
                    const commentResult = await this.$api.create.postComment({
                        commentable_id: this.discount.id,
                        commentable_type: this.type,
                        content: reply.html,
                    })

                    if (
                        commentResult.feedback === 'resource_created' ||
                        commentResult.feedback === 'resource_needs_approval'
                    ) {
                        reply.callback(true)

                        if (commentResult.feedback === 'resource_created') {
                            this.$root.$emit('notification', {
                                duration: 5000,
                                dismissButtonText: this.$util.lang.views.discountDetails.close,
                                text: this.$util.lang.views.discountDetails.comment_published,
                                type: 'success',
                            })
                            this.comments = [commentResult.data, ...this.comments]

                            this.$nextTick(() => {
                                this.$root.$emit('active-comment-pulses', commentResult.data.id)
                            })
                        }
                        if (commentResult.feedback === 'resource_needs_approval') {
                            this.$root.$emit('notification', {
                                duration: 5000,
                                dismissButtonText: this.$util.lang.views.discountDetails.hide,
                                text: this.$util.lang.views.discountDetails.comment_pending,
                                type: 'help',
                            })
                        }
                    }
                } catch (e) {
                    reply.callback(false)
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.views.discountDetails.hide,
                        text: this.$util.lang.views.discountDetails.comment_cant_create,
                        type: 'error',
                    })
                }
                this.creatingComment = false
            }
        },
        async loadComments() {
            const commentsResult = await this.$api.general.loadComments(this.nextPageQuery)

            if (commentsResult.feedback === 'data_success') {
                if (commentsResult.data.meta.current_page === 1) {
                    this.comments = [...commentsResult.data.results]
                } else {
                    const comments = this.linkToCommentId
                        ? commentsResult.data.results.filter(
                              (comment: any) => comment.id !== this.linkToCommentId
                          )
                        : commentsResult.data.results

                    this.comments = [...this.comments, ...comments]
                }

                if (commentsResult.data.meta.current_page < commentsResult.data.meta.last_page) {
                    this.nextPageQuery.page = commentsResult.data.meta.current_page + 1
                } else {
                    this.displayedItemFlags.showCommentsLoadButton = false
                }
            }
        },
        async loadCommentsButtonHandler() {
            if (!this.loadingItemFlags.commentsLoadButton) {
                this.loadingItemFlags.commentsLoadButton = true
                await this.loadComments()
                this.loadingItemFlags.commentsLoadButton = false
            }
        },
    },
})
