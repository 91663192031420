
import Vue, { PropOptions } from 'vue'
import { Models } from '@/types/models'
import { defaultImage } from '~/libs/useful-functions'

interface FilterLetters {
    name: string
    path: string
    data: Models.Category[]
    icon?: string
    icon2?: string
    class?: string
}

declare module 'vue/types/vue' {
    type filterLetters = FilterLetters[]
    type active = string
}

export default Vue.extend({
    name: 'FilterByLetter',
    props: {
        path: {
            type: String,
            default: '',
        },
        info: {
            type: Object,
            default: () => [{}] as PropOptions<{ [key: string]: Models.Category[] }[]>,
        },
    },
    data() {
        return {
            defaultImage,
            filterLetters: [
                {
                    name: 'popular',
                    path: '',
                    icon2: this.$assets.white.popular,
                    icon: this.$assets.primary.diamond,
                    class: 'font-semibold',
                    data: [],
                },
                { name: 'a', path: '/a', data: [] },
                { name: 'b', path: '/b', data: [] },
                { name: 'c', path: '/c', data: [] },
                { name: 'd', path: '/d', data: [] },
                { name: 'e', path: '/e', data: [] },
                { name: 'f', path: '/f', data: [] },
                { name: 'g', path: '/g', data: [] },
                { name: 'h', path: '/h', data: [] },
                { name: 'i', path: '/i', data: [] },
                { name: 'j', path: '/j', data: [] },
                { name: 'k', path: '/k', data: [] },
                { name: 'l', path: '/l', data: [] },
                { name: 'm', path: '/m', data: [] },
                { name: 'n', path: '/n', data: [] },
                { name: 'ñ', path: '/ñ', data: [] },
                { name: 'o', path: '/o', data: [] },
                { name: 'p', path: '/p', data: [] },
                { name: 'q', path: '/q', data: [] },
                { name: 'r', path: '/r', data: [] },
                { name: 's', path: '/s', data: [] },
                { name: 't', path: '/t', data: [] },
                { name: 'u', path: '/u', data: [] },
                { name: 'v', path: '/v', data: [] },
                { name: 'w', path: '/w', data: [] },
                { name: 'x', path: '/x', data: [] },
                { name: 'y', path: '/y', data: [] },
                { name: 'z', path: '/z', data: [] },
            ],
            active: 'popular',
        }
    },
    computed: {
        filteredFilterLetters(): FilterLetters[] {
            return this.filterLetters.filter(
                (letter) => this.active === 'popular' || this.active === letter.name
            )
        },
        limit(): boolean {
            return this.$route.params.slug === undefined
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(val) {
                if (val.params.slug === undefined) {
                    this.active = 'popular'
                } else {
                    this.active = val.params.slug
                }
            },
        },
    },
    mounted() {
        for (const letter in this.info) {
            const letterData = this.filterLetters.find(({ name }) => name === letter)
            if (letterData) {
                letterData.data = this.info[letter] || []
            }
        }
    },
    methods: {
        changeSelected(letter: any) {
            this.active = letter
        },
    },
})
