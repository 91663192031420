
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'

export default Vue.extend({
    name: 'Subscribe',
    async asyncData({ $api, route }) {
        const info = await $api.general.newsletterVerify({ hash: route.query.hash })

        console.log(info)

        if (info.feedback === 'data_success') {
            return { success: true }
        } else if (info.feedback === 'existing_email') {
            return { success: true, existing: true }
        } else {
            return { success: false }
        }
    },
    data() {
        return {
            success: null,
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.subscribe.title
        const description = this.$util.lang.pages.subscribe.description
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
})
