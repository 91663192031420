var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('transition',{attrs:{"name":"slide-down"}},[(_vm.info.discount && _vm.showResume)?_c('div',{staticClass:"layout-header-floating-resume"},[_c('div',{staticClass:"layout-header-floating-resume__container"},[_c('p',{staticClass:"layout-header-floating-resume__title"},[_vm._v(_vm._s(_vm.info.discount.title))]),_vm._v(" "),_c('BtnDiscount',{staticClass:"layout-header-floating-resume__button",attrs:{"info":{
                    inResume: true,
                    slug: _vm.info.discount.slug,
                    code: _vm.info.discount.code,
                    mode: _vm.info.mode,
                    showCode: true,
                    openCoupon: _vm.openCoupon,
                    discount: _vm.info,
                    openOffer: _vm.openOffer,
                }}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }