import { render, staticRenderFns } from "./premios.vue?vue&type=template&id=23aa9296&scoped=true&"
import script from "./premios.vue?vue&type=script&lang=ts&"
export * from "./premios.vue?vue&type=script&lang=ts&"
import style0 from "./premios.vue?vue&type=style&index=0&id=23aa9296&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23aa9296",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardDashboardRouter: require('/home/runner/work/okdescontos-front/okdescontos-front/components/card/dashboard/Router.vue').default,MicroNoResults: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/NoResults.vue').default,BtnSeeMoreResults: require('/home/runner/work/okdescontos-front/okdescontos-front/components/btn/SeeMoreResults.vue').default})
