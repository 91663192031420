var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"reset-success-index"},[_c('div',{staticClass:"reset-success"},[_c('h2',{staticClass:"reset-success__title"},[_vm._v("\n            "+_vm._s(_vm.$util.lang.pages.contrasenaActualizadaExitosamente.success)+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"reset-success__subtitle"},[_vm._v("\n            "+_vm._s(_vm.$util.lang.pages.contrasenaActualizadaExitosamente.enjoy)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"reset-success__buttons"},[_c('BtnGeneric',{staticClass:"reset-success__button",attrs:{"info":{
                    text: _vm.$util.lang.pages.contrasenaActualizadaExitosamente.see_coupons,
                    icon: _vm.$assets.white.couponsMenu,
                    link: '/',
                }}}),_vm._v(" "),_c('BtnGeneric',{staticClass:"reset-success__button",attrs:{"info":{
                    text: _vm.$util.lang.pages.contrasenaActualizadaExitosamente.see_offers,
                    icon: _vm.$assets.white.offersMenu,
                    link: `/${_vm.$util.lang.routes.offers}`,
                }}})],1),_vm._v(" "),_c('div',{staticClass:"reset-success__countdown"},[_c('p',{staticClass:"reset-success__countdown-tittle"},[_vm._v("\n                "+_vm._s(_vm.$util.lang.pages.contrasenaActualizadaExitosamente.redirect)+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"reset-success__countdown-count"},[_vm._v(_vm._s(_vm.countdown))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }