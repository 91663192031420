import { render, staticRenderFns } from "./expirados.vue?vue&type=template&id=8015486e&scoped=true&"
import script from "./expirados.vue?vue&type=script&lang=ts&"
export * from "./expirados.vue?vue&type=script&lang=ts&"
import style0 from "./expirados.vue?vue&type=style&index=0&id=8015486e&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8015486e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardMainGlobal: require('/home/runner/work/okdescontos-front/okdescontos-front/components/card/main/Global.vue').default,MicroLazy: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/Lazy.vue').default,MicroNoResults: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/NoResults.vue').default,BtnSeeMoreResults: require('/home/runner/work/okdescontos-front/okdescontos-front/components/btn/SeeMoreResults.vue').default,SidebarCategoriesTwoLazy: require('/home/runner/work/okdescontos-front/okdescontos-front/components/sidebar/CategoriesTwoLazy.vue').default,SidebarStores: require('/home/runner/work/okdescontos-front/okdescontos-front/components/sidebar/Stores.vue').default,SidebarStoresSkeleton: require('/home/runner/work/okdescontos-front/okdescontos-front/components/sidebar/StoresSkeleton.vue').default})
