
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { Api } from '~/global'

export default Vue.extend({
    name: 'BlogCategory',
    async validate({ $api, store, params, error, $util }) {
        const storeResult = await $api.pages.loadArticlesByCategory(params.category)

        if (storeResult.feedback === 'data_success') {
            store.commit('pages/setValue', { name: 'blogCategory', page: storeResult })
        } else if (storeResult.feedback === 'resource_not_found') {
            error({
                statusCode: 404,
                message: $util.lang.pages.blogCategory.category_not_found,
            })
        } else {
            error({
                statusCode: 404,
                message: $util.lang.pages.blogCategory.unexpected_error,
            })
        }

        return true
    },
    data() {
        const info = this.$store.state.pages.blogCategory as Api.Responses.Pages.ArticlesByCategory

        const pagination = {
            show: info.data.articles.meta.last_page !== info.data.articles.meta.current_page,
            loading: false,
        }

        return {
            info,
            data: {
                articles: [...info.data.articles.data],
            },
            pagination,
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.blogCategory.articles_about.replace(
            '{{category}}',
            this.info.data.blogCategory.name
        )
        const description = this.$util.lang.pages.blogCategory.all_articles.replace(
            '{{category}}',
            this.info.data.blogCategory.name
        )
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                    ['twitter:title', title],
                    ['twitter:description', description],
                    ['twitter:image:alt', title],
                ],
                title
            ),
        }
    },
    computed: {
        breadcrumbs(): { path?: string; title: string }[] {
            return [
                {
                    path: '/',
                    title: this.$util.lang.pages.blogCategory.home,
                },
                {
                    path: `/${this.$util.lang.routes.blog}`,
                    title: this.$util.lang.pages.blogCategory.blog,
                },
                {
                    title: this.info.data.blogCategory.name || '',
                },
            ]
        },
        nextPage(): number {
            return this.info.data.articles.meta.current_page + 1
        },
    },
    methods: {
        async loadMore() {
            this.pagination.loading = true

            const result = await this.$api.pages.loadArticlesByCategory(this.$route.params.category, {
                page: this.nextPage,
            })

            if (result.feedback === 'data_success') {
                this.data.articles = [...result.data.articles.data]
                this.info = result
            }
            this.pagination.loading = false
        },
    },
})
