import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=c812dcc4&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=ts&"
export * from "./Header.vue?vue&type=script&lang=ts&"
import style0 from "./Header.vue?vue&type=style&index=0&id=c812dcc4&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c812dcc4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutHeaderNotifications: require('/home/runner/work/okdescontos-front/okdescontos-front/components/layout/header/Notifications.vue').default,LayoutHeaderAdBars: require('/home/runner/work/okdescontos-front/okdescontos-front/components/layout/header/AdBars.vue').default,LayoutHeaderMenu: require('/home/runner/work/okdescontos-front/okdescontos-front/components/layout/header/Menu.vue').default,LayoutHeaderSearch: require('/home/runner/work/okdescontos-front/okdescontos-front/components/layout/header/Search.vue').default,LayoutHeaderArcLinks: require('/home/runner/work/okdescontos-front/okdescontos-front/components/layout/header/ArcLinks.vue').default})
