
import Vue, { PropOptions } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'MicroFullUser',
    props: {
        isSharing: {
            type: Boolean,
            default: false,
        },
        imageClass: {
            type: String,
            default: 'w-10 h-10',
        },
        info: {
            type: Object,
            required: true,
        } as PropOptions<Models.User>,
    },
    computed: {
        url(): object | string {
            return this.isSharing
                ? '#'
                : {
                      name: 'dashboard-user',
                      params: { user: this.info.id },
                  }
        },
    },
})
