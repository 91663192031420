
import Vue from 'vue'
import CategoryDetails from '@/views/CategoryDetails.vue'
import CategoriesList from '@/views/CategoriesList.vue'

export default Vue.extend({
    name: 'CategoriesBridge',
    components: {
        CategoryDetails,
        CategoriesList,
    },
    async validate({ store, $api, error, params: { slug }, $util }) {
        if (/^(a|b|c|d|e|f|g|h|i|j|k|l|m|n|ñ|o|p|q|r|s|t|u|v|w|x|y|z)$/.test(slug) || !slug) {
            const categories = await $api.pages.loadCategories()
            store.commit('pages/setValue', { name: 'categories', page: categories })
        } else {
            /* const isNew = slug.endsWith(`-${$util.lang.views.categoriesBridge.news}`)
            const isCommented = slug.endsWith(`-${$util.lang.views.categoriesBridge.commented}`)
            const newSlug =
                slug.includes(`-${$util.lang.views.categoriesBridge.news}`) ||
                slug.includes(`-${$util.lang.views.categoriesBridge.commented}`)
                    ? slug
                          .replace(`-${$util.lang.views.categoriesBridge.news}`, '')
                          .replace(`-${$util.lang.views.categoriesBridge.commented}`, '')
                    : slug */

            const order_by = /* isNew ?  */ 'latest' /* : isCommented ? 'most-comment' : null */

            const tabData = await $api.pages.loadCategory(slug, { 'order-by': order_by })

            if (tabData.feedback === 'data_success') {
                store.commit('pages/setValue', {
                    name: 'category',
                    page: tabData.data,
                })
            } else {
                error({
                    statusCode: 404,
                    message: $util.lang.views.categoriesBridge.not_found,
                })
            }
        }

        return true
    },
    render(h) {
        if (
            /^(popular|a|b|c|d|e|f|g|h|i|j|k|l|m|n|ñ|o|p|q|r|s|t|u|v|w|x|y|z)$/.test(
                this.$route.params.slug
            ) ||
            !this.$route.params.slug
        ) {
            return h('CategoriesList')
        } else {
            return h('CategoryDetails')
        }
    },
})
