
import Vue, { PropType } from 'vue'
import type { Models } from '../../types/models'

export default Vue.extend({
    props: {
        discount: {
            type: Object as PropType<Models.Discount>,
            required: true,
        },
        store: {
            type: Object as PropType<Models.Store>,
        },
        btnDisabled: {
            type: Boolean,
        },
    },
    data() {
        return {
            copied: this.$route.query?.slug === this.discount.slug ? true : null,
            showCodeOnly: true /* full version to false */,
            showToolTip: false,
            textAnimated: '',
            works: null as null | boolean,
        }
    },
    mounted() {
        if (this.isCurrentOpenCoupon) {
            setTimeout(() => {
                this.copied = false
            }, 5000)
        }
    },
    computed: {
        isCurrentOpenCoupon(): boolean {
            return this.$route.query.slug === this.discount.slug
        },
        showCode(): boolean {
            return this.$route.query.slug !== this.discount.slug
        },
        codeText(): string {
            if (this.discount.code && this.discount.code.trim().length) {
                const length = this.discount.code.length
                return this.$route.query.slug
                    ? this.discount.code
                    : this.discount.code?.slice(0, length >= 10 ? 7 : length - 3) + '...'
            }
            return this.$util.lang.components.btnDiscount.no_coupon_required
        },
    },
    methods: {
        /* async codeWorks(works: boolean) {
            const response = await this.$api.create.codeWorks(this.discount.id, {
                feedback: works ? 1 : 0,
            })

            if (response && response.feedback === 'data_success') {
                this.$emit('average-success', response.data.new_success_percentage)
                this.works = works

                setTimeout(() => {
                    this.showCodeOnly = true
                    this.copied = false
                }, 3000)
            }
        }, */
        async copyCode(clearAfter: boolean) {
            if (!this.$route.query.slug) {
                if (this.discount.code && this.discount.code.trim().length) {
                    await this.$copyText(this.discount.code)
                }
                this.$openCoupon(this.discount.slug, this.discount.store?.slug || this.store.slug)
            } else {
                if (this.discount.code && this.discount.code.trim().length) {
                    await this.$copyText(this.discount.code)
                }
                /* this.$openCoupon(this.discount.slug) */
                this.copied = true
                this.showToolTip = true
                setTimeout(() => {
                    this.showToolTip = false
                }, 3000)
                if (clearAfter) {
                    setTimeout(() => {
                        this.copied = false
                    }, 3000)
                }
            }
        },
    },
})
