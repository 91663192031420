
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'
import { banners as bannersKeys } from '@/config/constants'

interface BannerLS {
    [key: string]: string
}

type BannerProp = {
    name: string
    info: Models.Banner
}

export default Vue.extend({
    name: 'LayoutBanner',
    props: {
        banner: {
            type: Object as PropType<BannerProp>,
            required: true,
        },
    },
    data() {
        return {
            mountBanner: false,
        }
    },
    computed: {
        mobile(): boolean {
            return process.browser ? /^(mb|xs|sm|md)$/.test(this.$bp.current) : false
        },
        desktop(): boolean {
            return process.browser ? /^(lg|xl)$/.test(this.$bp.current) : false
        },
    },
    watch: {
        banner: {
            immediate: true,
            handler(banner: BannerProp) {
                if (
                    banner &&
                    banner.info &&
                    ((banner.info.desktop && this.desktop) || (banner.info.mobile && this.mobile))
                )
                    this.mount(true)
            },
        },
    },
    methods: {
        mount(mount: boolean) {
            const ls = localStorage.getItem(bannersKeys.downBanner)
            const parseLS = ls ? (JSON.parse(ls) as BannerLS) : null

            if (mount) {
                if (
                    !parseLS ||
                    !parseLS[this.banner.info.id] ||
                    parseLS[this.banner.info.id] !== this.banner.info.updated_at
                ) {
                    // Si no existe data en lS || si no existe informacion del banner en esta ruta || o si las fechas son distintas
                    // console.log('montando banner')
                    this.mountBanner = mount
                }
            } else {
                // console.log('desmontando banner')
                this.mountBanner = mount

                if (parseLS) {
                    parseLS[this.banner.info.id] = this.banner.info.updated_at

                    localStorage.setItem(bannersKeys.downBanner, JSON.stringify(parseLS))
                    // console.log('banner actualizado')
                } else {
                    const newLS = {
                        [this.banner.info.id]: this.banner.info.updated_at,
                    }
                    localStorage.setItem(bannersKeys.downBanner, JSON.stringify(newLS))
                    // console.log('banner creado')
                }
            }
        },
    },
})
