
import Vue, { PropType } from 'vue'
import { parseISO, format } from 'date-fns'

export default Vue.extend({
    name: 'SidebarOfferSummary',
    props: {
        title: {
            type: String,
        },
        info: {
            type: Object as PropType<{
                discounts_active: number
                offers_count: number
                coupons_count: number
                discounts_updated: number
                last_updated: string
            }>,
        },
        model: {
            type: String,
        },
    },
    data() {
        return {
            images: [
                'https://cdn.worldvectorlogo.com/logos/visa.svg',
                'https://cdn.worldvectorlogo.com/logos/mastercard-2.svg',
                'https://cdn.worldvectorlogo.com/logos/american-express-1.svg',
            ],
        }
    },
    computed: {
        lastUpdate(): string {
            return format(parseISO(this.info.last_updated), 'dd/MM/yyyy')
        },
    },
})
