var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"thread-item"},[_c('MicroBreadcrumbs',{attrs:{"layers":_vm.breadcrumbs,"text-color":"text-gray-900"}}),_vm._v(" "),_c('div',{staticClass:"thread-item__content"},[_c('main',[_c('div',{staticClass:"thread"},[_c('div',{staticClass:"flex-col hidden p-4 thread__author sm:flex"},[_c('NLink',{staticClass:"thread__author-image",attrs:{"to":{
                            name: 'dashboard-user',
                            params: { user: _vm.storeResult.forum.user.id },
                        }}},[(_vm.storeResult.forum.user.image && _vm.storeResult.forum.user.image.url)?_c('img',{attrs:{"src":_vm.storeResult.forum.user.image.url,"alt":_vm.storeResult.forum.user.name,"title":_vm.storeResult.forum.user.name}}):_c('MicroUserNoImage',{attrs:{"name":_vm.storeResult.forum.user.username}})],1),_vm._v(" "),_c('NLink',{staticClass:"thread__author-user",attrs:{"to":{
                            name: 'dashboard-user',
                            params: { user: _vm.storeResult.forum.user.id },
                        }}},[_vm._v("\n                        "+_vm._s(_vm.storeResult.forum.user.username)+"\n                    ")]),_vm._v(" "),(
                            _vm.storeResult.forum.user.role.name &&
                            _vm.storeResult.forum.user.role.name !== 'Usuario'
                        )?_c('span',{staticClass:"thread__author-badge"},[_c('MicroBadge',{attrs:{"text":_vm.storeResult.forum.user.role.name}})],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"about"},[_c('div',{staticClass:"hidden space-x-2 about-top sm:flex"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.$util.lang.pages.forosSlug.ago)+" "+_vm._s(_vm.updatedDate))]),_vm._v(" "),_c('div',{staticClass:"discount-dropdown"},[_c('button',{staticClass:"discount-dropdown__button",attrs:{"type":"button"},on:{"click":() =>
                                        _vm.dropdownToggle(
                                            !_vm.displayedItemFlags.showDiscountMenu,
                                            'showDiscountMenu'
                                        )}},[_c('img',{attrs:{"src":_vm.$assets.gray.buttonDots,"alt":"icon"}})]),_vm._v(" "),_c('transition',{attrs:{"name":"drop-from-right"}},[(_vm.displayedItemFlags.showDiscountMenu)?_c('DropdownOptions',{staticClass:"discount-dropdown__dropdown",attrs:{"options":_vm.dropdownOptions.discountOptions},on:{"close":() =>
                                            _vm.dropdownToggle(
                                                !_vm.displayedItemFlags.showDiscountMenu,
                                                'showDiscountMenu'
                                            )}}):_vm._e()],1)],1)]),_vm._v(" "),_c('div',{staticClass:"about__title-and-subtitle"},[_c('h1',[_vm._v(_vm._s(_vm.storeResult.forum.title))]),_vm._v(" "),(_vm.storeResult.forum.category)?_c('h2',[_c('img',{attrs:{"src":_vm.$assets.white.chat,"alt":"Mensajes"}}),_vm._v("\n                            "+_vm._s(_vm.storeResult.forum.category.name)+"\n                        ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"about__description",domProps:{"innerHTML":_vm._s(_vm.descriptionSanitized)}}),_vm._v(" "),_c('div',{staticClass:"flex justify-between mt-4"},[_c('div',{staticClass:"flex flex-row items-center sm:hidden gap-x-1"},[_c('NLink',{staticClass:"w-10 h-10",attrs:{"to":{
                                    name: 'dashboard-user',
                                    params: { user: _vm.storeResult.forum.user.id },
                                }}},[(
                                        _vm.storeResult.forum.user.image && _vm.storeResult.forum.user.image.url
                                    )?_c('img',{staticClass:"rounded-full",attrs:{"src":_vm.storeResult.forum.user.image.url,"alt":"autor"}}):_c('MicroUserNoImage',{attrs:{"name":_vm.storeResult.forum.user.username}})],1),_vm._v(" "),_c('div',[_c('NLink',{staticClass:"text-sm text-gray-800",attrs:{"to":{
                                        name: 'dashboard-user',
                                        params: { user: _vm.storeResult.forum.user.id },
                                    }}},[_vm._v("\n                                    "+_vm._s(_vm.storeResult.forum.user.username)+"\n                                ")]),_vm._v(" "),(
                                        _vm.storeResult.forum.user.role.name &&
                                        _vm.storeResult.forum.user.role.name !== 'Usuario'
                                    )?_c('span',[_c('MicroBadge',{attrs:{"text":_vm.storeResult.forum.user.role.name,"show-text-in-mobile":true}})],1):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"relative flex items-end gap-2 top sm:hidden"},[_c('span',{staticClass:"text-xs text-gray-800"},[_vm._v("Há "+_vm._s(_vm.updatedDate))]),_vm._v(" "),_c('div',{staticClass:"discount-dropdown"},[_c('button',{staticClass:"discount-dropdown__button",attrs:{"type":"button"},on:{"click":() =>
                                            _vm.dropdownToggle(
                                                !_vm.displayedItemFlags.showDiscountMenuMobile,
                                                'showDiscountMenuMobile'
                                            )}},[_c('img',{attrs:{"src":_vm.$assets.gray.buttonDots,"alt":"icon"}})]),_vm._v(" "),_c('transition',{attrs:{"name":"drop-from-right"}},[(_vm.displayedItemFlags.showDiscountMenuMobile)?_c('DropdownOptions',{staticClass:"discount-dropdown__dropdown",attrs:{"options":_vm.dropdownOptions.discountOptions},on:{"close":() =>
                                                _vm.dropdownToggle(
                                                    !_vm.displayedItemFlags.showDiscountMenuMobile,
                                                    'showDiscountMenuMobile'
                                                )}}):_vm._e()],1)],1)])])])]),_vm._v(" "),_c('client-only',[_c('h2',{staticClass:"comments_heading"},[_vm._v(_vm._s(_vm.$util.lang.pages.forosSlug.comments))]),_vm._v(" "),_c('div',{staticClass:"comments_wrapper",attrs:{"id":"discount-comments-section"}},[_c('div',{staticClass:"comments-top"},[_c('img',{staticClass:"comments-top__icon",attrs:{"src":_vm.$assets.black.comment,"alt":"icon"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.storeResult.comments.length))]),_vm._v(" "),_c('button',{staticClass:"comments-top__follow-button",class:{ following: _vm.follow },attrs:{"type":"button"},on:{"click":function($event){_vm.follow = !_vm.follow}}},[_vm._v("\n                            "+_vm._s(_vm.follow
                                    ? _vm.$util.lang.pages.forosSlug.following
                                    : _vm.$util.lang.pages.forosSlug.follow)+"\n                        ")])]),_vm._v(" "),_c('div',{staticClass:"px-4"},[_c('FormMakeComment',{staticClass:"mb-3",attrs:{"user":_vm.$store.state.user.profile,"floated-submit":true},on:{"reply":_vm.createComment}}),_vm._v(" "),_c('div',{staticClass:"comment-dropdown"},[_c('button',{staticClass:"comment-dropdown__button",attrs:{"type":"button"},on:{"click":() =>
                                        _vm.dropdownToggle(
                                            !_vm.displayedItemFlags.showCommentMenu,
                                            'showCommentMenu'
                                        )}},[_c('span',[_vm._v(_vm._s(_vm.nextPageQuery.order_by === 'most-liked'
                                        ? _vm.$util.lang.pages.forosSlug.more_relevant
                                        : _vm.$util.lang.pages.forosSlug.more_recents))]),_vm._v(" "),_c('img',{attrs:{"src":_vm.$assets.gray.arrowDown,"alt":"icon"}})]),_vm._v(" "),_c('transition',{attrs:{"name":"drop-from-left"}},[(_vm.displayedItemFlags.showCommentMenu)?_c('DropdownOptions',{staticClass:"comment-dropdown__dropdown",attrs:{"active":_vm.activeFilter,"options":_vm.dropdownOptions.commentOptions},on:{"close":() =>
                                            _vm.dropdownToggle(
                                                !_vm.displayedItemFlags.showCommentMenu,
                                                'showCommentMenu'
                                            )}}):_vm._e()],1)],1),_vm._v(" "),(_vm.comments.length > 0)?[_c('ul',{staticClass:"space-y-4"},_vm._l((_vm.comments),function(comment,index){return _c('li',{key:index},[_c('CardCommentThread',{attrs:{"comment":comment}})],1)}),0),_vm._v(" "),(_vm.displayedItemFlags.showCommentsLoadButton)?_c('BtnSeeMoreResults',{staticClass:"mt-3 mb-1",attrs:{"handler":_vm.loadCommentsButtonHandler,"loading":_vm.loadingItemFlags.commentsLoadButton}}):_vm._e()]:_c('MicroNoResults',{attrs:{"mode":"comments","title":_vm.$util.lang.pages.forosSlug.no_comments_yet,"subtitle":_vm.$util.lang.pages.forosSlug.comment_first}})],2)])])],1),_vm._v(" "),_c('aside',{staticClass:"sidebar"},[_c('SidebarShare',{attrs:{"title":_vm.storeResult.forum.title}}),_vm._v(" "),_c('div',{staticClass:"sidebar-discussions"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"head__title"},[_c('img',{attrs:{"src":_vm.$assets.gray.replies,"alt":"icono de respuestas"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$util.lang.pages.forosSlug.discussions))])]),_vm._v(" "),_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"dropdown__order-button",on:{"click":function($event){return _vm.dropdownToggleEasy(!_vm.dropdown.show.orderby, 'orderby')}}},[_c('img',{attrs:{"src":_vm.$assets.primary.filterBy,"alt":""}}),_vm._v("\n                            "+_vm._s(_vm.dropdown.orderby.selected)+"\n                            "),_c('Transition',{attrs:{"name":"fade"}},[(_vm.dropdown.show.orderby)?_c('DropdownOrderby',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
                                        handler: () => _vm.dropdownToggleEasy(false, 'orderby'),
                                    }),expression:"{\n                                        handler: () => dropdownToggleEasy(false, 'orderby'),\n                                    }"}],attrs:{"options":_vm.dropdown.orderby},on:{"update:options":function($event){return _vm.$set(_vm.dropdown, "orderby", $event)}}}):_vm._e()],1)],1)])]),_vm._v(" "),_c('ul',[_vm._l((_vm.storeResult.discussions.results),function(item){return _c('li',{key:item.id},[_c('NLink',{staticClass:"title",attrs:{"to":`/${_vm.$util.lang.routes.forum}/${item.slug}`}},[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('div',{staticClass:"user-and-messages"},[_c('div',{staticClass:"user-and-messages__author"},[_c('MicroFullUser',{attrs:{"info":item.user,"image-class":"w-8 h-8"}}),_vm._v(" "),_c('span',{staticClass:"answers"},[_c('img',{attrs:{"src":_vm.$assets.gray.replies,"alt":"icono de respuestas"}}),_vm._v("\n                                    "+_vm._s(item.comments_count)+"\n                                ")])],1)])],1)}),_vm._v(" "),(_vm.pagination.discussions.show)?_c('li',{staticClass:"see-more",attrs:{"loading":_vm.pagination.discussions.loading,"handler":() => _vm.loadMoreDiscussions()}},[_c('div',{staticClass:"see-more_btn"},[(!_vm.pagination.discussions.loading)?_c('div',[_vm._v("\n                                "+_vm._s(_vm.$util.lang.pages.forosSlug.see_more)+"\n                                "),_c('img',{attrs:{"src":_vm.$assets.primary.arrowDownDouble,"alt":"Flecha doble Ver mais"}})]):_c('img',{staticClass:"spin",attrs:{"src":_vm.$assets.primary.loading,"height":"17","width":"17","alt":"icon"}})])]):_vm._e()],2)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }