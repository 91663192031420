
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { Api } from '@/global'
import { currentDate } from '~/libs/date-functions'
import { Models } from '~/types/models'

export default Vue.extend({
    props: {
        propName: {
            type: String,
            default: '',
        },
    },
    async asyncData({ $api, store }) {
        const free = await $api.pages.loadFree()
        store.commit('pages/setValue', { name: 'free', page: free })
    },
    data() {
        const info = this.$store.state.pages.free as Api.Responses.Pages.Free
        const currentDates = currentDate()

        const [title, description] = this.$util.replacePlaceholders(
            [
                [':month', currentDates.month || ''],
                [':day', String(currentDates.day) || ''],
                [':year', String(currentDates.year) || ''],
                [':site', process.env.SITE_NAME || ''],
            ],
            [info.page.title || '', info.page.description || '']
        )

        return {
            metaInfo: { title, description },
            pagination: {
                popular: { loading: false, show: true },
                latest: { loading: false, show: true },
            },
            info,
            tabsInfo: {
                show: 0,
                tabs: [
                    { title: this.$util.lang.pages.gratis.populars },
                    { title: this.$util.lang.pages.gratis.news },
                ],
            },
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                ],
                this.metaInfo?.title
            ),
        }
    },
    computed: {
        breadcrumbs(): { path: string; title: string }[] {
            return [
                {
                    path: '/',
                    title: this.$util.lang.pages.gratis.home,
                },
                {
                    path: `/${this.$util.lang.routes.brands}`,
                    title: this.$util.lang.pages.gratis.stores,
                },
            ]
        },
        getType(): 'popular' | 'latest' {
            return this.tabsInfo.show === 0 ? 'popular' : 'latest'
        },
        stores(): Models.Store[] {
            return this.$store.state.layout?.sidebar?.stores
        },
    },
    mounted() {
        setTimeout(() => {
            this.$nextTick(() => {
                this.info.page.banners && this.$store.dispatch('assignBanners', this.info.page.banners)
            })
        }, 2000)
    },
    methods: {
        loadMore(): void {
            this.pagination[this.getType].loading = true
            let page = 0
            const newFree = JSON.parse(JSON.stringify(this.info)) as Api.Responses.Pages.Free

            const { latest, popular } = newFree.page.data

            if (this.tabsInfo.show === 0) {
                page = popular.meta.current_page + 1
            } else {
                page = latest.meta.current_page + 1
            }

            this.$api.pages
                .loadFree({ page })
                .then((res: any) => {
                    if (this.tabsInfo.show === 0) {
                        popular.data.push(...res.page.data.popular.data)
                        popular.meta = res.page.data.popular.meta
                    } else {
                        latest.data.push(...res.page.data.latest.data)
                        latest.meta = res.page.data.latest.meta
                    }

                    this.info = newFree

                    this.pagination[this.getType] = {
                        loading: false,
                        show: page < newFree.page.data[this.getType].meta.last_page,
                    }
                })
                .catch((err: any) => {
                    console.error('error', err)
                    this.pagination[this.getType].loading = false
                })
        },
    },
})
