
import Vue from 'vue'

export default Vue.extend({
    name: 'SidebarCategoriesTwoLazy',
    props: {
        info: Array,
        maxItems: Number,
    },
    data() {
        return {
            intersected: false,
        }
    },
    mounted() {
        this.$scrollObserver.addTarget(
            'lazyComponents',
            this.$refs.ScrollRoot as HTMLDivElement,
            this.intersectHandler
        )
    },
    methods: {
        intersectHandler(data: { target: Element; intersected: boolean }) {
            if (data.intersected) {
                this.intersected = true

                this.$scrollObserver.remove(data.target)
            }
        },
    },
})
