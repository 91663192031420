import { render, staticRenderFns } from "./Similar.vue?vue&type=template&id=b1785eb8&scoped=true&"
import script from "./Similar.vue?vue&type=script&lang=ts&"
export * from "./Similar.vue?vue&type=script&lang=ts&"
import style0 from "./Similar.vue?vue&type=style&index=0&id=b1785eb8&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1785eb8",
  null
  
)

export default component.exports