import { render, staticRenderFns } from "./Handler.vue?vue&type=template&id=4333412d&"
import script from "./Handler.vue?vue&type=script&lang=ts&"
export * from "./Handler.vue?vue&type=script&lang=ts&"
import style0 from "./Handler.vue?vue&type=style&index=0&id=4333412d&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CtaExtAndApp: require('/home/runner/work/okdescontos-front/okdescontos-front/components/cta/ExtAndApp.vue').default,CtaEmail: require('/home/runner/work/okdescontos-front/okdescontos-front/components/cta/Email.vue').default,CtaTelegram: require('/home/runner/work/okdescontos-front/okdescontos-front/components/cta/telegram.vue').default,CtaFacebook: require('/home/runner/work/okdescontos-front/okdescontos-front/components/cta/facebook.vue').default,CtaWhatsapp: require('/home/runner/work/okdescontos-front/okdescontos-front/components/cta/whatsapp.vue').default})
