
import Vue from 'vue'

export default Vue.extend({
    name: 'FormFieldPassword',
    props: {
        type: {
            type: String,
            default: 'text',
        },
        value: {
            type: String,
        },
        invalid: {
            type: [Boolean, String],
            default: false,
        },
    },
    data() {
        return {
            showPassword: false,
        }
    },
})
