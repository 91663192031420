
import Vue from 'vue'
import { getDictionary } from '~/libs/variant-dict'

export default Vue.extend({
    name: 'CardShareStepOne',
    props: {
        kind: {
            type: String as () => 'offer' | 'coupon',
        },
    },
    data() {
        const type = getDictionary(this.kind)
        return {
            type,
            verification: {
                url: false as boolean | string,
            },
            form: {
                url: '',
                code: '',
            },
            isShareDuplicated: false,
            loading: false,
            sharetabs: {
                show: 0,
                tabs: [
                    {
                        title: this.$util.lang.components.cardShareStepOne.share_offer,
                        to: `/${this.$util.lang.routes.share}/${this.$util.lang.routes.offer}`,
                        icon: {
                            active: this.$assets.primary.offer,
                            inactive: this.$assets.gray.offer,
                        },
                    },
                    {
                        title: this.$util.lang.components.cardShareStepOne.share_coupon,
                        to: `/${this.$util.lang.routes.share}/${this.$util.lang.routes.coupon}`,
                        icon: {
                            active: this.$assets.primary.coupons,
                            inactive: this.$assets.gray.cuponSoft,
                        },
                    },
                ],
            },
        }
    },
    computed: {},
    watch: {
        type: {
            handler(newValue: any) {
                if (newValue.variant === 'coupon') {
                    this.sharetabs.show = 1
                } else {
                    this.sharetabs.show = 0
                }
            },
            immediate: true,
        },
    },
    methods: {
        async handleNext(pass?: boolean) {
            this.loading = true
            const path = `compartir-${this.type.wordPlain}-crear`
            if (pass) {
                return this.$router.push({
                    name: path,
                })
            }

            if (this.form.url.length === 0) {
                this.verification.url = this.$util.lang.components.cardShareStepOne.insert_valid_url
                this.loading = false
                return
            }

            const res = await this.$api.general.useScrapper({ url: this.form.url, type: this.type.variant })

            if (res.feedback === 'params_validation_failed') {
                this.verification.url = res.data.url?.[0]
            } else if (res.feedback === 'data_success' || res.feedback === 'data_empty') {
                if (res.feedback === 'data_empty') {
                    this.$root.$emit('notification', {
                        duration: 3000,
                        dismissButtonText: this.$util.lang.components.cardShareStepOne.hide,
                        text: this.$util.lang.components.cardShareStepOne.image_not_found,
                        type: 'help',
                    })
                    return this.$router.push({
                        name: path,
                        query: {
                            url: this.form.url,
                            code: this.form.code,
                        },
                    })
                }
                return this.$router.push({
                    name: path,
                    query: {
                        url: this.form.url,
                        code: this.form.code,
                        images: res.data.images.toString(),
                    },
                })
            } else {
                this.$root.$emit('notification', {
                    duration: 3000,
                    dismissButtonText: this.$util.lang.components.cardShareStepOne.hide,
                    text: this.$util.lang.components.cardShareStepOne.error,
                    type: 'error',
                })
            }
            this.loading = false
        },
        handleCancel() {
            this.isShareDuplicated = false
            this.form.url = this.form.code = ''
        },
    },
})
