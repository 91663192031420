
import Vue from 'vue'

export default Vue.extend({
    name: 'ModalCookies',
    data() {
        return {
            cookies: [
                'COOKIE_STRICT_NECESSARY',
                'COOKIE_PERSONALIZATION',
                'COOKIE_FUNCTIONALS',
                'COOKIE_PERSONALIZATION_COPY',
            ],
        }
    },
    methods: {
        onSubmit(acceptall: boolean = false) {
            this.$emit('close')

            if (acceptall) {
                this.cookies.forEach((item) => {
                    localStorage.setItem(item, 'true')
                })
                this.$root.$emit('notification', {
                    duration: 5000,
                    dismissButtonText: this.$util.lang.components.modalCookiesCookies.hide,
                    text: this.$util.lang.components.modalCookiesCookies.cookies_saved,
                    type: 'success',
                })
            }
        },
        changeModal() {
            this.$store.commit('setModal', { name: 'ModalAdjustCookies' })
        },
    },
})
