import { render, staticRenderFns } from "./UserRanking.vue?vue&type=template&id=ca4b83bc&scoped=true&"
import script from "./UserRanking.vue?vue&type=script&lang=ts&"
export * from "./UserRanking.vue?vue&type=script&lang=ts&"
import style0 from "./UserRanking.vue?vue&type=style&index=0&id=ca4b83bc&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca4b83bc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroUserImage: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/userImage.vue').default,MicroBadge: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/Badge.vue').default})
