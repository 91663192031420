
import Vue from 'vue'
import decodeUriComponent from 'decode-uri-component'
import { MetaInfo } from 'vue-meta/types'
import { getDictionary } from '@/libs/variant-dict'

type Variants = 'coupon' | 'offer' | 'chat'

export default Vue.extend({
    middleware: ['ifAuthenticated', 'ifAcceptTerms'],
    data() {
        const variant = this.$route.query.variant as Variants
        const type = getDictionary(variant)
        return {
            type,
            variant,
            slug: this.$route.query.slug,
            title:
                typeof this.$route.query.title === 'string'
                    ? decodeUriComponent(this.$route.query.title)
                    : null,
            revision: this.$route.query.revision || false,
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.compartirFinalizado.title_seo
        const description = this.$util.lang.pages.compartirFinalizado.description_seo
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
})
