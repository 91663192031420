
import Vue, { PropOptions } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'SidebarUserRanking',
    props: {
        info: { type: Object } as PropOptions<{
            monthly: Models.User[]
            weekly: Models.User[]
        }>,
    },
    data() {
        return {
            filter: 'weekly' as 'weekly' | 'monthly',
        }
    },
    computed: {
        users(): Models.User[] {
            return this.info[this.filter]
        },
    },
})
