var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.users)?_c('section',{staticClass:"sidebar-user-ranking"},[_c('div',{staticClass:"content-head"},[_c('h3',{staticClass:"content-head__heading"},[_vm._v(_vm._s(_vm.$util.lang.components.sidebarUserRanking.rank))])]),_vm._v(" "),_c('div',{staticClass:"content-filter"},[_c('button',{staticClass:"content-filter__button",class:{ active: _vm.filter === 'weekly' },attrs:{"type":"button"},on:{"click":function($event){_vm.filter = 'weekly'}}},[_vm._v("\n            "+_vm._s(_vm.$util.lang.components.sidebarUserRanking.weekly)+"\n        ")]),_vm._v(" "),_c('button',{staticClass:"content-filter__button",class:{ active: _vm.filter === 'monthly' },attrs:{"type":"button"},on:{"click":function($event){_vm.filter = 'monthly'}}},[_vm._v("\n            "+_vm._s(_vm.$util.lang.components.sidebarUserRanking.monthly)+"\n        ")])]),_vm._v(" "),(_vm.users.length > 0)?_c('ul',{staticClass:"content-list"},_vm._l((_vm.users),function(user,index){return _c('li',{key:index},[_c('NLink',{staticClass:"content-user",class:'pos' + (index + 1),attrs:{"to":{ name: 'dashboard-user', params: { user: user.id } },"rel":"follow"}},[(user)?_c('div',{staticClass:"content-user__info"},[_c('div',{staticClass:"w-6 h-6"},[_c('MicroUserImage',{attrs:{"info":user}})],1),_vm._v(" "),_c('p',[_vm._v(_vm._s(user.username))]),_vm._v(" "),(user.role.name !== 'Usuario')?_c('MicroBadge',{attrs:{"text":user.role.name}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"content-user__rank"},[_c('div',{staticClass:"rank-medal"},[_c('p',{class:{
                                'text-site-primary': index === 0,
                                'text-site-secondary': index === 1,
                                'text-[#0867AC]': index === 2,
                            }},[_vm._v("\n                            0"+_vm._s(index + 1)+"\n                        ")]),_vm._v(" "),_c('img',{attrs:{"src":index === 0
                                    ? _vm.$assets.secondary.bookmark
                                    : index === 1 || index === 2
                                    ? _vm.$assets.primary.bookmark
                                    : _vm.$assets.gray.bookmark,"alt":"bookmark"}})])]),_vm._v(" "),_c('div',{staticClass:"content-user__statistic",class:{
                        'text-site-primary': index === 0,
                        'text-site-secondary': index === 1,
                        'text-[#0867AC]': index === 2,
                    }},[_c('p',[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(user.comments_count || 0))]),_vm._v("\n                        "+_vm._s(_vm.$util.lang.components.sidebarUserRanking.comments)+"\n                    ")]),_vm._v(" "),_c('p',[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(user.discounts_count || 0))]),_vm._v("\n                        "+_vm._s(_vm.$util.lang.components.sidebarUserRanking.discounts)+"\n                    ")])])])],1)}),0):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }