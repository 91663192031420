
import Vue from 'vue'
import { format, parseISO } from 'date-fns'
import { pt as locale } from 'date-fns/locale'
import { Api } from '~/global'

export default Vue.extend({
    name: 'DashboardPublic',
    async asyncData({ $api, store, error, $util, params: { user } }) {
        try {
            const userData = await $api.pages.loadUserProfile(user)

            if (userData.feedback === 'data_success') {
                store.commit('pages/setDashboard', userData.data)
            }

            if (userData.feedback === 'resource_not_found') {
                error({
                    statusCode: 404,
                    message: $util.lang.pages.dashboardParent.profile_not_found,
                })
            }
        } catch (e) {}
    },
    data() {
        const userData = this.$store.state.pages.dashboard.index as Api.Responses.Pages.UserProfile['data']
        /*  const coins = [
            {
                title: this.$util.lang.pages.dashboardParent.coins_available,
                coins: userData.statics.third_section.coins_available,
                icon: this.$assets.yellow.coin,
            },
            {
                title: this.$util.lang.pages.dashboardParent.coins_total,
                coins: userData.statics.third_section.collected_coins,
                icon: this.$assets.yellow.coinStack,
            },
            {
                title: this.$util.lang.pages.dashboardParent.awards,
                coins: userData.statics.third_section.awards_count,
                icon: this.$assets.yellow.coinPrize,
            },
        ] */
        const statistics = [
            {
                items: [
                    {
                        icon: this.$assets.primary.dashOffersAndCoupons,
                        count:
                            userData.statics.first_section.published_offers_count +
                            userData.statics.first_section.published_coupons_count,
                        text: this.$util.lang.pages.dashboardParent.discounts,
                    },
                    /* {
                        icon: this.$assets.primary.dashComments,
                        count: userData.statics.first_section.published_comments_count,
                        text: this.$util.lang.pages.dashboardParent.comments,
                    }, */
                    /* {
                        icon: this.$assets.primary.dashChat,
                        count: userData.statics.first_section.published_forums_count,
                        text: this.$util.lang.pages.dashboardParent.chats,
                    }, */
                    {
                        icon: this.$assets.primary.dashLike,
                        count: userData.statics.second_section.likes_in_comments_published_count,
                        text: this.$util.lang.pages.dashboardParent.likes_sended,
                    },
                ],
            },
        ]
        const accountState = {
            follow: false,
            notifications: false,
        }
        return {
            userData,
            /* coins, */
            statistics,
            accountState,
        }
    },
    computed: {
        memberSince(): string {
            return format(parseISO(this.userData.user.created_at || ''), "d 'de' MMMM 'del' yyyy", { locale })
        },
        discountsTotal(): number {
            const total =
                this.userData.statics.first_section.published_offers_count +
                this.userData.statics.first_section.published_coupons_count
            return total >= 100 ? 100 : total
        },
        commentsTotal(): number {
            const total = this.userData.statics.first_section.published_comments_count
            return total >= 100 ? 100 : total
        },
        discountsPercent(): number {
            return ((this.discountsTotal >= 25 ? 25 : this.discountsTotal) / 25) * 100
        },
        commentsPercent(): number {
            return ((this.commentsTotal >= 50 ? 50 : this.commentsTotal) / 50) * 100
        },
        userPercent(): number {
            return this.discountsPercent * 0.5 + this.commentsPercent * 0.5
        },
    },
})
