
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'
import { banners as bannersKeys } from '@/config/constants'

type BannerLS = {
    [key: string]: string
}

type Banner = {
    name: string
    info: Models.Banner
}

export default Vue.extend({
    name: 'LayoutHeaderBanner',
    props: {
        banner: {
            type: Object as PropType<Banner>,
            required: true,
        },
    },
    data() {
        return {
            mountBanner: false,
            areStylesMounted: false,
        }
    },
    computed: {
        mobile(): boolean {
            return process.browser ? /^(mb|xs|sm|md)$/.test(this.$bp.current) : false
        },
        desktop(): boolean {
            return process.browser ? /^(lg|xl)$/.test(this.$bp.current) : false
        },
        images(): { [key: string]: string } | null {
            if (this.$store.state.banners.up) {
                return {
                    mobile: this.$store.state.banners.up.info.images[0].url,
                    desktop: this.$store.state.banners.up.info.images[1].url,
                }
            } else return null
        },
    },
    watch: {
        banner: {
            immediate: true,
            handler(banner: Banner) {
                if (
                    banner &&
                    banner.info &&
                    ((banner.info.desktop && this.desktop) || (banner.info.mobile && this.mobile))
                )
                    this.mount(true)
            },
        },
        $route: {
            deep: true,
            handler() {
                try {
                    this.$emit('prevent-scroll-listeners')
                    document.body.classList.remove('add-banner-height')
                } catch (e) {
                    console.log('watching route log: ', e)
                }
                this.mountBanner = false
            },
        },
    },
    beforeMount() {
        window.addEventListener('scroll', this.handleScroll)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll(): void {
            const scrollPosition = window.scrollY
            if (this.mountBanner) {
                if (scrollPosition > 0 && this.areStylesMounted) {
                    this.unmountBannerStyles()
                } else if (scrollPosition <= 0 && !this.areStylesMounted) {
                    this.mountBannerStyles(350)
                }
            }
        },
        handleURL(): void {
            if (this.banner.info.external) {
                window.open(this.banner.info.url, '_blank', 'nofollow')
            } else {
                this.$router.push(this.banner.info.url)
            }
        },
        mountBannerStyles(timeout: number = 350): void {
            try {
                setTimeout(() => {
                    this.$emit('prevent-scroll-listeners')
                    document.body.classList.add('add-banner-height')
                }, timeout)
                this.areStylesMounted = true
            } catch (e) {
                console.log('mounting banner: ', e)
            }
        },
        unmountBannerStyles(): void {
            try {
                this.$emit('prevent-scroll-listeners')
                document.body.classList.remove('add-banner-height')
                this.areStylesMounted = false
            } catch (e) {
                console.log('unmounting banner: ', e)
            }
        },
        mount(mount: boolean) {
            const ls = localStorage.getItem(bannersKeys.upBanner)

            const parseLS = ls ? (JSON.parse(ls) as BannerLS) : null

            if (mount) {
                if (
                    !parseLS ||
                    !parseLS[this.banner.info.id] ||
                    parseLS[this.banner.info.id] !== this.banner.info.updated_at
                ) {
                    this.$nextTick(() => {
                        this.mountBannerStyles()
                        this.mountBanner = true
                    })
                }
            } else {
                this.$nextTick(() => {
                    this.unmountBannerStyles()
                    this.mountBanner = false
                })
                if (parseLS) {
                    parseLS[this.banner.info.id] = this.banner.info.updated_at

                    localStorage.setItem(bannersKeys.upBanner, JSON.stringify(parseLS))
                } else {
                    const newLS = {
                        [this.banner.info.id]: this.banner.info.updated_at,
                    }
                    localStorage.setItem(bannersKeys.upBanner, JSON.stringify(newLS))
                }
            }
        },
    },
})
