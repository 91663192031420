
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { Api } from '~/global'
import { currentDate } from '~/libs/date-functions'

export default Vue.extend({
    name: 'Offers',
    async asyncData({ $api, store }) {
        const offers = await $api.pages.loadOffers()
        store.commit('pages/setValue', {
            name: 'offers',
            page: offers,
        })
    },
    data() {
        const info = this.$store.state.pages.offers as Api.Responses.Pages.Offers

        const currentDates = currentDate()

        const [title, description] = this.$util.replacePlaceholders(
            [
                [':month', currentDates.month || ''],
                [':day', String(currentDates.day) || ''],
                [':year', String(currentDates.year) || ''],
                [':site', process.env.SITE_NAME || ''],
            ],
            [info.page.title || '', info.page.description || '']
        )
        return {
            metaInfo: { title, description },
            pagination: {
                popular: {
                    loading: false,
                    show: true,
                },
                latest: {
                    loading: false,
                    show: true,
                },
                most_comment: {
                    loading: false,
                    show: true,
                },
            },
            info,
            offers: {
                show: 0,
                tabs: [
                    { title: this.$util.lang.pages.ofertas.populars },
                    { title: this.$util.lang.pages.ofertas.news },
                    { title: this.$util.lang.pages.ofertas.commented },
                ],
            },
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                ],
                this.metaInfo?.title
            ),
        }
    },
    computed: {
        breadcrumbs(): { path: string; title: string }[] {
            return [
                {
                    path: '/',
                    title: this.$util.lang.pages.ofertas.home,
                },
                {
                    path: '',
                    title: this.$util.lang.pages.ofertas.offers,
                },
            ]
        },
        getType(): 'popular' | 'latest' | 'most_comment' {
            return this.offers.show === 0 ? 'popular' : this.offers.show === 1 ? 'latest' : 'most_comment'
        },
    },
    mounted() {
        setTimeout(() => {
            this.$nextTick(() => {
                this.info.page.banners && this.$store.dispatch('assignBanners', this.info.page.banners)
            })
        }, 2000)
    },
    methods: {
        loadMore(): void {
            this.pagination[this.getType].loading = true
            let next = 0
            const copyInfo = JSON.parse(JSON.stringify(this.info))

            if (this.offers.show === 0) {
                // Popular
                next = copyInfo.page.data.popular.meta.current_page + 1
                // Nuevos
            } else if (this.offers.show === 1) {
                next = copyInfo.page.data.latest.meta.current_page + 1
                // Comentados
            } else {
                next = copyInfo.page.data.most_comment.meta.current_page + 1
            }

            this.$api.pages
                .loadOffers({ page: next })
                .then((res: any) => {
                    // Populares
                    if (this.offers.show === 0) {
                        copyInfo.page.data.popular.data.push(...res.page.data.popular.data)
                        copyInfo.page.data.popular.meta = res.page.data.popular.meta
                        // Nuevos
                    } else if (this.offers.show === 1) {
                        copyInfo.page.data.latest.data.push(...res.page.data.latest.data)
                        copyInfo.page.data.latest.meta = res.page.data.latest.meta
                        // Comentados
                    } else {
                        copyInfo.page.data.most_comment.data.push(...res.page.data.most_comment.data)
                        copyInfo.page.data.most_comment.meta = res.page.data.most_comment.meta
                    }

                    this.info = copyInfo

                    this.pagination[this.getType] = {
                        loading: false,
                        show: next < this.info.page.data[this.getType].meta.last_page,
                    }
                })
                .catch((err: any) => {
                    console.error('error', err)
                    this.pagination[this.getType].loading = true
                })
        },
        currentTab(key: any) {
            this.offers.show = key
        },
    },
})
