
import Vue from 'vue'
import { set as setCookie } from 'js-cookie'
import { cookies } from '~/config/constants'

export default Vue.extend({
    name: 'FormLogin',
    data() {
        return {
            rememberMe: false,
            newsAccept: false,
            form: {
                data: {
                    username: '',
                    password: '',
                },
                validationStatus: {
                    username: false as string | boolean,
                    password: false as string | boolean,
                },
            },
        }
    },
    computed: {
        rrss(): {
            text: string
            icon: string
            iconAlt: string
            handler: any
        }[] {
            return [
                {
                    text: this.$util.lang.components.formLogin.login_google,
                    icon: this.$assets.social.googleAccount,
                    iconAlt: 'Google button',
                    handler: () => this.socialSignIn('google'),
                },
                {
                    text: this.$util.lang.components.formLogin.login_facebook,
                    icon: this.$assets.social.facebookAccount,
                    iconAlt: 'Facebook button',
                    handler: () => this.socialSignIn('facebook'),
                },
            ]
        },
    },
    mounted() {
        if (this.$route.query?.action) {
            localStorage.setItem('action', this.$route.query?.action.toString())
        }
    },
    methods: {
        async signIn() {
            this.$nuxt.$loading.start()

            const result = await this.$api.auth.signIn('withPassword', this.form.data)

            if (result.feedback === 'params_validation_failed') {
                this.form.validationStatus = {
                    username: result.data.username?.[0] || false,
                    password: result.data.password?.[0] || false,
                }
            }

            if (result.feedback === 'token_created_successfull') {
                setCookie(cookies.access_token_key, result.data.access_token, {
                    expires: result.data.expires_at,
                })

                setCookie(cookies.token_expires_at_key, result.data.expires_at.toString(), {
                    expires: result.data.expires_at,
                })

                this.$store.commit('auth/setAuthData', result.data)

                this.$store.commit('auth/setAuthenticationStatus', true)

                const user = await this.$api.user.profile()

                if (user.feedback === 'data_success') {
                    this.$store.commit('user/setProfile', user.data.user)
                }

                if (!this.$route.query?.action) {
                    this.$router.push({ name: 'index' })
                } else if (this.$route.query?.action) {
                    this.$router.push({
                        name: 'preferences',
                        query: { action: this.$route.query.action },
                        hash: '#notificaciones',
                    })
                }

                this.$root.$emit('notification', {
                    duration: 5000,
                    dismissButtonText: this.$util.lang.components.formLogin.hide,
                    text: this.$util.lang.components.formLogin.welcome,
                    type: 'success',
                })
            }

            this.$nuxt.$loading.finish()
        },
        socialSignIn(strategy: 'google' | 'facebook') {
            this.$nuxt.$loading.start()
            try {
                this.$api.auth.socialSingIn(strategy, {
                    success_url: location.origin + location.pathname,
                    error_url: location.origin + location.pathname,
                })
            } catch (e) {
                this.$nuxt.$loading.finish()
            }
        },
    },
})
