
import Vue from 'vue'

export default Vue.extend({
    name: 'FieldPrice',
    props: {
        isDisabled: {
            type: Boolean,
        },
        priceoffer: {
            type: String,
        },
        priceregular: {
            type: String,
        },
        shipcost: {
            type: String,
        },
        freeship: {
            type: Boolean,
        },
        invalid: {
            type: [Boolean, String],
            default: false,
        },
    },

    data() {
        return {
            isFocus: [false],
            freeshiping: false,
            freediscount: false,
            costshipLocked: false,
            discountLocked: false,
        }
    },
    computed: {},
    watch: {
        freediscount: {
            handler(newval): void {
                if (newval) {
                    this.$emit('update:priceoffer', '0')
                    this.$emit('update:priceregular', '')
                    this.discountLocked = true
                } else {
                    this.discountLocked = false
                    this.$emit('update:priceoffer', '')
                    this.$emit('update:priceregular', '')
                }
            },
            immediate: true,
        },
        freeshiping: {
            handler(newval): void {
                if (newval) {
                    this.$emit('update:shipcost', '0')
                    this.costshipLocked = true
                } else {
                    this.$emit('update:freeship', newval)
                    this.$emit('update:shipcost', '')
                    this.costshipLocked = false
                }
            },
            immediate: true,
        },
        priceoffer: {
            handler(newval): void {
                if (newval === '0' && this.priceregular === '0') {
                    this.freediscount = true
                }
                this.$emit('update:priceoffer', newval.replace(/\D/g, ''))
            },
        },
        priceregular: {
            handler(newval): void {
                if (newval === '0' && this.priceoffer === '0') {
                    this.freediscount = true
                }
                this.$emit('update:priceregular', newval.replace(/\D/g, ''))
            },
        },
        shipcost: {
            handler(newval): void {
                if (this.freeshiping === true) {
                    this.$emit('update:shipcost', '0')
                } else {
                    const newValue = newval.replace(/\D/g, '')
                    this.$emit('update:shipcost', newValue)
                }
            },
        },
    },
    methods: {
        handleFocus(key: number, value: boolean): void {
            Vue.set(this.isFocus, key, value)
        },
    },
})
