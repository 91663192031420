
import Vue from 'vue'

export default Vue.extend({
    name: 'DropdownMaincard',
    props: {
        options: {
            type: Array as () => Array<{
                text: string
                title: string
                icon: string
                style: string
            }>,
            default: () => [],
        },
        width: {
            type: String,
            default: '',
        },
    },
})
