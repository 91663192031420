
import Vue, { PropType } from 'vue'
import { Sidebar } from '~/types/sidebar'
import { getDictionary } from '~/libs/variant-dict'

export default Vue.extend({
    name: 'SidebarSimilar',
    props: {
        title: {
            type: String,
        },
        model: {
            type: String,
        },
        variant: {
            type: String as PropType<'offer' | 'coupon'>,
            required: true,
        },
        info: {
            type: Array as PropType<Sidebar.Xsimilares[]>,
        },
    },
    data() {
        const type = getDictionary(this.variant)
        return { type }
    },
    computed: {},
})
