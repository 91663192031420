
import Vue, { PropType } from 'vue'

export default Vue.extend({
    name: 'ModalImageZoom',
    props: {
        info: Object as PropType<{
            image: {
                type: String
                default: ''
            }
        }>,
    },
    methods: {
        updateModal() {
            this.$emit('close', false)
        },
    },
})
