
import Vue, { PropType } from 'vue'
import {
    parseISO,
    isBefore,
    differenceInMinutes,
    isToday,
    isTomorrow,
    formatDistanceToNowStrict,
} from 'date-fns'
import { es as locale } from 'date-fns/locale'
import { formatInTimeZone } from 'date-fns-tz'
import { Models } from '~/types/models'
import { getDateDifference } from '~/libs/date-functions'

export default Vue.extend({
    name: 'CardMainCoupons',
    props: {
        info: {
            type: Object as PropType<Models.Discount>,
        },
        storeInfo: {
            type: Object as PropType<Models.Store>,
        },
        showBrandLogo: {
            type: Boolean,
        },
    },
    data() {
        return {
            getDateDifference,
            dropdown: {
                show: {
                    text: false,
                },
            },
            success: Number(this.info.success_percentage),
        }
    },

    computed: {
        isExpired(): boolean {
            return this.info.expired_at ? isBefore(parseISO(this.info.expired_at), new Date()) : false
        },
        updatedDate(): string {
            const date = this.info.updated_at && parseISO(this.info.updated_at)

            return date ? formatInTimeZone(date, 'America/Mexico_City', 'dd/MM/yyyy') : ''
        },
        refreshedAt30MinsAgo(): number {
            const date = this.info.updated_at && parseISO(this.info.updated_at)
            const difference = !!date && differenceInMinutes(new Date(), date)

            return (difference && difference > 0 && difference < 30 && difference) || 0
        },
        endingDate(): string {
            const date = this.info.expired_at && parseISO(this.info.expired_at)

            return date ? formatInTimeZone(date, 'America/Mexico_City', 'dd/MM/yyyy') : ''
        },
        endToday(): boolean {
            return !!this.info.expired_at && isToday(parseISO(this.info.expired_at))
        },
        endTomorrow(): boolean {
            return !!this.info.expired_at && isTomorrow(parseISO(this.info.expired_at))
        },
        endInLessThanTwoDays(): boolean {
            return this.endToday || this.endTomorrow
        },
        createdAtDate(): string | false {
            const date = this.info.created_at && parseISO(this.info.created_at)

            return date ? formatInTimeZone(date, 'America/Mexico_City', 'dd/MM/yyyy') : false
        },
        startingDate(): string | false {
            const date = this.info.start_at && parseISO(this.info.start_at)

            return date ? formatInTimeZone(date, 'America/Mexico_City', 'dd/MM/yyyy') : false
        },
        lastUse(): string | false {
            return this.info.last_use
                ? formatDistanceToNowStrict(parseISO(this.info.last_use), {
                      locale,
                  })
                : false
        },
        timestamp(): string | boolean {
            if (!this.info.updated_at && !this.info.created_at) return false
            const timestamp = formatDistanceToNowStrict(
                parseISO(this.info.updated_at || this.info.created_at || '')
            )
            return timestamp.split(' ')[0] + timestamp.split(' ')[1]?.charAt(0)
        },
        createdAt(): Date {
            return parseISO(this.info.created_at)
        },
        updatedAt(): Date | null {
            return this.info.updated_at ? parseISO(this.info.updated_at) : null
        },
        areTimestampsEqual(): boolean {
            return (
                !!this.createdAt &&
                !!this.updatedAt &&
                getDateDifference(this.createdAt) === getDateDifference(this.updatedAt)
            )
        },
        showNewPrice(): boolean {
            return (
                ((this.info.discount_type === 'precio' || this.info.discount_type === 'porcentaje') &&
                    this.info.discount_value !== '0') ||
                this.info.discount_type === 'envío gratis'
            )
        },
        newCouponPrice(): string {
            return this.info.discount_type === 'envío gratis'
                ? this.$util.lang.components.cardMainCoupons.free_ship
                : this.info.discount_type === 'precio'
                ? this.$util.lang.symbol + this.info.discount_value + ' OFF'
                : this.info.discount_value === '100'
                ? this.$util.lang.components.cardMainCoupons.free
                : this.info.discount_value + '% OFF'
        },
    },
    mounted() {
        if (this.info.slug === this.$route.query.slug) {
            const $el = this.$refs.coupon as HTMLElement
            document.documentElement.style.scrollBehavior = 'smooth'
            document.documentElement.scrollTop = $el.getBoundingClientRect().top - 12
            document.documentElement.style.scrollBehavior = 'auto'
        }
    },
    methods: {
        switchDescription() {
            this.dropdown.show.text = !this.dropdown.show.text
        },
        openCoupon() {
            if (!this.$route.query.slug) {
                this.$openCoupon(this.info.slug, this.info.store?.slug || this.storeInfo.slug)
            } else {
                this.$openCoupon(this.info.slug)
            }
        },
        setNewSuccess(success: string | number) {
            this.success = Number(success)
        },
    },
})
