
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'
import { defaultImage } from '~/libs/useful-functions'

export default Vue.extend({
    name: 'CardFeaturedStore',
    props: {
        info: Object as PropType<Models.Store>,
    },
    data() {
        return {
            defaultImage,
        }
    },
})
