import { render, staticRenderFns } from "./FeaturedGrid.vue?vue&type=template&id=77f2f1a7&scoped=true&"
import script from "./FeaturedGrid.vue?vue&type=script&lang=ts&"
export * from "./FeaturedGrid.vue?vue&type=script&lang=ts&"
import style0 from "./FeaturedGrid.vue?vue&type=style&index=0&id=77f2f1a7&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77f2f1a7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardFeatured: require('/home/runner/work/okdescontos-front/okdescontos-front/components/card/Featured.vue').default})
