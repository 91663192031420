
import Vue, { PropType } from 'vue'
import { formatDistanceToNowStrict, parseISO } from 'date-fns'
import { pt as locale } from 'date-fns/locale'
import { DropdownOptionType } from '../dropdown/Options.vue'
import { Models } from '~/types/models'
import { Api, ServicesQueryParameters } from '~/global'
import { removeXSS, setURLS } from '~/libs/useful-functions'

export default Vue.extend({
    name: 'CardComment',
    props: {
        comment: {
            type: Object as PropType<Models.Comment>,
        },
        parentId: Number,
    },
    data() {
        return {
            removeXSS,
            setURLS,
            pulsesActivated: false,
            liked: Boolean(this.comment.liked),
            show: {
                replyForm: false,
                commentMenu: false,
                loadComments: true,
            },
            disable: {
                like: Boolean(this.comment.liked),
                loadComments: false,
                commentForm: false,
            },
            nextPageQuery: {
                child_of: this.comment.id,
                per_page: 6,
                page: 1,
                order_by: 'most-comment',
            } as ServicesQueryParameters.GetComments,
        }
    },
    computed: {
        timestamp(): string {
            const timestamp =
                (this.comment.created_at &&
                    formatDistanceToNowStrict(parseISO(this.comment.created_at), { locale })) ||
                ''
            const shortTimestamp = timestamp.split(' ')[0] + timestamp.split(' ')[1]?.charAt(0)

            return shortTimestamp
        },
        menuOptions(): DropdownOptionType[] {
            return [
                {
                    title: this.$util.lang.components.cardComment.copy_link_to_comment,
                    icon: this.$assets.primary.referralLink,
                    handler: () => {
                        this.$copyText(
                            window.location.host +
                                window.location.pathname +
                                `#${this.parentId ? 'reply' : 'comment'}-${this.comment.id}`
                        )
                    },
                },
                {
                    title: this.$util.lang.components.cardComment.report,
                    icon: this.$assets.primary.shield,
                    description: this.$util.lang.components.cardComment.why_report,
                    subDropdown: true,
                    options: [
                        {
                            title: this.$util.lang.components.cardComment.spam_offensive,
                            icon: this.$assets.primary.span,
                            handler: () => {
                                this.createReport('Spam/Ofensivo')
                            },
                        },
                        {
                            title: this.$util.lang.components.cardComment.abuse_hate,
                            icon: this.$assets.gray.dislike,
                            handler: () => {
                                this.createReport('Acoso o incitación al odio')
                            },
                        },
                    ],
                },
            ]
        },
    },
    mounted() {
        this.$root.$once('active-comment-pulses', this.activePulses)
    },
    destroyed() {
        this.$root.$off('active-comment-pulses', this.activePulses)
    },
    methods: {
        activePulses(id: number) {
            this.pulsesActivated = id === this.comment.id
        },
        async createReport(reason: Parameters<Api.Services['create']['postReport']>[0]['reason']) {
            try {
                const reportResult = await this.$api.create.postReport({
                    reportable_type: 'comment',
                    reportable_id: this.comment.id,
                    reason,
                })

                if (reportResult && reportResult.feedback === 'data_success') {
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.components.cardComment.okay,
                        text: this.$util.lang.components.cardComment.report_success,
                        type: 'success',
                    })
                } else {
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.components.cardComment.close,
                        text: this.$util.lang.components.cardComment.report_failed,
                        type: 'error',
                    })
                }
            } catch (e) {}
        },
        async handleLike() {
            this.disable.like = true

            try {
                const likeResult = await this.$api.create.postLike({
                    likeable_type: 'comment',
                    likeable_id: this.comment.id,
                    type: 1,
                })

                if (likeResult && likeResult.feedback === 'data_success') {
                    this.liked = true
                }
            } catch (e) {}

            this.disable.like = true
        },
        async handleComment(reply: { callback: (clear: boolean) => void; html: string }) {
            this.disable.commentForm = true

            try {
                const commentResult = await this.$api.create.postComment({
                    commentable_id: this.comment.id,
                    commentable_type: 'comment',
                    content: reply.html,
                })

                if (commentResult && commentResult.feedback === 'resource_created') {
                    this.show.replyForm = false

                    this.$emit('reply', commentResult.data)

                    this.$root.$emit('notification', {
                        duration: 4000,
                        dismissButtonText: this.$util.lang.components.cardComment.hide,
                        text: this.$util.lang.components.cardComment.answer_published,
                        type: 'success',
                    })

                    reply.callback(true)
                } else if (commentResult && commentResult.feedback === 'resource_needs_approval') {
                    this.$root.$emit('notification', {
                        duration: 4000,
                        dismissButtonText: this.$util.lang.components.cardComment.hide,
                        text: this.$util.lang.components.cardComment.answer_pending,
                        type: 'help',
                    })

                    reply.callback(true)
                } else {
                    reply.callback(false)
                }
            } catch (e) {}
            this.disable.commentForm = false
        },
        commentMenuToggle(show: boolean): void {
            if (this.$bp.mb || this.$bp.xs || this.$bp.sm) {
                this.$store.commit('setDropdown', show ? this.menuOptions : null)
            } else {
                this.show.commentMenu = show
            }
        },
        replyFormToggle(show: boolean) {
            this.show.replyForm = show
        },
    },
})
