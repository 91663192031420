
import Vue from 'vue'

type postIndex = {
    id: string
    text: string
}

export default Vue.extend({
    props: {
        content: {
            type: String,
        },
    },
    data() {
        return { postIndex: [] as postIndex[], isIndexAvailable: false }
    },
    mounted() {
        this.getTextIndex()
    },
    methods: {
        gotoPostId(id: string) {
            this.$scrollTo(id)
        },
        getTextIndex(): void {
            if (this.$refs.post) {
                const h2 = (this.$refs.post as HTMLElement).querySelectorAll('h2')
                // check if h2 element has text, then get innerText and id and creates a object with it, and sets an id to each h2
                if (h2.length > 0) {
                    h2.forEach((el: HTMLElement, key: number) => {
                        if (el.innerText.length > 0) {
                            const id = 'post-' + key
                            el.id = id
                            this.postIndex.push({
                                id,
                                text: el.innerText,
                            })
                        }
                    })
                }
                if (this.postIndex.length > 0) this.isIndexAvailable = true
            }
        },
    },
})
