
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'

export default Vue.extend({
    data() {
        return {
            items: [
                {
                    logo: this.$assets.illustration.share.offer,
                    title: this.$util.lang.pages.compartir.offer,
                    description: this.$util.lang.pages.compartir.share_offer,
                    link: `/${this.$util.lang.routes.share}/${this.$util.lang.routes.offer}`,
                },
                {
                    logo: this.$assets.illustration.share.coupon,
                    title: this.$util.lang.pages.compartir.coupon,
                    description: this.$util.lang.pages.compartir.share_coupon,
                    link: `/${this.$util.lang.routes.share}/${this.$util.lang.routes.coupon}`,
                },
                {
                    logo: this.$assets.illustration.share.chat,
                    title: this.$util.lang.pages.compartir.chat,
                    description: this.$util.lang.pages.compartir.open_thread,
                    link: `/${this.$util.lang.routes.share}/${this.$util.lang.routes.chat}`,
                },
            ],
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.compartir.title_seo
        const description = this.$util.lang.pages.compartir.description_seo
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
})
