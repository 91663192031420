
import Vue, { PropOptions } from 'vue'

export default Vue.extend({
    name: 'ModalAuthEmailVerification',
    props: {
        info: Object as PropOptions<{
            isPage: boolean
            message: string | null
            isForUserVerification: boolean
        }>,
    },
    data() {
        return {
            email: {
                loading: false,
                sent: null as boolean | null,
                error: null as boolean | null,
            },
        }
    },
    methods: {
        async sendVerificationMail() {
            this.email.loading = true
            this.email.error = false

            const result = await this.$api.user.requestAccountVerification()
            if (result.feedback === 'data_updated_success') {
                this.email.sent = true
                this.email.error = false
            } else {
                this.email.sent = false
                this.email.error = true
            }
            this.email.loading = false
        },
    },
})
