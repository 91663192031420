
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'MicroStoreServerContent',
    props: {
        content: Object as PropType<Models.Content>,
    },
})
