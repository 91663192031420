
import Vue, { PropOptions } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'ModalDashboardEmail',
    props: {
        info: Object as PropOptions<{
            callback: (success: boolean) => void
        }>,
    },
    data() {
        const form = {
            data: {
                email: '',
            },
            validationStatus: {
                email: false as boolean | string,
            },
        }

        return {
            loading: false,
            form,
        }
    },
    computed: {
        userData(): Models.User {
            return this.$store.state.user.profile
        },
    },
    methods: {
        cancelHandler() {
            this.$emit('close')
        },
        async continueHandler() {
            this.loading = true
            await this.updateProfile()
            this.loading = false
        },
        async updateProfileInfo() {
            try {
                const userData = await this.$api.pages.loadUserProfile(this.userData.id)

                if (userData.feedback === 'data_success') {
                    this.$store.commit('pages/setDashboard', userData.data)
                }
            } catch (e) {}
        },
        async updateProfile() {
            const profileBody = {} as { [key: string]: any }

            !!this.form.data.email && (profileBody.email = this.form.data.email)

            try {
                const profileResult = await this.$api.user.updateProfile(
                    String(this.userData.id),
                    profileBody
                )

                if (profileResult.feedback === 'data_updated_success') {
                    await this.updateProfileInfo()

                    this.$root.$emit('notification', {
                        type: 'success',
                        text: this.$util.lang.components.modalDashboardEmail.profile_updated,
                        duration: 4000,
                    })
                    this.$emit('close')
                } else if (
                    profileResult.feedback === 'data_error' ||
                    profileResult.feedback === 'params_validation_failed'
                ) {
                    this.form.validationStatus.email = profileResult.data.email[0] || false
                }
            } catch (e) {}
        },
    },
})
