
import encodeUrl from 'encodeurl'
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'

export default Vue.extend({
    name: 'CreateChat',
    middleware: ['ifAuthenticated', 'ifAcceptTerms'],
    data() {
        return {
            loading: false,
            validationStatus: {
                title: false as boolean | string,
                description: false as boolean | string,
                forum_category_id: false as boolean | string,
            },
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.compartirChat.title_seo
        const description = this.$util.lang.pages.compartirChat.description_seo
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
    methods: {
        setValidations(name: 'title' | 'description', status: boolean | string = false) {
            this.validationStatus[name] = status
        },
        async makePost({ title, description, forumCategoryId }: any) {
            this.loading = true

            const newChat = { title, description, forum_category_id: forumCategoryId }
            const response = await this.$api.create.postChat(newChat)

            if (!response) {
                this.$root.$emit('notification', {
                    duration: 5000,
                    text: this.$util.lang.pages.compartirChat.error_on_create,
                    type: 'error',
                })
            } else if (
                response.feedback === 'error_fields_validation' ||
                response.feedback === 'params_validation_failed'
            ) {
                this.validationStatus.title = response.data.title?.[0] || false
                this.validationStatus.description = response.data.description?.[0] || false
                this.validationStatus.forum_category_id = response.data.forum_category_id?.[0] || false

                this.$scrollTo(`#${Object.keys(response.data)[0]}`)
            } else if (response.feedback === 'resource_created') {
                this.$router.push({
                    path: `/${this.$util.lang.routes.share}/${
                        this.$util.lang.routes.ended
                    }?variant=chat&revision=false&slug=${response.data.forum.slug}&title=${encodeUrl(
                        response.data.forum.title
                    )}`,
                    params: {
                        variant: 'chat',
                        slug: response.data.forum.slug,
                    },
                })
            } else if (response.feedback === 'resource_needs_approval') {
                this.$router.push({
                    path: `/${this.$util.lang.routes.share}/${
                        this.$util.lang.routes.ended
                    }?variant=chat&revision=true&title=${encodeUrl(response.data.forum.title)}`,
                    params: {
                        variant: 'chat',
                        slug: response.data.forum.slug,
                    },
                })
            }
            this.loading = false
        },
    },
})
