
import Vue from 'vue'

export default Vue.extend({
    name: 'Giphy',
    props: {
        position: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            search: '',
            itemsPerView: 20,
            totalPages: 0,
            currentPage: 0,
            timeOutToSearch: null as null | NodeJS.Timeout,
            results: [] as any[],
        }
    },
    computed: {
        url(): string {
            return (
                'https://api.giphy.com/v1/gifs' +
                (this.search.length > 0 ? '/search?q=' + this.search : '/trending')
            )
        },
    },
    watch: {
        search() {
            if (this.timeOutToSearch) clearTimeout(this.timeOutToSearch)
            this.timeOutToSearch = setTimeout(() => this.getGiphy(), 700)
        },
        currentPage() {
            this.getGiphy()
        },
    },
    mounted() {
        this.getGiphy()
    },
    methods: {
        prev() {
            this.currentPage = this.currentPage > 0 ? this.currentPage - 1 : this.currentPage
        },
        next() {
            this.currentPage =
                this.currentPage + 1 > this.totalPages ? this.currentPage : this.currentPage + 1
        },
        getGiphy() {
            return this.$axios
                .$get(this.url, {
                    params: {
                        api_key: '6cVePkrnDbRSHo5QSRm2EHFeEr0OFfHA',
                        limit: this.itemsPerView,
                        offset: this.currentPage * this.itemsPerView,
                    },
                })
                .then((result) => {
                    if (result.pagination.total_count)
                        this.totalPages = Math.ceil(result.pagination.total_count / this.itemsPerView)

                    this.results = result.data.map((gif: any) => {
                        return {
                            id: gif.id,
                            url: gif.images.downsized.url,
                            preview: gif.images.preview_gif.url,
                            alt: gif.title,
                        }
                    })
                })
        },
    },
})
