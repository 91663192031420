import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=561758c1&scoped=true&"
import script from "./_slug.vue?vue&type=script&lang=ts&"
export * from "./_slug.vue?vue&type=script&lang=ts&"
import style0 from "./_slug.vue?vue&type=style&index=0&id=561758c1&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "561758c1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardAlertMessage: require('/home/runner/work/okdescontos-front/okdescontos-front/components/card/AlertMessage.vue').default,MicroBreadcrumbs: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/Breadcrumbs.vue').default,CardSliderEventFeaturedStores: require('/home/runner/work/okdescontos-front/okdescontos-front/components/card/slider/event/FeaturedStores.vue').default,CardSliderEventFeaturedDiscounts: require('/home/runner/work/okdescontos-front/okdescontos-front/components/card/slider/event/FeaturedDiscounts.vue').default,CtaHandler: require('/home/runner/work/okdescontos-front/okdescontos-front/components/cta/Handler.vue').default,CardMainGlobal: require('/home/runner/work/okdescontos-front/okdescontos-front/components/card/main/Global.vue').default,MicroLazy: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/Lazy.vue').default,BtnSeeMoreResults: require('/home/runner/work/okdescontos-front/okdescontos-front/components/btn/SeeMoreResults.vue').default,MicroNoResults: require('/home/runner/work/okdescontos-front/okdescontos-front/components/micro/NoResults.vue').default})
