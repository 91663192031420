
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { Models } from '~/types/models'
import { Helpers } from '~/types/helpers'

export default Vue.extend({
    name: 'Notifications',
    middleware: ['ifAuthenticated'],
    async asyncData({ $api, store }) {
        const userid = store.state.user.profile.id

        const read = await $api.general.loadNotifications({ user_id: userid, read: true, per_page: 5 })
        const no_read = await $api.general.loadNotifications({ user_id: userid, read: false, per_page: 5 })

        if (read.feedback === 'data_success') {
            if (no_read.feedback === 'data_success') {
                const readed = {
                    notifications: read.data.results,
                    pagination: read.data.meta,
                    info: read,
                }
                const no_readed = {
                    notifications: no_read.data.results,
                    pagination: no_read.data.meta,
                    info: no_read,
                }

                return { readed, no_readed }
            }
        }
    },
    data() {
        const userid = this.$store.state.user.profile.id
        return {
            info: {} as { title: string; description: string },
            userid,
            readed: {
                notifications: {} as Models.Notification[],
                pagination: {} as Helpers.Meta,
                info: {},
            },
            no_readed: {
                notifications: {} as Models.Notification[],
                pagination: {} as Helpers.Meta,
                info: {},
            },
            loading: {
                readed: false,
                no_readed: false,
            },
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.notificaciones.title_seo
        const description = this.$util.lang.pages.notificaciones.description_seo
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
    computed: {
        nextPage(): { no_readed: number | null; readed: number | null } {
            let readed = 0 as number | null
            let no_readed = 0 as number | null
            if (this.readed.pagination.current_page < this.readed.pagination.last_page) {
                readed = this.readed.pagination.current_page + 1
            } else readed = null
            if (this.no_readed.pagination.current_page < this.no_readed.pagination.last_page) {
                no_readed = this.no_readed.pagination.current_page + 1
            } else no_readed = null
            return { readed, no_readed }
        },
    },
    mounted() {
        this.markAsRead(this.no_readed.notifications)
    },
    methods: {
        removeItem(id: number, type: boolean = false): void {
            this.no_readed.notifications = this.no_readed.notifications.filter(
                (item: Models.Notification) => {
                    if (type && id === item.models[0].model_id) {
                        return false
                    }
                    return item.id !== id
                }
            )
        },
        async markAsRead(notifications: Models.Notification[]) {
            const bodyFormData = new FormData()
            notifications.forEach((notification: Models.Notification) => {
                if (notification.read_at === null) {
                    bodyFormData.append('ids[]', notification.id.toString())
                }
            })

            if (bodyFormData.has('ids[]')) {
                const response = await this.$api.notifications.markAsRead(bodyFormData)
                if (response.feedback === 'data_updated_success') {
                    this.$store.dispatch('user/getNotificationsCount', this.$nuxt)
                }
            }
        },
        getMoreNotifications(readed: boolean): void {
            const nextPage = readed ? this.nextPage.readed : this.nextPage.no_readed
            if (nextPage !== null) {
                if (readed) {
                    this.loading.readed = true
                } else {
                    this.loading.no_readed = true
                }
                this.$api.general
                    .loadNotifications({ user_id: this.userid, page: nextPage, read: readed, per_page: 5 })
                    .then((response) => {
                        if (response.feedback === 'data_success') {
                            if (readed) {
                                this.readed.notifications = [
                                    ...this.readed.notifications,
                                    ...response.data.results,
                                ]
                                this.readed.pagination = response.data.meta
                            } else {
                                this.no_readed.notifications = [
                                    ...this.no_readed.notifications,
                                    ...response.data.results,
                                ]
                                this.no_readed.pagination = response.data.meta
                                this.markAsRead(response.data.results)
                            }
                        } else {
                            this.$root.$emit('notification', {
                                type: 'error',
                                message: this.$util.lang.pages.notificaciones.error,
                            })
                        }
                        this.loading.readed = false
                        this.loading.no_readed = false
                    })
            }
        },
    },
})
