
import Vue from 'vue'
import { PropType } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    props: {
        store: Object as PropType<Models.Store>,
    },
})
