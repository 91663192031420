
import Vue from 'vue'
import StoreDetails from '@/views/StoreDetails.vue'
import StoresList from '@/views/StoresList.vue'

export default Vue.extend({
    name: 'StoresBridge',
    components: {
        StoreDetails,
        StoresList,
    },
    async validate({ store, $api, error, params: { slug }, query, $util }) {
        if (/^(a|b|c|d|e|f|g|h|i|j|k|l|m|n|ñ|o|p|q|r|s|t|u|v|w|x|y|z)$/.test(slug) || !slug) {
            const storeResult = await $api.pages.loadStores()

            if (storeResult.feedback === 'data_success') {
                store.commit('pages/setValue', { name: 'stores', page: storeResult })
            } else if (storeResult.feedback === 'resource_not_found') {
                error({
                    statusCode: 404,
                    message: $util.lang.views.storesBridge.category_not_found,
                })
            } else {
                error({
                    statusCode: 404,
                    message: $util.lang.views.storesBridge.error,
                })
            }
        } else {
            const storeResult = await $api.pages.loadStore(slug, { status: 'active' })

            if (storeResult.feedback === 'data_success') {
                store.commit('pages/setValue', {
                    name: 'store',
                    page: storeResult.data,
                })
            } else if (storeResult.feedback === 'resource_not_found') {
                error({
                    statusCode: 404,
                    message: $util.lang.views.storesBridge.store_not_found,
                })
            } else {
                error({
                    statusCode: 404,
                    message: $util.lang.views.storesBridge.error,
                })
            }
        }

        return true
    },
    render(h) {
        if (
            /^(popular|a|b|c|d|e|f|g|h|i|j|k|l|m|n|ñ|o|p|q|r|s|t|u|v|w|x|y|z)$/.test(
                this.$route.params.slug
            ) ||
            !this.$route.params.slug
        ) {
            return h('StoresList')
        } else {
            return h('StoreDetails')
        }
    },
})
