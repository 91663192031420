
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { format, parseISO, intervalToDuration, formatDuration } from 'date-fns'
import { pt as locale } from 'date-fns/locale'
import { Api } from '~/global'
import { Models } from '~/types/models'
import { Helpers } from '~/types/helpers'

export default Vue.extend({
    name: 'MessengerServiceIndex',
    middleware: ['ifAuthenticated'],
    async asyncData({ $api, store }) {
        const conversations = await $api.messages.loadConversations()

        store.commit('pages/setValue', {
            name: 'conversations',
            page: conversations,
        })
    },
    data() {
        const info = this.$store.state.pages.conversations as Api.Responses.User.Conversations

        const conversations: Models.Conversation[] = JSON.parse(JSON.stringify(info.data.results))
        let meta: Helpers.Meta | null = null

        meta = {
            ...info.data.meta,
        }

        return {
            conversations,
            pagination: {
                loadMoreMessages: { show: true, loading: false, data: {} as Helpers.Meta },
            },
            format,
            parseISO,
            showOptionsMenu: false,
            showAside: true,
            showChat: false,
            currentConversationId: 0,
            currentConversation: [] as Models.Message[],
            formData: {
                message: '',
            },
            sending: false,
            loadingConversation: false,
            fetchButton: {
                loading: false,
                show:
                    meta?.total &&
                    meta?.current_page &&
                    meta?.last_page &&
                    meta?.total > 0 &&
                    meta?.current_page < meta?.last_page,
            },
            params: {
                page: 2,
            },
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.pages.mensajeria.title_seo
        const description = this.$util.lang.pages.mensajeria.description_seo
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
    computed: {
        mobile(): boolean {
            return process.browser && (this.$bp.mb || this.$bp.xs || this.$bp.sm || this.$bp.md)
        },
        optionsMenu(): any {
            return [
                {
                    title: this.$util.lang.pages.mensajeria.delete,
                    icon: this.$assets.gray.remove,
                    description: this.$util.lang.pages.mensajeria.delete_conversation,
                    handler: () =>
                        this.$store.commit('setModal', {
                            name: 'ModalConfirm',
                            info: {
                                text: this.$util.lang.pages.mensajeria.want_delete_conversation,
                                continue: {
                                    text: this.$util.lang.pages.mensajeria.delete,
                                    handler: async () => {
                                        const response = await this.$api.messages.deleteConversation(
                                            this.currentConversationId
                                        )
                                        if (response.feedback === 'data_success') {
                                            this.$root.$emit('notification', {
                                                text: this.$util.lang.pages.mensajeria.conversation_deleted,
                                                duration: 3000,
                                                dismissButtonText: this.$util.lang.pages.mensajeria.hide,
                                                type: 'success',
                                            })
                                            this.refreshChat()
                                        } else {
                                            this.$root.$emit('notification', {
                                                text: this.$util.lang.pages.mensajeria.error,
                                                duration: 3000,
                                                dismissButtonText: this.$util.lang.pages.mensajeria.hide,
                                                type: 'error',
                                            })
                                        }
                                    },
                                },
                                cancel: {
                                    text: this.$util.lang.pages.mensajeria.cancel,
                                    handler: () => null,
                                },
                            },
                        }),
                },
                {
                    title: this.silentText(true),
                    icon: this.$assets.gray.deactivate,
                    description: `${this.silentText(true)} ${
                        this.$util.lang.pages.mensajeria.mute_conversation
                    }`,
                    handler: () =>
                        this.$store.commit('setModal', {
                            name: 'ModalConfirm',
                            info: {
                                text: `${this.$util.lang.pages.mensajeria.want} ${this.silentText()} ${
                                    this.$util.lang.pages.mensajeria.this_conversation
                                }`,
                                continue: {
                                    text: this.silentText(true),
                                    handler: async () => {
                                        const response = await this.$api.messages.silentConversation(
                                            this.currentConversationId,
                                            !this.receiver.silent
                                        )
                                        if (response.feedback === 'data_success') {
                                            this.$root.$emit('notification', {
                                                text: this.$util.lang.pages.mensajeria.conversation_muted,
                                                duration: 3000,
                                            })
                                            this.refreshChat(this.currentConversationId)
                                        } else {
                                            this.$root.$emit('notification', {
                                                text: this.$util.lang.pages.mensajeria
                                                    .error_on_conversation_mute,
                                                duration: 3000,
                                                type: 'error',
                                            })
                                        }
                                    },
                                },
                                cancel: {
                                    text: this.$util.lang.pages.mensajeria.cancel,
                                    handler: () => null,
                                },
                            },
                        }),
                },
            ]
        },
        receiver(): Models.Conversation {
            const receiver = this.conversations.find(
                (conversation: Models.Conversation) => conversation.id === this.currentConversationId
            )

            return receiver!
        },
    },
    watch: {
        pagination: {
            handler(newValue) {
                newValue = newValue.loadMoreMessages.data
                if (newValue.current_page === newValue.last_page) {
                    this.pagination.loadMoreMessages.show = false
                } else {
                    this.pagination.loadMoreMessages.show = true
                }
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() {
        this.showAside = true
        this.showChat = !this.mobile
        if (typeof this.$route.query.id === 'string') {
            this.changeChatThread(true, parseInt(this.$route.query.id))
        }
    },
    methods: {
        silentText(mode: boolean = false): string {
            const desilenciar = mode
                ? this.$util.lang.pages.mensajeria.unmute_may
                : this.$util.lang.pages.mensajeria.unmute
            const silenciar = mode
                ? this.$util.lang.pages.mensajeria.mute_may
                : this.$util.lang.pages.mensajeria.mute
            return this.receiver.silent ? desilenciar : silenciar
        },
        scrollToBottom(): void {
            const messagesBox = this.$refs.scrollToBottom as HTMLUListElement
            if (messagesBox) {
                messagesBox.scrollTop = messagesBox.scrollHeight
            }
        },
        sortByDate() {
            this.currentConversation = [
                ...this.currentConversation.sort((a: any, b: any) => {
                    return b.created_at > a.created_at ? -1 : 1
                }),
            ]
        },
        getDateDifference(date: any) {
            const duration = intervalToDuration({
                start: new Date(date as any),
                end: new Date(),
            })

            const units = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']
            const nonzero = Object.entries(duration)
                .filter(([_, value]) => value)
                .map(([unit, _]) => unit)

            return formatDuration(duration, {
                format: units.filter((i) => new Set(nonzero).has(i)).slice(0, 1),
                delimiter: ', ',
                locale,
            })
        },
        getDate(date: any): string {
            const newDate = date && parseISO(date)
            return newDate ? format(newDate, 'dd/MM/yyyy hh:mm') : ''
        },
        newMessage() {
            this.$store.commit('setModal', {
                name: 'ModalMessagesNewMessage',
                info: { refresh: (id: number | null) => this.refreshChat(id) },
            })
        },
        async changeChatThread(showChat: boolean, id: number | null) {
            if (id) {
                if (this.mobile) {
                    this.$nuxt.$loading.start()
                } else {
                    this.showChat = false
                    this.loadingConversation = true
                }

                const res = await this.$api.messages.conversationDetail(id, { page: 1, per_page: 20 })

                if (res.feedback === 'data_success') {
                    this.currentConversationId = id

                    this.currentConversation = res.data.results

                    if (this.mobile) {
                        this.showAside = !showChat
                    }

                    this.showChat = showChat

                    this.pagination.loadMoreMessages.data = res.data.meta
                }

                this.currentConversation = res.data.results.reverse()

                this.markAsRead(id.toString())

                if (this.mobile) {
                    this.$nuxt.$loading.finish()
                } else {
                    this.loadingConversation = false
                }

                this.$nextTick(() => this.scrollToBottom())
            } else if (this.mobile) {
                this.showAside = !showChat
                this.showChat = showChat
            }
        },
        async getMoreMessages(id: number) {
            if (id) {
                this.pagination.loadMoreMessages.loading = true

                const messagesBox = this.$refs.scrollToBottom as HTMLUListElement

                const currentScrollHeight = messagesBox.scrollHeight

                const res = await this.$api.messages.conversationDetail(id, {
                    page: this.pagination.loadMoreMessages.data.current_page + 1,
                    per_page: 20,
                })

                if (res.feedback === 'data_success') {
                    this.currentConversation = [...this.currentConversation, ...res.data.results]

                    this.sortByDate()

                    this.pagination.loadMoreMessages.loading = false

                    this.pagination.loadMoreMessages.data = res.data.meta

                    this.$nextTick(() => {
                        const nextScrollHeight = messagesBox.scrollHeight

                        messagesBox.scrollTop = nextScrollHeight - currentScrollHeight
                    })
                } else {
                    this.$root.$emit('notification', {
                        duration: 3000,
                        dismissButtonText: this.$util.lang.pages.mensajeria.hide,
                        text: this.$util.lang.pages.mensajeria.unexpected_error,
                        type: 'error',
                    })
                }
            }
        },
        async refreshChat(id: number | null = null) {
            this.showChat = false

            if (this.mobile) {
                this.$nuxt.$loading.start()
            } else {
                this.loadingConversation = true
            }

            const conversations = await this.$api.messages.loadConversations()

            if (conversations.feedback === 'data_success') {
                this.conversations = conversations.data.results
            }

            if (id) {
                const currentConversation = await this.$api.messages.conversationDetail(id, {
                    per_page: 20,
                    page: 1,
                })

                if (currentConversation.feedback === 'data_success') {
                    this.currentConversation = currentConversation.data.results

                    this.pagination.loadMoreMessages.show =
                        !!currentConversation.data.meta.last_page &&
                        currentConversation.data.meta.current_page < currentConversation.data.meta.last_page

                    this.pagination.loadMoreMessages.data = currentConversation.data.meta

                    this.sortByDate()

                    this.currentConversationId = id

                    if (this.mobile) {
                        this.$nuxt.$loading.finish()

                        this.showAside = false

                        this.showChat = true
                    } else {
                        this.showChat = true

                        this.loadingConversation = false
                    }

                    this.$nextTick(() => this.scrollToBottom())

                    return
                }
            }

            if (this.mobile) {
                this.$nuxt.$loading.finish()

                this.showAside = true

                this.showChat = false
            } else {
                this.showChat = false

                this.loadingConversation = false
            }
        },
        async getMoreConversations() {
            this.fetchButton.loading = true
            const res = await this.$api.messages.loadConversations({ page: this.params.page })

            if (res.feedback === 'data_success') {
                this.conversations = [...this.conversations, ...res.data.results]
                const { current_page, last_page } = res.data.meta

                if (!last_page || current_page === last_page) {
                    this.fetchButton.show = false
                } else {
                    this.params.page = current_page + 1
                }
            }
            this.fetchButton.loading = false
        },
        async markAsRead(id: string) {
            const conversation = this.conversations.find((i) => i.id === Number(id))
            if (conversation) {
                conversation.has_unread_message = false
            }
            await this.$api.messages.markAsRead(id)
            this.$store.dispatch('user/getMessagesCount', {
                store: this.$store,
                $api: this.$api,
            })
        },
        async sendMessage(id: number, newMessage: string) {
            if (this.formData.message.length > 0 && !this.sending) {
                this.sending = true

                const res = await this.$api.messages.createMessage({
                    conversation_id: id,
                    message: newMessage,
                })

                if (res.feedback === 'data_success') {
                    this.formData.message = ''

                    this.currentConversation = [...this.currentConversation, res.data]

                    this.$nextTick(() => this.scrollToBottom())
                } else {
                    this.$root.$emit('notification', {
                        duration: 3000,
                        dismissButtonText: this.$util.lang.pages.mensajeria.hide,
                        text: this.$util.lang.pages.mensajeria.error_on_send_message,
                        type: 'error',
                    })
                }
                this.sending = false
            }
        },
        handleOptionsMenuToggle(show: boolean): void {
            this.showOptionsMenu = show
        },
    },
})
