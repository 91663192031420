var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chat-share"},[_c('h3',[_vm._v("\n        "+_vm._s(_vm.carddata.formData.title
                ? _vm.carddata.formData.title
                : _vm.$util.lang.components.cardSharePreviewChat.title)+"\n        "),_c('p',[_vm._v("\n            "+_vm._s(_vm.carddata.dropdown.forumCategories.selected.name !==
                _vm.$util.lang.components.cardSharePreviewChat.select
                    ? _vm.carddata.dropdown.forumCategories.selected.name
                    : _vm.$util.lang.components.cardSharePreviewChat.select_category)+"\n        ")])]),_vm._v(" "),_c('h5',[_vm._v("\n        "+_vm._s(_vm.carddata.formData.description && _vm.content
                ? _vm.content
                : _vm.$util.lang.components.cardSharePreviewChat.description)+"\n    ")]),_vm._v(" "),_c('MicroFullUser',{attrs:{"info":_vm.$store.state.user.profile,"is-sharing":true,"image-class":"w-8 h-8"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }