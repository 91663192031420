var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"event"},[(_vm.storeResult.event.header_messages && _vm.storeResult.event.header_messages[0])?_c('CardAlertMessage',{attrs:{"info":_vm.storeResult.event.header_messages[0]}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"event__cover"},[_c('MicroBreadcrumbs',{staticClass:"event__cover-breadcrumbs",attrs:{"layers":_vm.breadcrumbs,"text-color":"text-black","margin":"mt-1"}}),_vm._v(" "),_c('div',{staticClass:"event__cover-content"},[(_vm.storeResult.event.image && _vm.storeResult.event.title)?_c('div',{staticClass:"event__cover-content-image"},[_c('img',{attrs:{"src":_vm.storeResult.event.image.url,"alt":_vm.storeResult.event.title,"title":_vm.storeResult.event.title,"width":"60","height":"60","loading":"lazy","onerror":`this.onerror=null;this.src='${_vm.defaultImage.event}'`}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"event__cover-content-text"},[_c('h1',{staticClass:"event__cover-content-title"},[_c('span',{staticClass:"emphasis"},[_vm._v(_vm._s(_vm.metaInfo.h1))])]),_vm._v(" "),_c('h2',{staticClass:"event__cover-content-sub"},[_vm._v(_vm._s(_vm.metaInfo.h2))])])])],1),_vm._v(" "),_c('main',{staticClass:"event__main"},[(
                !_vm.storeResult.event.hide_featured_stores ||
                (_vm.storeResult.featured_stores && _vm.storeResult.featured_stores.length > 0)
            )?_c('CardSliderEventFeaturedStores',{attrs:{"info":_vm.storeResult.featured_stores,"title":_vm.$util.lang.pages.eventosSlug.featured_stores}}):_vm._e(),_vm._v(" "),_c('CardSliderEventFeaturedDiscounts',{attrs:{"info":_vm.storeResult.featured_discounts,"title":_vm.$util.lang.pages.eventosSlug.featured_discounts}}),_vm._v(" "),_c('div',{staticClass:"event__main-discounts"},[(_vm.existsAnyData)?[_c('section',[_c('ul',{staticClass:"space-y-3"},_vm._l((_vm.discounts),function(item,key){return _c('li',{key:key},[(item.isCtaCard)?_c('CtaHandler',{key:key,attrs:{"cta":item}}):_c('MicroLazy',{attrs:{"url":item.url,"title":item.title,"mode":"global"}},[_c('CardMainGlobal',{attrs:{"info":item}})],1)],1)}),0)]),_vm._v(" "),(_vm.fetchButton.show)?_c('BtnSeeMoreResults',{staticClass:"mt-4",attrs:{"loading":_vm.fetchButton.loading,"handler":_vm.loadMore}}):_vm._e()]:_c('MicroNoResults',{attrs:{"mode":"discounts","title":_vm.$util.lang.pages.eventosSlug.no_discounts}})],2)],1),_vm._v(" "),(_vm.storeResult.all_stores.length)?_c('section',{staticClass:"event__allstores"},[_c('h3',{staticClass:"event__allstores-title"},[_vm._v("\n            "+_vm._s(_vm.$util.lang.pages.eventosSlug.enjoy)+"\n            "+_vm._s(_vm.storeResult.event.title)+"\n            "+_vm._s(_vm.$util.lang.pages.eventosSlug.in_stores)+"\n        ")]),_vm._v(" "),_c('ul',{staticClass:"event__allstores-list"},_vm._l((_vm.storeResult.all_stores),function(item,key){return _c('li',{key:key},[_c('NLink',{staticClass:"event__allstores-list-item",attrs:{"to":`/${_vm.$util.lang.routes.brands}/${item.slug}`}},[_c('span',{staticClass:"event__allstores-list-item-name"},[_vm._v(_vm._s(item.name))])])],1)}),0)]):_vm._e(),_vm._v(" "),(_vm.storeResult.event.contents && _vm.storeResult.event.contents.length)?_c('section',{staticClass:"event__footer"},[_vm._l((_vm.storeResult.event.contents),function(content,index){return [(
                    content.content &&
                    content.title &&
                    content.content.length &&
                    content.content !== '<p></p>'
                )?_c('article',{key:index,staticClass:"event__footer-section"},[_c('div',{staticClass:"event__footer-head"},[_c('div',{staticClass:"event__footer-head-image",class:content.icon}),_vm._v(" "),_c('h3',{staticClass:"event__footer-head-title"},[_vm._v("\n                        "+_vm._s(content.title)+"\n                    ")])]),_vm._v(" "),_c('div',{staticClass:"event__footer-description",domProps:{"innerHTML":_vm._s(content.content)}})]):_vm._e()]})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }