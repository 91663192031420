
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { Api } from '~/global'
import { currentDate } from '~/libs/date-functions'

export default Vue.extend({
    name: 'Eventos',
    async asyncData({ $api, store }) {
        const events = await $api.pages.loadEvents()
        store.commit('pages/setValue', { name: 'events', page: events })
    },
    data() {
        const events = this.$store.state.pages.events as Api.Responses.Pages.Events
        const currentDates = currentDate()

        const [title, description] = this.$util.replacePlaceholders(
            [
                [':month', currentDates.month || ''],
                [':day', String(currentDates.day) || ''],
                [':year', String(currentDates.year) || ''],
                [':site', process.env.SITE_NAME || ''],
            ],
            [events.page.title || '', events.page.description || '']
        )

        return {
            events,
            metaInfo: { title, description },
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                ],
                this.metaInfo?.title
            ),
        }
    },
    computed: {
        existsAnyEvent(): boolean {
            return (
                this.events.page.data.active.data.length > 0 ||
                this.events.page.data.expired.data.length > 0 ||
                this.events.page.data.next.data.length > 0
            )
        },
    },
})
