
import { format, parseISO } from 'date-fns'
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { Api } from '~/global'
import { currentDate } from '~/libs/date-functions'
import { Models } from '~/types/models'
import { defaultImage } from '~/libs/useful-functions'

export default Vue.extend({
    name: 'Store',
    data() {
        const storeResult = this.$store.state.pages.store as Api.Responses.Pages.StoreDetails['data']

        const offersMetaData = storeResult?.offers?.meta
        const couponsMetaData = storeResult?.coupons?.meta

        let current_page_offers = null as null | number

        let last_page_offers = null as null | number

        let current_page_coupons = null as null | number

        let last_page_coupons = null as null | number

        if (offersMetaData) {
            current_page_offers = offersMetaData.current_page
            last_page_offers = offersMetaData.last_page
        }

        if (couponsMetaData) {
            current_page_coupons = couponsMetaData.current_page
            last_page_coupons = couponsMetaData.last_page
        }

        const currentDates = currentDate()

        const [title, description, h1, h2, firstSidebarContent] = this.$util.replacePlaceholders(
            [
                [':store', storeResult.store.name || ''],
                [':last', storeResult.store.last_discount || ''],
                [':count', String(storeResult.store.all_count) || ''],
                [':coupons', String(storeResult.store.coupons_count) || ''],
                [':offers', String(storeResult.store.offers_count) || ''],
                [':month', currentDates.month || ''],
                [':year', String(currentDates.year) || ''],
                [':day', String(currentDates.day) || ''],
                [':site', process.env.SITE_NAME || ''],
            ],
            [
                storeResult.store.seo?.title || '',
                storeResult.store.seo?.description || '',
                storeResult.store.seo?.h1 || '',
                storeResult.store.seo?.h2 || '',
                storeResult.store.content || '',
            ]
        )

        const pagination = {
            coupons: {
                show: last_page_coupons && current_page_coupons !== last_page_coupons,
                loading: false,
                meta: { ...couponsMetaData },
            },
            offers: {
                show: last_page_offers && current_page_offers !== last_page_offers,
                loading: false,
                meta: { ...offersMetaData },
            },
        }
        const sectionsData: {
            offers: any[]
            coupons: any[]
            expiredDiscounts: any[] | null
        } = {
            offers: [...(storeResult.offers?.data || [])],
            coupons: [...(storeResult.coupons?.data || [])],
            expiredDiscounts: null,
        }

        return {
            activeTab: 1,
            defaultImage,
            rating: null as null | number,
            firstSidebarContent,
            metaInfo: {
                title,
                description,
                h1,
                h2,
            },
            currentDates,
            storeResult,
            pagination,
            sidebarContents:
                storeResult.store.contents?.data?.filter(
                    (content: Models.Content) => content.position === 'sidebar'
                ) || [],
            footerContents:
                storeResult.store.contents?.data?.filter(
                    (content: Models.Content) => content.position === 'footer'
                ) || [],
            activeFaq: {
                index: null as null | number,
                active: false,
            },
            sectionsData,
            sidebarData: {
                paymentMethods: storeResult.store.payment_methods?.data || [],
                summary: null as null | object,
                similarStores: null as null | Models.Store[],
                popularStores: null as null | Models.Store[],
            },
            showSection: {
                featuredDiscounts: true,
                expiredDiscounts: true,
                sidebar: {
                    paymentMethods: true,
                    summary: true,
                    similarStores: true,
                    popularStores: true,
                },
            },
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['og:image:secure_url', this.storeResult.store.image?.url || ''],
                    ['twitter:image', this.storeResult.store.image?.url || ''],
                    ['og:image', this.storeResult.store.image?.url || ''],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                    ['keywords', this.storeResult.store.seo?.keywords || ''],
                ],
                this.metaInfo?.title
            ),
        }
    },
    jsonld(): any {
        return {
            '@context': 'http://schema.org',
            '@graph': [
                {
                    '@context': 'https://schema.org',
                    '@type': 'Store',
                    name: this.storeResult.store.name,
                    logo: this.storeResult.store.image?.url,
                    description: this.storeResult.store.content && this.firstSidebarContent,
                    url: `https://www.megadescuentos.com/marcas/${this.storeResult.store.slug}`,
                    image: this.storeResult.store.image?.url,
                    sameAs: this.storeResult.store.url,
                },
                {
                    '@context': 'https://schema.org',
                    '@type': 'FAQPage',
                    mainEntity: this.storeResult.store.faqs?.data.map((value: any) => {
                        return {
                            '@type': 'Question',
                            name: value.question,
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: value.answer,
                            },
                        }
                    }),
                },
                {
                    '@context': 'https://schema.org',
                    '@type': 'BreadcrumbList',
                    itemListElement: [
                        ...this.breadcrumbs
                            .filter((_, index) => this.breadcrumbs.length !== index + 1)
                            .map((value, index) => {
                                return {
                                    '@type': 'ListItem',
                                    position: index + 1,
                                    item: `https://www.megadescuentos.com${value.path}`,
                                    name: value.title,
                                }
                            }),
                    ],
                },
            ],
        }
    },
    computed: {
        sidebarQuestions(): { text: any; handler: () => void }[] | undefined {
            return this.storeResult.store.faqs?.data.map((faq: any, index: any) => ({
                text: faq.question,
                handler: () => {
                    setTimeout(() => {
                        this.activeFaq.active = true
                        this.activeFaq.index = index
                    }, 900)

                    this.$scrollTo('#store-question-' + index, {
                        duration: 1000,
                    })
                },
            }))
        },
        breadcrumbs(): { path?: string; title: string }[] {
            return [
                {
                    path: '/',
                    title: this.$util.lang.views.storeDetails.home,
                },
                {
                    path: `/${this.$util.lang.routes.brands}`,
                    title: this.$util.lang.views.storeDetails.stores,
                },
                {
                    title: this.storeResult.store?.name || '',
                },
            ]
        },
        updated_at(): string {
            return (
                (!!this.storeResult.store.updated_at &&
                    format(parseISO(this.storeResult.store.updated_at), 'dd/MM/yyyy')) ||
                ''
            )
        },
    },
    async mounted() {
        setTimeout(() => {
            this.$nextTick(() => {
                if (this.storeResult?.banners?.length)
                    this.$store.dispatch('assignBanners', this.storeResult?.banners)
            })
        }, 2000)

        try {
            const secondaryInfo = await this.$api.pages.loadSecondaryDataOfStore(this.$route.params.slug)

            if (secondaryInfo.feedback === 'data_success') {
                this.sidebarData.similarStores = [...secondaryInfo.data.similar_stores]

                this.sidebarData.popularStores = [...secondaryInfo.data.popular_stores]

                this.sidebarData.summary = {
                    ...secondaryInfo.data.discount_summary,
                    last_updated: this.storeResult.store.updated_at,
                }

                this.sectionsData.expiredDiscounts = [...secondaryInfo.data.discounts_expired]
            }
        } catch (e) {}
    },
    methods: {
        onUserRating(num: number) {
            this.rating = num
        },
        calcExpiredDate(date: string | null): string {
            return date
                ? format(parseISO(date), 'dd/MM/yyyy')
                : this.$util.lang.views.storeDetails.valid_until_advice
        },
        async loadMore(section: 'offers' | 'coupons') {
            this.pagination[section].loading = true

            if (section === 'offers') {
                const result = await this.$api.general.loadOffers({
                    status: 'active',
                    page: this.pagination.offers.meta.current_page + 1,
                    per_page: 15,
                    stores: [this.storeResult.store.id],
                })

                if (result && result.feedback === 'data_success') {
                    this.sectionsData.offers = [...this.sectionsData.offers, ...result.data.results]
                    this.pagination.offers.meta = { ...result.data.meta }

                    if (result.data.meta.current_page === result.data.meta.last_page) {
                        this.pagination.offers.show = false
                    }
                }
            }

            if (section === 'coupons') {
                const result = await this.$api.general.loadCoupons({
                    status: 'active',
                    page: this.pagination.coupons.meta.current_page + 1,
                    per_page: 15,
                    stores: [this.storeResult.store.id],
                })

                if (result && result.feedback === 'data_success') {
                    this.sectionsData.coupons = [...this.sectionsData.coupons, ...result.data.results]
                    this.pagination.coupons.meta = { ...result.data.meta }

                    if (result.data.meta.current_page === result.data.meta.last_page) {
                        this.pagination.coupons.show = false
                    }
                }
            }

            this.pagination[section].loading = false
        },
    },
})
