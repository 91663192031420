
import Vue from 'vue'
export default Vue.extend({
    name: 'StoreMask',
    layout: 'none',
    async validate({ params, $api, redirect, route }) {
        if (route.name === 'go-store-slug') {
            const url = await $api.general.loadStoreShortLink(params.id)
            redirect(301, url)
        }
        return true
    },
})
