
import Vue from 'vue'
export default Vue.extend({
    name: 'FieldTabs',
    props: {
        optional: {
            type: Boolean,
        },
        discounttype: {
            type: String,
        },
        discountvalue: {
            type: String,
        },
        invalid: {
            // type can be boolean or string
            type: [Boolean, String],
        },
    },
    data() {
        return {
            active: null as null | string,
            discount_value: '',
            tabs: ['porcentaje', 'precio', 'envío gratis', null],
        }
    },
    computed: {
        pos(): {
            left: boolean
            center: boolean
            right: boolean
            none: boolean
        } {
            return {
                left: this.active === this.tabs[0],
                center: this.active === this.tabs[1],
                right: this.active === this.tabs[2],
                none: this.active === this.tabs[3],
            }
        },
        activePercent(): { active: boolean } {
            return {
                active: this.active === 'porcentaje',
            }
        },
        activeCash(): { active: boolean } {
            return {
                active: this.active === 'precio',
            }
        },
        activeShipping(): { active: boolean } {
            return {
                active: this.active === 'envío gratis',
            }
        },
        activeNull(): { active: boolean } {
            return {
                active: this.active === null,
            }
        },
    },
    watch: {
        active(newValue: string | null) {
            if (
                newValue === 'porcentaje' &&
                this.discount_value !== null &&
                parseInt(this.discount_value) > 100
            ) {
                this.$emit('update:discountvalue', this.discount_value)
            }
            if (newValue === 'envío gratis') {
                this.discount_value = ''
                this.$emit('update:discountvalue', '')
            }
            this.$emit('update:discounttype', newValue)
        },
        discount_value: {
            immediate: true,
            handler(val) {
                if (this.active === 'porcentaje') {
                    const newValue = val.replace(/\D/g, '')
                    if (Number.parseInt(newValue, 10) > 100) {
                        this.discount_value = '100'
                        this.$emit('update:discountvalue', '100')
                    } else {
                        this.discount_value = newValue
                        this.$emit('update:discountvalue', newValue)
                    }
                } else {
                    const newValue = val.replace(/\D/g, '')
                    this.discount_value = newValue
                    this.$emit('update:discountvalue', newValue)
                }
            },
        },
    },
    methods: {
        handleActive(type: string) {
            if (this.active === type) {
                this.active = null
            } else {
                this.active = type
            }
        },
    },
})
