
import Vue from 'vue'

export default Vue.extend({
    name: 'SidebarSocialNetworks',
    props: {
        rrss: {
            type: Object,
        },
    },
})
