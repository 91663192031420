
export default {
    name: 'LayoutScrollTop',
    data() {
        return {
            showButton: false,
            listener: null,
        }
    },
    mounted() {
        this.listener = () => {
            const { scrollTop, scrollHeight, clientHeight } = document.documentElement

            this.showButton = scrollTop / (scrollHeight - clientHeight) > 0.3
        }

        document.addEventListener('scroll', this.listener)
    },
    destroyed() {
        document.removeEventListener('scroll', this.listener)
    },
    methods: {
        scrollTop() {
            document.documentElement.style.scrollBehavior = 'smooth'

            window.scroll(0, 1)

            document.documentElement.style.scrollBehavior = 'unset'
        },
    },
}
