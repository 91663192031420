
import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'
import { Api } from '~/global'
import { currentDate } from '~/libs/date-functions'
import { Models } from '~/types/models'

declare module 'vue/types/vue' {
    export type tThreads = {
        category: string
    }
    interface Vue {
        threads: tThreads[]
        filterBy: string
    }
}

export default Vue.extend({
    name: 'Chats',
    async asyncData({ $api, store }) {
        const forums = await $api.pages.loadForum()
        store.commit('pages/setValue', { name: 'forums', page: forums })
    },
    data() {
        const forum = this.$store.state.pages.forums as Api.Responses.Pages.Forum

        const currentDates = currentDate()

        const [title, description] = this.$util.replacePlaceholders(
            [
                [':month', currentDates.month || ''],
                [':day', String(currentDates.day) || ''],
                [':year', String(currentDates.year) || ''],
                [':site', process.env.SITE_NAME || ''],
            ],
            [forum.page.title || '', forum.page.description || '']
        )

        return {
            forum,
            metaInfo: { title, description },
            page: 1,
            showLoadMore:
                forum.page.data.forums.meta.total > 0 &&
                forum.page.data.forums.meta.current_page < forum.page.data.forums.meta.last_page,
            loading: false,
            filterBy: 'Todo',
            pagination: forum.page.data.categories.reduce((acc: any, curr) => {
                acc[curr.name] = {
                    show: true,
                    loading: false,
                }
                return acc
            }, {}),
            dropdown: {
                show: {
                    category: false,
                },

                category: {
                    selected: { name: 'Todo', icon: null, id: 0 },
                    data: [
                        { name: 'Todo' },
                        ...forum.page.data.categories.map((category) => ({
                            name: category.name,
                        })),
                    ],
                },
            },
        }
    },
    head(): MetaInfo {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', this.metaInfo?.description],
                    ['title', this.metaInfo?.title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', this.metaInfo?.title],
                    ['og:image:alt', this.metaInfo?.title],
                    ['og:description', this.metaInfo?.description],
                ],
                this.metaInfo?.title
            ),
        }
    },
    computed: {
        filteredThreads(): Models.Forum[] {
            const chats = this.forum.page.data.forums.data

            if (this.filterBy !== 'Todo') {
                return chats.filter(
                    (item: Models.Forum) => item.category && item.category.name === this.filterBy
                )
            }
            return chats
        },
        stores(): Models.Store[] | null {
            return this.$store.state.layout?.sidebar?.stores || null
        },
        breadcrumbs(): { path: string; title: string }[] {
            return [
                {
                    path: '/',
                    title: this.$util.lang.pages.foros.home,
                },
                {
                    path: '',
                    title: this.$util.lang.pages.foros.forum,
                },
            ]
        },
    },
    methods: {
        dropdownToggle(show: boolean, name: string): void {
            this.dropdown.show[name as 'category'] = show
        },
        onChangeCategory({ selected }: { selected: { name: string } }) {
            this.filterBy = selected.name
        },
        onChangeCategoryByName(name: string) {
            this.filterBy = name
            this.dropdown.category.selected.name = name
        },
        async loadMoreForos() {
            try {
                this.loading = true
                this.page = this.page + 1

                const foros = await this.$api.pages.loadForum({
                    page: this.page,
                })

                const { forums } = foros.page.data

                const { forums: oldForums, categories } = this.forum.page.data

                forums.data = [...oldForums.data, ...forums.data]

                foros.page.data.categories = categories

                this.forum = foros
            } catch (e) {
            } finally {
                this.loading = false
                this.showLoadMore = !(this.page === this.forum.page.data.forums.meta.last_page)
            }
        },
    },
})
